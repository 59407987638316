import React from 'react';
import Styles from './ButtonSection.module.scss';
import BSButton from 'Components/Button/BSButton';
import { MdOutlineKeyboardDoubleArrowRight } from 'react-icons/md';
import { IoChevronBackOutline } from 'react-icons/io5';

const ButtonSection = ({
  btn_1,
  btn_2,
  handleNext,
  handleBack,
  isValid = true,
  showIcon = true,
}) => {
  return (
    <div className={Styles.buttons}>
      <BSButton
        ButtonText={btn_1}
        classList="secondary_btn w-100"
        onClick={handleNext}
        disabled={!isValid}
      />
      <BSButton
        ButtonText={btn_2}
        classList={Styles.buttons__skip_btn}
        icon={
          showIcon &&
          (btn_2 === 'Back to previous step' ? (
            <IoChevronBackOutline />
          ) : btn_2 === 'Skip onboarding' ? (
            <MdOutlineKeyboardDoubleArrowRight />
          ) : null)
        }
        iconPosition={btn_2 === 'Back to previous step' ? 'left' : 'right'}
        onClick={handleBack}
      />
    </div>
  );
};

export default ButtonSection;
