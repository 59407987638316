import Announcement from 'Components/Pages/Brand/Announcement';
import React, { useState, useEffect, useRef } from 'react';
import Styles from './Brand.module.scss';
import Tabs from 'Components/Pages/Brand/Tabs';
import BrandSummary from 'Components/Pages/Brand/BrandSummary';
import LoadingState from 'Components/LoadingState/LoadingState';
import { useSelector } from 'react-redux';

const Brand = () => {
  const { user } = useSelector((state) => state.auth);
  const { subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};
  const [changeAdd, setChangeAdd] = useState(false);
  // const [brandSummary, setBrandSummary] = useState(user?.user_brands?.brands_count === 0 ? false : true);
  const [brandSummary, setBrandSummary] = useState(false);

  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState('');
  const [description, setDescription] = useState('');
  const [brandName, setBrandName] = useState('');
  const [activeTab, setActiveTab] = useState(null);
  const [brandSummaryData, setBrandSummaryData] = useState(false);
  const [brandImage, setBrandImage] = useState(null);
  const [fields, setFields] = useState([]);
  const [cancelClicked, setCancelClicked] = useState(false);

  const [initialValues, setInitialValues] = useState({
    brand_name: '',
    brand_description: '',
    core_messaging: '',
    value_proposition: '',
    industries: [],
    keywords_and_themes: [],
    target_audience: [],
    tone: [],
    detailed_topics: [],
    logo: brandImage,
  });

  function scrollTo(to, duration) {
    const start = document.body.scrollTop;
    const diff = to - start;
    const scrollStep = Math.PI / (duration / 10);
    let count = 0;

    const scrollInterval = setInterval(() => {
      if (document.body.scrollTop !== to) {
        count += 1;
        const currPos =
          start + diff * (0.5 - 0.5 * Math.cos(count * scrollStep));
        document.body.scrollTop = currPos;
      } else {
        clearInterval(scrollInterval);
      }
    }, 10);
  }

  function test(elID) {
    const allElements = document.querySelectorAll('.clickable');
    allElements.forEach((el) => {
      el.classList.remove(Styles.yellowBorder, Styles.blueBorder);
    });

    const dest = document.getElementById(elID);
    if (dest) {
      // Add the appropriate border class based on the element ID
      if (
        elID === 'core_messaging' ||
        elID === 'value_propositions' ||
        elID === 'industry' ||
        elID === 'detailed_topics'
      ) {
        dest.classList.add(Styles.yellowBorder);
      } else if (
        elID === 'audience' ||
        elID === 'tone_of_voice' ||
        elID === 'keywords_themes' ||
        elID === 'brand_description'
      ) {
        dest.classList.add(Styles.blueBorder);
      }

      // Scroll to the element
      scrollTo(dest.offsetTop, 500);
    }
  }

  // scorll the brand form into view when the user clicks on tabs
  const isFirstRender = useRef(true);
  const brandRef = useRef(null);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    brandRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  }, [activeTab]);

  return (
    <>
      {brandSummary ? (
        loading ? (
          <>
            <Announcement />
            <div className={Styles.loading_container}>
              <LoadingState
                className={Styles.loaging_size}
                // text={loadingText}
              />
            </div>
          </>
        ) : (
          <div className={Styles.container}>
            {/* <LeftNavigation test={test} brandName={brandName} url={url} currentSubscription={currentSubscription} setBrandSummary={setBrandSummary} /> */}
            <BrandSummary
              setLoading={setLoading}
              url={url}
              setBrandSummary={setBrandSummary}
              description={description}
              setBrandName={setBrandName}
              test={test}
              brandName={brandName}
              currentSubscription={currentSubscription}
              user={user}
              setChangeAdd={setChangeAdd}
              changeAdd={changeAdd}
              setUrl={setUrl}
              brandSummary={brandSummary}
              setBrandSummaryData={setBrandSummaryData}
              brandSummaryData={brandSummaryData}
              initialValues={initialValues}
              setInitialValues={setInitialValues}
              brandImage={brandImage}
              setBrandImage={setBrandImage}
              setDescription={setDescription}
              fields={fields}
              setFields={setFields}
              cancelClicked={cancelClicked}
              setCancelClicked={setCancelClicked}
            />
          </div>
        )
      ) : (
        <div className={Styles.main_page} ref={brandRef}>
          <Announcement />
          <p className={Styles.heading}>
            Establish your brand identity to drive your content strategy ✨
          </p>
          <p className={Styles.descriptions}>
            Here, you can view, edit, and manage your brand, or create a new
            brand identity from your website URL or a text description.
            Vocable’s AI takes your brand in context to generate tailored
            content plans and campaigns that align with your unique brand voice
            and identity.
          </p>
          <Tabs
            setBrandSummary={setBrandSummary}
            setLoading={setLoading}
            setUrl={setUrl}
            setDescription={setDescription}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            setCancelClicked={setCancelClicked}
          />
        </div>
      )}
    </>
  );
};

export default Brand;
