import React from 'react';
import { FiStar } from 'react-icons/fi';

import Styles from './Star.module.scss';

const Star = ({
  isStarred,
  shouldDisplay,
  shouldAlwaysDisplay,
  position,
  onClick,
}) => {
  return (
    <div
      className={isStarred ? Styles.Star_Active : Styles.Star}
      data-position={position}
    >
      {isStarred || shouldDisplay || shouldAlwaysDisplay ? (
        <FiStar size={20} onClick={onClick} />
      ) : null}
    </div>
  );
};

export default Star;
