import React from 'react';
import Styles from './PricingCard.module.scss';
import { ReactComponent as BasicPlan } from '../../../assets/svg/basicplan.svg';
import { ReactComponent as ProPlan } from '../../../assets/svg/proplan.svg';
import { ReactComponent as BusinessPlan } from '../../../assets/svg/businessplans.svg';

const iconMapping = {
  basicplan: <BasicPlan />,
  proplan: <ProPlan />,
  businessplan: <BusinessPlan />,
};

const PricingCard = ({
  type,
  name,
  amount,
  discount,
  duration,
  services,
  fullHeight,
  cta,
}) => {
  const discountedPerMonth =
    duration === 'year' ? Math.floor(discount / 12) : discount;

  return (
    <div
      className={`${Styles.card_container} ${fullHeight && Styles.full_height}`}
    >
      <div
        className={`${Styles.card_container__card} ${type === 'proplan' ? Styles.pro_border : type === 'businessplan' ? Styles.business_border : ''}`}
      >
        <div
          className={`${Styles.card_container__card__inner} ${type === 'proplan' ? Styles.pro_bg : type === 'businessplan' ? Styles.business_bg : ''}`}
        >
          <div className={Styles.billingCardHeadingWrapper}>
            <div className={Styles.billingCardHeadingWrapper__heading}>
              <span
                className={Styles.billingCardHeadingWrapper__heading__dropicon}
              >
                {iconMapping[type]}
              </span>
              <div className="d-flex align-items-start flex-column">
                <p className={Styles.billingCardHeadingWrapper__heading__name}>
                  {name}
                </p>
                <p
                  className={
                    Styles.billingCardHeadingWrapper__heading__subscription
                  }
                >
                  <span
                    className={
                      Styles.billingCardHeadingWrapper__heading__subscription__old_price
                    }
                  >
                    ${amount}
                  </span>{' '}
                  <br />
                  <span
                    className={
                      Styles.billingCardHeadingWrapper__heading__subscription__bold
                    }
                  >
                    {duration === 'year'
                      ? `$${discountedPerMonth}`
                      : `$${discount}`}
                  </span>{' '}
                  <span>/month</span>
                </p>
              </div>
            </div>
          </div>
          <div className={Styles.duration_and_badge}>
            <span>
              {duration === 'month' ? 'Billed Monthly' : 'Billed Yearly'}
            </span>
            {type === 'businessplan' && (
              <div className={Styles.billingCardHeadingWrapper__badge}>
                Coming soon
              </div>
            )}
          </div>
          <p className={Styles.card_container__card__inner__text}>
            {type === 'proplan'
              ? 'Designed for small businesses to elevate their content marketing strategy.'
              : type === 'businessplan'
                ? 'Advanced features & collaboration tools for teams to streamline their content operations.'
                : 'Tailored to empower solopreneurs with essential AI-driven content creation tools.'}
          </p>
          <div
            className={`${Styles.card_container__card__inner__bottom_container} ${type === 'proplan' ? Styles.pro_border : type === 'businessplan' ? Styles.business_border : ''}`}
          >
            {type && type !== 'basicplan' && (
              <div
                className={`${Styles.card_container__card__inner__bottom_container__badge} ${type === 'proplan' ? Styles.basic_bg_feature : type === 'businessplan' ? Styles.pro_bg_feature : ''}`}
              >
                {type === 'proplan'
                  ? 'Includes all features in the Basic Plan'
                  : type === 'businessplan'
                    ? 'Includes all features in the Pro Plan'
                    : 'Includes all features in the Basic Plan'}
              </div>
            )}
            <p
              className={
                Styles.card_container__card__inner__bottom_container__heading
              }
            >
              Services Included
            </p>
            <div
              className={
                Styles.card_container__card__inner__bottom_container__list_container
              }
            >
              {services?.map((option, index) => (
                <div
                  className={
                    Styles.card_container__card__inner__bottom_container__list_container__list
                  }
                  key={index}
                >
                  <img src="/Images/bullets.svg" alt="checkIcon" />
                  <span>{option}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      {cta}
    </div>
  );
};

export default PricingCard;
