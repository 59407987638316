import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Styles from './UpgradeModal.module.scss';
import Annoucement from '../../../assets/annoucement.gif';

const UpgradeModal = ({ show, onHide, message }) => {
  const navigate = useNavigate();

  const handleUpgrade = () => {
    onHide();
    navigate('/subscription');
  };

  return (
    <Modal show={show} onHide={onHide} centered style={{ zIndex: 2000 }}>
      <Modal.Header closeButton>
        <Modal.Title className={Styles.title}>Upgrade to Pro</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className={Styles.wraper}>
          <div className={Styles.wraper__lotie}>
            <img src={Annoucement} alt="annoucement" />
          </div>
          <div className={Styles.wraper__heading}>Upgrade your plan</div>
          <div className={Styles.wraper__description}>
            {message ||
              'To access more features, please upgrade to the Pro plan.'}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button className="cancel_btn" variant="secondary" onClick={onHide}>
          Cancel
        </button>
        <button
          className="secondary_btn"
          variant="primary"
          onClick={handleUpgrade}
        >
          Upgrade to Pro
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpgradeModal;
