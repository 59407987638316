import React, { useEffect, useMemo, useRef, useState } from 'react';
import { EditorContent } from '@tiptap/react';
import { useLocation, useParams } from 'react-router-dom';

import useSticky from 'hooks/useSticky';
import CommentComponent from './CommentComponent';
import ContextMenu from './ContextMenu';
import MenuBar from './MenuBar';
import useDocumentProjectCollaborators from 'hooks/documents/useDocumentProjectCollaborators';
import { generateStreamResponse } from 'helpers/openAIChat';
import { copyToClipboard } from 'helpers';
import { success as renderSuccess } from 'helpers/toaster';
import AskAIBar from 'Components/AskAIBar/AskAIBar';
import { GENERATE_INNER_HTML_PROMPT_POSTFIX } from 'constants';

function EditorSection({
  editor,
  setAITitle,
  handleRewrite,
  comments,
  setComments,
  fetchComments,
  isComment,
  hoverId,
  selection,
  secondWidth,
  handleWriteParagraph,
  openModalLink,
  mainContainerRef,
  handleExpandChild,
  addComment,
  resolveComment,
  deleteComment,
  setSelection,
}) {
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const [aiContent, setAiContent] = useState('');
  const [contentGenerated, setContentGenerated] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const menuRef = useRef(null);

  const { sticky, stickyRef } = useSticky();
  const abortController = useRef();

  const { documentId } = useParams();
  const { project_collaborators: projectCollaborators } =
    useDocumentProjectCollaborators({ documentId });

  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const commentUUID = query?.get('comment');

  const { state } = editor || {};

  useEffect(() => {
    if (contentGenerated) {
      setIsLoading(false);
    }
  }, [contentGenerated]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuPosition({ left: 0, top: 0 });
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  // useEffect(() => {
  //   if (editor) {
  //     let timeoutId;
  //     const handleSelectionUpdate = ({ editor }) => {
  //       clearTimeout(timeoutId);
  //       timeoutId = setTimeout(() => {
  //         const { to, from } = editor.state.selection;
  //         const text = editor.state.doc.textBetween(from, to, ' ');
  //         if (!editor.state.selection.empty && text.trim()) {
  //           const { left, bottom } = editor.view.coordsAtPos(to);
  //           setMenuPosition({ top: bottom, left });
  //         } else {
  //           setMenuPosition({ left: 0, top: 0 });
  //         }
  //         setSelection({ from, to });
  //       }, 1000); // Delay menu appearance
  //     };

  //     editor.on('selectionUpdate', handleSelectionUpdate);

  //     // Clean up event listener when component unmounts or editor changes
  //     return () => {
  //       editor.off('selectionUpdate', handleSelectionUpdate);
  //       clearTimeout(timeoutId);
  //     };
  //   }
  // }, [editor]);

  useEffect(() => {
    if (editor) {
      // Set up mouseup event listener to detect when text selection is complete
      const handleMouseUp = () => {
        const { to, from } = editor.state.selection;
        const text = editor.state.doc.textBetween(from, to, ' ');

        if (!editor.state.selection.empty && text.trim()) {
          const { left, bottom } = editor.view.coordsAtPos(to);
          setMenuPosition({ top: bottom, left });
        } else {
          setMenuPosition({ left: 0, top: 0 });
        }

        setSelection({ from, to });
      };

      document.addEventListener('mouseup', handleMouseUp);

      // Clean up event listener when component unmounts or editor changes
      return () => {
        document.removeEventListener('mouseup', handleMouseUp);
      };
    }
  }, [editor]);

  // const handleRewriteChild = (type) => {
  //   handleRewrite(type);
  //   setMenuPosition({ left: 0, top: 0 });
  // };

  const handleRewriteChild = (type) => {
    if (type !== undefined) {
      handleRewrite(type);
      setMenuPosition({ left: 0, top: 0 });
    }
  };

  const handleExpand = async () => {
    setMenuPosition({ left: 0, top: 0 });
    handleExpandChild();
    setMenuPosition({ left: 0, top: 0 });
  };

  const elements = document.querySelectorAll('.comment');

  const transformedElements = useMemo(() => {
    if (!elements) {
      return [];
    }
    return [...elements].filter(
      (obj, index, self) => index === self.findIndex((t) => t.id === obj.id),
    );
  }, [elements]);

  const userList = useMemo(() => {
    if (!projectCollaborators?.length) {
      return [];
    }

    return projectCollaborators.map((userInfo) => {
      return {
        id: userInfo.id,
        display: userInfo.name,
        avatar: userInfo.avatar,
      };
    });
  }, [projectCollaborators]);

  return (
    <>
      <div className="text-editor">
        <MenuBar
          editor={editor}
          ref={stickyRef}
          styles={
            sticky
              ? {
                  padding: '8px 24px',
                  position: 'fixed',
                  top: 67,
                  zIndex: 998,
                  width: secondWidth,
                  boxShadow: 'rgba(33, 35, 38, 0.1) 0px 10px 10px -10px',
                }
              : {
                  padding: '8px 24px',
                  width: '100%',
                }
          }
          openModalLink={openModalLink}
        />
        <div
          style={{
            height: sticky ? `${stickyRef.current?.clientHeight}px` : '0px',
          }}
        />
        <div className="text-container_wrapper">
          <EditorContent
            editor={editor}
            onContextMenu={(event) => {
              event.preventDefault();
              const { to, from } = selection || {};
              const text = state.doc.textBetween(from, to, ' ');
              if (!editor.state.selection.empty && text.trim()) {
                setMenuPosition({ top: event.pageY, left: event.pageX });
              }
            }}
            spellCheck
          />
        </div>

        {!!menuPosition.left && (
          <ContextMenu
            menuPosition={menuPosition}
            checkCommentExist={handleRewriteChild}
            handleExpand={handleExpand}
            setMenuPosition={setMenuPosition}
            addComment={addComment}
            isComment={isComment}
            handleWriteParagraph={handleWriteParagraph}
            userList={userList}
            mainContainerRef={mainContainerRef}
          />
        )}
        {transformedElements?.map((element) => {
          if (element.id) {
            const { top, left } = element?.getBoundingClientRect() || {};

            if (!Object.keys(comments).length) {
              return null;
            }

            return (
              <CommentComponent
                key={element.id}
                rectValues={{
                  top,
                  offsetWidth: element.offsetWidth,
                  left,
                }}
                commentId={element.id}
                comments={comments}
                setComments={setComments}
                fetchComments={fetchComments}
                isListOpen={
                  hoverId === element.id || commentUUID === element.id
                }
                mainContainerRef={mainContainerRef}
                userList={userList}
                resolveComment={resolveComment}
                deleteComment={deleteComment}
              />
            );
          } else {
            return null;
          }
        })}
        {/* <AskAIBar
          handlePromptAI={handlePromptAI}
          aiContent={aiContent}
          setAiContent={setAiContent}
          isLoading={isLoading}
          handleCopy={handleCopy}
          handleInsert={handleInsert}
          contentGenerated={contentGenerated}
        /> */}
      </div>
    </>
  );
}

export default EditorSection;
