import BSButton from 'Components/Button/BSButton';
import FormikTextField from 'Components/Form/FormikTextField';
import PasswordValidationError from 'Components/Password/Error';
import {
  PASSWORD_FORMAT,
  RESET_PASSWORD_BUTTON_TEXT,
  RESET_PASSWORD_HEADING,
} from 'constants';
import { ErrorMessage, Formik } from 'formik';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import passwordService from 'services/passwordService';
import * as Yup from 'yup';
import ImageView from '../../../Components/Image/ImageView';
import styles from '../SignInModal/SignUpModal.module.scss';

const ResetPasswordModal = ({ show, handleClose }) => {
  const navigate = useNavigate();
  const resetPasswordModalShow = true;

  const resetPasswordModalCloseHandler = () => {
    // navigate('/signup?login=true');
    navigate('/log-in');
  };

  const initialValues = {
    reset_password_token: '',
    password: '',
    password_confirmation: '',
  };

  const validationSchema = Yup.object({
    reset_password_token: Yup.string().required(
      'Reset password token is required.',
    ),
    password: Yup.string()
      .required('Password is required.')
      .min(8, 'Password must be at least 8 characters.')
      .max(18, 'Password must be at most 18 characters.'),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Passwords must match.')
      .matches(PASSWORD_FORMAT, {
        message: PasswordValidationError(),
      }),
  });

  const handleSubmit = async (values) => {
    const body = { user: values };
    try {
      await passwordService.resetPassword(body);
      // navigate('/signup?login=true');
      navigate('/log-in');

      renderSuccess('Password reset Successfully!');
    } catch (error) {
      renderError(error);
    }
  };

  return (
    <div>
      <Modal
        show={resetPasswordModalShow}
        onHide={resetPasswordModalCloseHandler}
        dialogClassName="fixwidthModal_476px"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop
      >
        <div>
          <Modal.Header
            className={styles.AuthModalHeader}
            closeButton
          ></Modal.Header>
          <Modal.Body className={styles.ModalBody}>
            <div className={styles.modalBodyWrapper}>
              <div
                className={
                  'd-flex flex-column align-items-center ' +
                  styles.headingSection
                }
              >
                <ImageView
                  src="/Images/vocable__logo_svg.svg"
                  alt="Vocable_logo"
                  class="img-fluid logo-img m-0"
                ></ImageView>
                <p className={styles.modalHeadingText}>
                  {RESET_PASSWORD_HEADING}
                </p>
              </div>
              <div className={styles.ModalInnerWrapper}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  validateOnMount={true}
                  enableReinitialize={true}
                >
                  {({ values, isValid }) => {
                    return (
                      <Form
                        className={
                          'd-flex flex-column  ' + styles.ModalInputSection
                        }
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <FormikTextField
                          name="reset_password_token"
                          type="text"
                          placeholder="Enter your reset token"
                          classList="w-100 input"
                        ></FormikTextField>
                        <ErrorMessage name="reset_password_token">
                          {(msg) => <span className="error">{msg}</span>}
                        </ErrorMessage>
                        <FormikTextField
                          Label="New Password"
                          name="password"
                          type="password"
                          classList="input input__password"
                          placeholder="&#183;&#183;&#183;&#183;&#183;&#183;&#183;"
                        ></FormikTextField>
                        <ErrorMessage name="password">
                          {(msg) => <span className="error">{msg}</span>}
                        </ErrorMessage>
                        <FormikTextField
                          Label="Confirm New Password"
                          type="password"
                          name="password_confirmation"
                          classList="input input__password"
                          placeholder="&#183;&#183;&#183;&#183;&#183;&#183;&#183;"
                        ></FormikTextField>
                        <ErrorMessage name="password_confirmation">
                          {(msg) => <span className="error">{msg}</span>}
                        </ErrorMessage>
                        <BSButton
                          variant="dark"
                          classList="w-100"
                          ButtonText={RESET_PASSWORD_BUTTON_TEXT}
                          disabled={!isValid}
                          onClick={() => handleSubmit(values)}
                        ></BSButton>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </div>
  );
};

export default ResetPasswordModal;
