import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import ImageView from 'Components/Image/ImageView';
import { FiCalendar, FiEdit, FiMoreVertical, FiTrash2 } from 'react-icons/fi';
import { ROUTES } from 'constants/routes';
import { getNameInitials } from 'helpers';
import { formatmd } from 'helpers/dates';
import Styles from './ProjectTile.module.scss';
import moment from 'moment';
import { AvatarGroup } from 'Components/AvatarGroup/AvatarGroup';
import { CiCalendar } from 'react-icons/ci';
import { BsThreeDotsVertical } from 'react-icons/bs';
import StyleClasses from './ProjectTile.module.scss';

const ProjectTile = ({
  project: {
    id,
    name,
    editors,
    objective,
    end_date: endDate,
    start_date: startDate,
    content_types: contentTypes,
    campaign_goal: campaignGoals,
  },
  userId,
  handleEdit,
  handleDelete,
}) => {
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);

  const formattedEndDate = formatmd(endDate);
  const formattedStartDate = formatmd(startDate);

  const canDelete = editors.some(
    (editor) => editor.id === userId && editor.owner,
  );

  const handlePropagation = (e) => {
    e.stopPropagation();
  };

  const navigateToProject = () => {
    navigate(ROUTES.ProjectSlug(id));
  };

  const editorsLength = editors?.length || 0;

  // const text = 'Lead Generation';
  return (
    <div
      className={Styles.TileContainer}
      onClick={navigateToProject}
      onMouseEnter={() => setShowDropdown(true)}
      onMouseLeave={() => setShowDropdown(false)}
    >
      <div className={Styles.TileContent}>
        <div className={Styles.card__header}>
          <div
            className={`${Styles.card__header__badge} ${campaignGoals === 'Lead Generation' ? Styles.lead_generation : campaignGoals === 'Brand Awareness' ? Styles.brand_awareness : campaignGoals === 'Engagement' ? Styles.engagement : campaignGoals === 'Sales Conversion' ? Styles.sales_conversion : Styles.engagement}`}
          >
            {campaignGoals}
          </div>
          <AvatarGroup
            size="sm"
            // avatars={['https://i.pravatar.cc/300', 'https://i.pravatar.cc/300','https://i.pravatar.cc/300','https://i.pravatar.cc/300']}
            avatars={contentTypes?.split(',')}
          />
        </div>
        <p className={Styles.card_heading}>{name}</p>
        <div className={Styles.card__date}>
          <CiCalendar color="#667085" height={12} width={12} />
          <span>
            {formattedStartDate} - {formattedEndDate}
          </span>
        </div>
      </div>
      <div className={Styles.TileInfo}>
        <div className={Styles.TileInfo_Actions}>
          <div
            className={Styles.TileInfo_AvatarGroup}
            style={editorsLength === 1 ? { background: 'transparent' } : {}}
          >
            {/* {editors?.map(({ id, avatar, name: userName }) => {
              return avatar ? (
                <ImageView key={id} src={avatar} />
              ) : (
                <div key={id}>{getNameInitials(userName)}</div>
              );
            })} */}

            <div className="d-flex gap-3 stacked-Images-rounded">
              <div
              // className={StyleClasses.ProjectDetail_titleContainer_editors}
              // onClick={() => setEditorRemoveModalOpen(true)}
              >
                {editors?.slice(0, 3)?.map(({ id, avatar, name: userName }) => {
                  return avatar ? (
                    <ImageView
                      key={id}
                      src={avatar}
                      class="rounded-avatar-bordered"
                    />
                  ) : (
                    <div key={id} className={`rounded-profile`}>
                      {getNameInitials(userName)}
                    </div>
                  );
                })}

                {editorsLength > 3 && (
                  <div className={'rounded-profile'}>
                    {`+${editorsLength - 3}`}
                  </div>
                )}
              </div>

              {editorsLength === 1 && (
                <span className={`${Styles.collaborator_name}`}>
                  {editors[0].name}
                </span>
              )}
            </div>
          </div>

          {showDropdown && (
            <DropdownButton
              title={<BsThreeDotsVertical color="#182230" />}
              onClick={handlePropagation}
            >
              <Dropdown.Item onClick={handleEdit}>
                <div className={Styles.DropdownItem}>
                  <FiEdit size={14} />
                  <p className={Styles.DropdownItem_Text}>Edit</p>
                </div>
              </Dropdown.Item>
              {canDelete && (
                <Dropdown.Item onClick={handleDelete}>
                  <div className={Styles.DropdownItem}>
                    <FiTrash2 size={14} />
                    <p className={Styles.DropdownItem_Text}>Delete</p>
                  </div>
                </Dropdown.Item>
              )}
            </DropdownButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectTile;
