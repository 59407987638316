import React, { useState, useEffect } from 'react';
import Styles from './Styles.module.scss';
import FormikTextField from 'Components/Form/FormikTextField';
import { Form } from 'react-bootstrap';
import { ErrorMessage, Formik } from 'formik';
import ImageUploader from '../ImageUploader/ImageUploader';
import * as Yup from 'yup';
import { nextStep, updateStep1, prevStep } from 'redux/slices/newOnboarding';
import { useDispatch, useSelector } from 'react-redux';
import ButtonSection from '../ButtonSection/ButtonSection';
import { useNavigate } from 'react-router-dom';
import { error as errorToast, success as successToast } from 'helpers/toaster';
import { getDomainName } from 'helpers/onboarding';
import { resetOnboarding } from 'redux/slices/newOnboarding';

const Step1 = ({
  haveweb,
  setHaveweb,
  autofill,
  setAutofill,
  openSubscriptionModal,
  flow,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const onboardingData = useSelector((state) => state.newOnboarding.step1);
  const [isScrapping, setIsScrapping] = useState(false);
  const [previousUrl, setPreviousUrl] = useState('');

  const [initialValues, setInitialValues] = useState({
    url: onboardingData.url || '',
    description: onboardingData.description || '',
    logo: onboardingData.logo || null,
  });

  const getValidationSchema = (haveWeb) => {
    if (haveWeb) {
      return Yup.object().shape({
        description: Yup.string().trim().required('Description is required'),
      });
    } else {
      return Yup.object().shape({
        url: Yup.string()
          .trim()
          .required('Website is required')
          .url('Invalid website'),
      });
    }
  };

  const handleUndo = () => {
    dispatch(resetOnboarding());
    // setJobId(null);
  };

  const handleNext = (values) => {
    values.url !== previousUrl && handleUndo();
    // values.url !== previousUrl && handleAutofill(values.url, values?.logo);
    const formData = { ...values };
    if (values.logo instanceof File) {
      const reader = new FileReader();
      reader.onloadend = () => {
        formData.logo = reader.result;
        dispatch(updateStep1(formData));
        dispatch(nextStep());
      };
      reader.readAsDataURL(values.logo);
    } else {
      dispatch(updateStep1(formData));
      dispatch(nextStep());
    }
  };

  const validateURL = (url) => {
    const urlRegex =
      /^(https?:\/\/)?((([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})|((\d{1,3}\.){3}\d{1,3}))(:\d+)?(\/.*)?$/;
    return urlRegex.test(url);
  };

  const handleCancelOnboarding = () => {
    navigate('/');
    // return () => {
    //   dispatch(resetOnboarding());
    // };
  };

  useEffect(() => {
    setPreviousUrl(onboardingData.url || '');
  }, [onboardingData.url]);

  useEffect(() => {
    const handleGlobalKeyPress = (e) => {
      const isDescriptionField = e.target.id === 'description';
      if (e.key === 'Enter' && !isDescriptionField) {
        e.preventDefault();
      }
    };
    document.addEventListener('keydown', handleGlobalKeyPress);
    return () => {
      document.removeEventListener('keydown', handleGlobalKeyPress);
    };
  }, []);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={getValidationSchema(haveweb)}
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={handleNext}
      >
        {({ values, setFieldValue, isValid, dirty, submitForm }) => {
          const { description } = values || {};
          const isDescriptionVisible = haveweb;
          const handleClick = () => {
            submitForm();
          };
          const handleUndo = () => {
            setAutofill(false);
            setFieldValue('url', '');
            dispatch(resetOnboarding());
          };
          const handleHaveWebsite = () => {
            setFieldValue('url', '');
            setFieldValue('description', '');

            setHaveweb(!haveweb);
            dispatch(resetOnboarding());
          };

          return (
            <>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                }}
                className={Styles.form1_wrapper}
              >
                <div>
                  {!haveweb ? (
                    <>
                      {autofill ? (
                        <div className={Styles.form1_wrapper__undo_redo}>
                          <span>
                            Data auto-filled from {getDomainName(values.url)}
                          </span>
                          {isScrapping ? (
                            <div className={Styles.loader}>Loading...</div>
                          ) : (
                            <button onClick={handleUndo}>Undo</button>
                          )}
                        </div>
                      ) : (
                        <>
                          <div className={`w-100 d-flex align-items-end gap-2`}>
                            <FormikTextField
                              name="url"
                              type="text"
                              placeholder="https://example.com"
                              classList="input input__Email w-100"
                              Label="Enter your website"
                              isRequired
                            />
                          </div>
                          <div>
                            <ErrorMessage name="url">
                              {(msg) => <span className="error">{msg}</span>}
                            </ErrorMessage>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <div>
                      <FormikTextField
                        id="description"
                        name="description"
                        type="text"
                        placeholder="Add description..."
                        Label="Describe in details what you’re promoting"
                        classList="input form-control text-area-height"
                        isRequired
                        isTextArea
                      />
                      <ErrorMessage
                        name="description"
                        component="div"
                        className="error mt-2"
                      />
                    </div>
                  )}

                  <button
                    className={Styles.form1_wrapper__btn}
                    onClick={handleHaveWebsite}
                  >
                    {!haveweb
                      ? 'Don’t have a website'
                      : 'Autofill from my website'}
                  </button>
                </div>

                <div className={Styles.form1_wrapper__uploaders}>
                  <div className={Styles.form1_wrapper__uploaders__left}>
                    <label
                      htmlFor=""
                      className={Styles.form1_wrapper__uploaders__left__label}
                    >
                      Company Logo
                    </label>
                    <div
                      className={
                        Styles.form1_wrapper__uploaders__left__image_container
                      }
                    >
                      <ImageUploader
                        setFieldValue={setFieldValue}
                        fieldName="logo"
                        initialImage={values.logo}
                      />
                      <div
                        className={
                          Styles.form1_wrapper__uploaders__left__image_container__text
                        }
                      ></div>
                    </div>
                  </div>
                </div>
                <ButtonSection
                  btn_1="Save and Continue"
                  btn_2={flow === 'newPlan' ? 'Cancel' : 'Skip onboarding'}
                  handleNext={handleClick}
                  handleBack={
                    flow === 'onboarding'
                      ? openSubscriptionModal
                      : handleCancelOnboarding
                  }
                  showIcon={flow === 'newPlan' ? false : true}
                />
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
};

export default Step1;
