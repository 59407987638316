import React, { useEffect, useMemo, useState } from 'react';
import { ErrorMessage, Formik } from 'formik';
import * as Yup from 'yup';
import {
  FiFolder,
  FiFolderPlus,
  FiLayers,
  FiPlusCircle,
  FiXCircle,
} from 'react-icons/fi';
import { useDispatch } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { setLoader } from 'redux/slices/loader';

import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import BSButton from 'Components/Button/BSButton';
import FormikTextField from 'Components/Form/FormikTextField';
import Styles from './SaveAsModal.module.scss';
import { saveAsDocument } from 'services/documentService';
import ProjectDrawer from 'Components/Projects/ProjectDrawer';
import { createFolder, getFolders } from 'services/folderService';
import { getProjectFolders } from 'services/projectService';
import { getTask } from '../../../services/taskService';
import useProjects from 'hooks/projects/useProjects';
import Dropdown from 'Components/Dropdown/Dropdown';
import { useSelector } from 'react-redux';
import UpgradeModal from '../UpgradeModal/UpgradeModal';

const SaveAsModal = ({
  title: modalTitle,
  document,
  openSaveAsModal,
  show,
  onHide,
}) => {
  const { data: projectPages } = useProjects({ searchTerm: '' });

  const projects = useMemo(() => {
    return projectPages?.pages.flatMap((page) => page?.data?.projects);
  }, [projectPages?.pages]);

  const selectedFolder = document?.folders?.[0];
  const [folders, setFolders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const [newFolderName, setNewFolderName] = useState('');
  const [toggleNewFolder, setToggleNewFolder] = useState(false);
  const [projectDrawerView, setProjectDrawerView] = useState(false);
  const [canCreateNewProject, setCanCreateNewProject] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { id: userId, subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  useEffect(() => {
    fetchFolders(document?.project_id || selectedFolder?.project_id);
    setCanCreateNewProject(checkIfCanCreateNewProject());
  }, []);

  const initialValues = {
    title: document?.title,
    folder_id: selectedFolder?.id,
    project_id: document?.project_id || selectedFolder?.project_id,
  };

  const validationSchema = Yup.object({
    title: modalTitle
      ? Yup.string()
      : Yup.string().required('Title is required'),
    folder_id: Yup.string(),
    project_id: Yup.string(),
  });

  const handleSubmit = ({
    title,
    folder_id: folderId,
    project_id: projectId,
  }) => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Saving!',
        description: 'Please wait...',
      }),
    );
    saveAsDocument({
      title,
      folderId,
      projectId,
      documentId: document?.id,
    })
      .then((res) => {
        renderSuccess(res?.data?.message);
        onHide();
        dispatch(setLoader({ isLoading: false }));
      })
      .catch((error) => {
        renderError(error);
        dispatch(setLoader({ isLoading: false }));
      });
  };

  const projectOptions = useMemo(() => {
    return (
      projects?.map((project) => {
        return {
          value: project.id,
          label: (
            <div className="d-flex align-items-center gap-3">
              <FiLayers size={18} color="#101828" />
              <p className="w-100">{project.name}</p>
            </div>
          ),
        };
      }) || []
    );
  }, [projects]);

  const folderOptions = folders?.map((folder) => {
    return {
      value: folder.id,
      label: (
        <div className="d-flex align-items-center gap-3">
          <FiFolder size={18} color="#101828" />
          <p className="w-100">{folder.title}</p>
        </div>
      ),
    };
  });

  const fetchFolders = async (projectId) => {
    dispatch(setLoader({ isLoading: true }));

    try {
      const responseFolders = projectId
        ? await getProjectFolders(projectId)
        : await getFolders();
      dispatch(setLoader({ isLoading: false }));
      setFolders(responseFolders?.data?.folders);
    } catch (error) {
      console.error(error);
      dispatch(setLoader({ isLoading: false }));
    }
  };

  const handleCreate = async (inputValue, projectId, setFieldValue) => {
    try {
      setIsLoading(true);
      const folderCreationResponse = await createFolder(projectId, inputValue);

      const { data } = folderCreationResponse || {};
      const { folder } = data || {};
      const { id } = folder || {};
      const newOption = {
        id,
        title: inputValue,
      };

      setIsLoading(false);
      setFolders((prev) => [...prev, newOption]);
      setFieldValue('folder_id', id, true);
      setToggleNewFolder(false);
      setNewFolderName('');
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const checkIfCanCreateNewProject = async () => {
    try {
      const taskId = document?.task_associated;

      if (!taskId) {
        return true;
      }

      const {
        data: { task },
      } = await getTask(taskId);

      if (!task.project_id) {
        return true;
      }

      return false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };
  return (
    <>
      <Modal
        show={show}
        onHide={onHide}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        style={{ zIndex: 1001 }}
        backdropClassName={Styles.SaveAsModalBackdrop}
      >
        <Modal.Header className={Styles.SaveAsModalHeader} closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {modalTitle || 'Save As'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className={'d-flex flex-column ' + Styles.SaveAsModalBody}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize={true}
            validateOnChange={true}
          >
            {({ values, isValid, setFieldValue }) => {
              return (
                <form
                  className="d-flex flex-column gap-3"
                  onSubmit={(e) => e.preventDefault()}
                >
                  {!modalTitle && (
                    <>
                      <FormikTextField
                        name="title"
                        type="text"
                        placeholder="File name"
                        Label="File name *"
                        classList="input"
                      />
                      <ErrorMessage name="title" component="div" />
                    </>
                  )}

                  <div className="d-flex align-items-center gap-3">
                    <div className="w-100">
                      <Dropdown
                        name="project_id"
                        label="Select campaign"
                        placeholder="Select campaign"
                        options={projectOptions}
                        value={projectOptions.find(
                          (option) => option.value === values.project_id,
                        )}
                        defaultValue={
                          document?.project_id || selectedFolder?.project_id
                        }
                        onChange={(option) => {
                          setFieldValue('project_id', option?.value);
                          fetchFolders(option?.value);
                          setFieldValue('folder_id', null);
                        }}
                        isClearable
                        isDisabled={
                          !canCreateNewProject ||
                          currentSubscription?.plan_name === 'Basic Plan'
                        }
                      />
                    </div>
                    {canCreateNewProject && (
                      <FiPlusCircle
                        className="align-self-end mb-2"
                        size={25}
                        style={{ cursor: 'pointer' }}
                        onClick={
                          // () => {
                          // setProjectDrawerView(true);

                          currentSubscription?.plan_name === 'Basic Plan'
                            ? () => setShowUpgradeModal(true)
                            : () => setProjectDrawerView(true)
                          // }
                        }
                      />
                    )}
                  </div>

                  <div className="d-flex align-items-center gap-3">
                    <div className="w-100">
                      <Dropdown
                        name="folder_id"
                        label="Select folder"
                        placeholder="Select folder"
                        options={folderOptions}
                        value={folderOptions.find(
                          (option) => option.value === values?.folder_id,
                        )}
                        defaultValue={
                          document?.project_id || selectedFolder?.project_id
                        }
                        onChange={(option) => {
                          setFieldValue('folder_id', option?.value);

                          // When selecting a folder, choose the project associated with that folder
                          // and fetch the folder for that project as well
                          if (option?.value) {
                            const projectElement = projectOptions.find(
                              (project) =>
                                project.value ===
                                folders.find(
                                  (folder) => option?.value === folder.id,
                                )?.project_id,
                            );

                            setFieldValue('project_id', projectElement?.value);
                            fetchFolders(projectElement?.value);
                          }
                        }}
                        isClearable
                        isDisabled={isLoading || toggleNewFolder}
                        isLoading={isLoading}
                      />
                    </div>
                    {toggleNewFolder ? (
                      <FiXCircle
                        className="align-self-end mb-2"
                        size={25}
                        color="#212529"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setToggleNewFolder(!toggleNewFolder);
                        }}
                      />
                    ) : (
                      <FiFolderPlus
                        className="align-self-end mb-2"
                        size={25}
                        color="#212529"
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          setToggleNewFolder(!toggleNewFolder);
                          setFieldValue('folder_id', '');
                        }}
                      />
                    )}
                  </div>

                  {toggleNewFolder && (
                    <div className="row">
                      <div className="col-9" style={{ paddingRight: '0px' }}>
                        <input
                          name="title"
                          type="text"
                          placeholder="New folder name"
                          className="form-control"
                          onChange={(e) => setNewFolderName(e.target.value)}
                        />
                      </div>
                      <div className="col-3" style={{ paddingLeft: '10px' }}>
                        <BSButton
                          ButtonText="Create"
                          classList="w-100 secondary_btn btn-sm"
                          variant="dark"
                          disabled={!newFolderName}
                          onClick={() =>
                            handleCreate(
                              newFolderName,
                              values.project_id,
                              setFieldValue,
                            )
                          }
                          style={{ height: '37px' }}
                        />
                      </div>
                    </div>
                  )}

                  <BSButton
                    ButtonText="Save"
                    classList="w-100 mt-3 secondary_btn"
                    variant="dark"
                    disabled={!isValid}
                    onClick={() => handleSubmit(values)}
                  />
                </form>
              );
            }}
          </Formik>
        </Modal.Body>
      </Modal>

      {projectDrawerView && (
        <ProjectDrawer
          show={projectDrawerView}
          onHide={async () => {
            await openSaveAsModal();
            setProjectDrawerView(false);
          }}
          action={'create'}
        />
      )}

      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
        />
      )}
    </>
  );
};

export default SaveAsModal;
