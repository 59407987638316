import React, { useState, useEffect } from 'react';
import { CgSpinner } from 'react-icons/cg';
import Styles from './LoadingState.module.scss';

const LoadingState = ({ className }) => {
  const [loadingText, setLoadingText] = useState('Analyzing your site...');

  useEffect(() => {
    const timer1 = setTimeout(() => {
      setLoadingText('Understanding your audience...');
    }, 4000);

    const timer2 = setTimeout(() => {
      setLoadingText('Crafting your messaging...');
    }, 7000);

    const timer3 = setTimeout(() => {
      setLoadingText('Researching your keywords...');
    }, 10000);

    const timer4 = setTimeout(() => {
      setLoadingText('Generating your brand identity...');
    }, 13000);

    return () => {
      clearTimeout(timer1);
      clearTimeout(timer2);
      clearTimeout(timer3);
      clearTimeout(timer4);
    };
  }, []);
  return (
    <div className={`${Styles.SpinnerContainer} ${className}`}>
      <div className={Styles.SpinnerContainer__inner}>
        <CgSpinner size={40} color={'#101828'} />
        {loadingText && <p className={Styles.SpinnerText}>{loadingText}</p>}
      </div>
    </div>
  );
};

export default LoadingState;
