import * as Yup from 'yup';
import { titleize } from 'helpers';

export const integratonFieldsToObject = (fields, defaultValues) => {
  return fields.reduce((acc, field) => {
    const fieldKey = field.field;
    if (defaultValues && fieldKey === 'key') {
      return acc;
    }

    return {
      ...acc,
      [fieldKey]: defaultValues?.[fieldKey] ?? '',
    };
  }, {});
};

export const integrationFieldsToSchema = (fields, defaultValues) => {
  return fields.reduce((acc, field) => {
    const fieldKey = field.field;

    // If the field key is 'key' and defaultValues is passed, mark it as optional
    if (defaultValues && fieldKey === 'key') {
      return {
        ...acc,
        [fieldKey]: Yup.string().optional(),
      };
    }

    // Add validation that trims whitespace and prevents only space input
    return {
      ...acc,
      [fieldKey]: Yup.string()
        .trim() // Remove leading and trailing spaces
        .strict(true) // Enforce trimming during validation
        .test(
          'not-only-spaces',
          `${titleize(fieldKey)} cannot contain only spaces`,
          (value) => value && value.trim().length > 0,
        )
        .required(`${titleize(fieldKey)} is required`),
    };
  }, {});
};

export const settingsValidationSchema = Yup.object().shape({
  story_detail: Yup.object().shape({
    author: Yup.string()
      .trim()
      .required('Author is required')
      .test(
        'not-only-spaces',
        'Author cannot contain only spaces',
        (value) => value && value.trim().length > 0,
      ),
    category: Yup.string()
      .trim()
      .required('Category is required')
      .test(
        'not-only-spaces',
        'Category cannot contain only spaces',
        (value) => value && value.trim().length > 0,
      ),
    // tags: Yup.string()
    // .trim()
    // .required('Tag is required')
    // .test(
    //   'not-only-spaces',
    //   'Tag cannot contain only spaces',
    //   (value) => value && value.trim().length > 0,
    // ),
    // category: Yup.string()
    //   .trim()
    //   .required('Category is required')
    //   .test(
    //     'not-only-spaces',
    //     'Category cannot contain only spaces',
    //     (value) => value && value.trim().length > 0,
    //   ),
    tags: Yup.array()
      .of(Yup.string().trim().required('Tag cannot be empty'))
      .min(1, 'Tags are required.'),
    // due_date: Yup.date().required('Due date is required').nullable(),
    // cover_image: Yup.string()
    //   .required('Cover image is required'),
    // Yup.array()
    //   .of(Yup.string().required())
    //   .min(1, 'Keyword is required'),
  }),
  seo_setting: Yup.object().shape({
    slug: Yup.string()
      .trim()
      .required('Slug is required')
      .test(
        'not-only-spaces',
        'Slug cannot contain only spaces',
        (value) => value && value.trim().length > 0,
      ),
    meta_description: Yup.string()
      .trim()
      .required('Meta description is required')
      .test(
        'not-only-spaces',
        'Meta description cannot contain only spaces',
        (value) => value && value.trim().length > 0,
      ),
  }),
  publish_settings: Yup.object().shape({
    scheduled_at: Yup.date()
      .nullable()
      // .test('is-future-date', 'Please select a future date and time to schedule or leave empty to publish now.', function(value) {
      .test(
        'is-future-date',
        'Please select a future date and time or leave empty.',
        function (value) {
          if (!value) {
            return true;
          }
          return value && new Date(value) > new Date();
        },
      ),
    // .required('If scheduling a post, please select a date and time.'),
    status: Yup.string()
      .trim()
      .required('Status is required')
      .test(
        'not-only-spaces',
        'Status cannot contain only spaces',
        (value) => value && value.trim().length > 0,
      ),
  }),
});
