import React, { useState, useEffect, useRef } from 'react';
import styles from './ChatGptSelect.module.scss';
import { IoIosArrowDown } from 'react-icons/io';
import { ReactComponent as ChatGpt3 } from '../../../../assets/chatgpt/AI Models.svg';
import { ReactComponent as ChatGpt4o } from '../../../../assets/chatgpt/AI Models (1).svg';
import { ReactComponent as ChatGpt4 } from '../../../../assets/chatgpt/type=Chat gpt 4.svg';
import { ReactComponent as Claude3Opus } from '../../../../assets/chatgpt/type=Claude 3 Opus.svg';
import { ReactComponent as Claude3Sonnet } from '../../../../assets/chatgpt/type=Claude 3 Sonnet.svg';
import { ReactComponent as Gemini15Flash } from '../../../../assets/chatgpt/type=Geminni 1.5 Flash.svg';
import { ReactComponent as Gemini15Pro } from '../../../../assets/chatgpt/type=Geminni 1.5 Pro.svg';
import { TbExclamationCircle } from 'react-icons/tb';
import { RiSearchLine } from 'react-icons/ri';
import { useSelector } from 'react-redux';

const icons = {
  // 'gpt-3.5-turbo': <ChatGpt3 />,
  'gpt-4o': <ChatGpt4o />,
  'gpt-4': <ChatGpt4 />,
  'claude-3-opus-20240229': <Claude3Opus />,
  'claude-3-5-sonnet-20240620': <Claude3Sonnet />,
  'gemini-1.5-flash': <Gemini15Flash />,
  'gemini-1.5-pro': <Gemini15Pro />,
};

const descriptions = {
  // 'gpt-3.5-turbo': 'Great for everyday tasks',
  'gpt-4o': 'Fastest & most advanced model',
  'gpt-4': 'Most powerful AI model',
  'claude-3-opus-20240229': 'Anthropic’s most advanced model',
  'claude-3-5-sonnet-20240620': 'Anthropic’s balanced model',
  'gemini-1.5-flash': "Google's latest & fastest model",
  'gemini-1.5-pro': "Google's most advanced model",
};

const colors = {
  // 'gpt-3.5-turbo': '#e6f7ff',
  'gpt-4o': '#ffb3b3',
  'gpt-4': '#d9b3ff',
};

const ChatGptSelect = ({ selected, setSelected }) => {
  const { user } = useSelector((state) => state.auth);
  const { subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  const [isOpen, setIsOpen] = useState(false);
  const [search, setSearch] = useState('');
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const options = [
    // { value: 'gpt-3.5-turbo', label: 'GPT-3.5', disabled: false },
    { value: 'gpt-4o', label: 'GPT-4o', disabled: false },
    { value: 'gpt-4', label: 'GPT-4', disabled: false },
    {
      value: 'claude-3-opus-20240229',
      label: 'Claude 3 Opus',
      disabled: currentSubscription?.plan_name === 'Basic Plan',
    },
    {
      value: 'claude-3-5-sonnet-20240620',
      label: 'Claude 3.5 Sonnet',
      disabled: currentSubscription?.plan_name === 'Basic Plan',
    },
    {
      value: 'gemini-1.5-flash',
      label: 'Gemini 1.5 Flash',
      disabled: currentSubscription?.plan_name === 'Basic Plan',
    },
    {
      value: 'gemini-1.5-pro',
      label: 'Gemini 1.5 Pro',
      disabled: currentSubscription?.plan_name === 'Basic Plan',
    },
  ];

  const handleSelect = (option) => {
    if (!option.disabled) {
      setSelected(option.value);
      setIsOpen(false);
    }
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(search.toLowerCase()),
  );

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <div
        className={styles.selected}
        onClick={() => setIsOpen(!isOpen)}
        style={{ backgroundColor: colors[selected] }}
      >
        <span className={styles.icon_selected}>{icons[selected]}</span>
        {options.find((option) => option.value === selected)?.label}
        <span className={styles.arrow}>
          <IoIosArrowDown />
        </span>
      </div>
      {isOpen && (
        <div
          className={styles.optionsContainer}
          style={{
            top:
              dropdownRef.current &&
              dropdownRef.current.getBoundingClientRect().bottom + 300 >
                window.innerHeight
                ? 'auto'
                : '100%',
            bottom:
              dropdownRef.current &&
              dropdownRef.current.getBoundingClientRect().bottom + 300 >
                window.innerHeight
                ? '100%'
                : 'auto',
          }}
        >
          <div className={styles.modal_container}>
            <span>Models</span>
            <button>
              <TbExclamationCircle size={16} />
            </button>
          </div>
          <div className="position-relative">
            <input
              type="text"
              className={styles.search}
              placeholder="Search..."
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            <span className={styles.search_icon}>
              <RiSearchLine size={16} color="#667085" />
            </span>
          </div>
          <div className={styles.options}>
            {filteredOptions.map((option, index) => (
              <div
                key={index}
                className={`${styles.option} ${option.value === selected && styles.active_option} ${option.disabled && styles.disabled_option}`}
                onClick={() => handleSelect(option)}
              >
                <div className={styles.option_left}>
                  <span className={styles.icon}>{icons[option.value]}</span>
                  <div className={styles.text_container}>
                    <span className={styles.option_text}>{option.label}</span>
                    <span className={styles.option_description}>
                      {descriptions[option.value]}
                    </span>
                  </div>
                </div>
                {option.value === selected && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M13.3337 4L6.00033 11.3333L2.66699 8"
                      stroke="#00A7B7"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChatGptSelect;
