import React, { useState } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import { FiTool } from 'react-icons/fi';

import { NavLink, useLocation } from 'react-router-dom';
import Styles from './Sidebar.module.scss';
import {
  MAIN_SIDEBAR_OPTIONS,
  TOOLS_SIDEBAR_OPTIONS,
  TOOL_PATHS,
  OTHER_OPTIONS,
} from '../../constants/SidebarItems';

import ImageView from 'Components/Image/ImageView';
import { UserProfileCard } from 'Components/UserProfileCard/UserProfileCard';
import { ManageSubscriptionCard } from 'Components/ManageSubscriptionCard/ManageSubscriptionCard';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';
import Rocketicon from '../../assets/landingpage/rocket.svg';
import { useNavigate } from 'react-router-dom';

function Sidebar({ planName, isOpen }) {
  const location = useLocation();
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const navigate = useNavigate();

  const activeAccordion = () => {
    if (TOOL_PATHS.some((elem) => location.pathname.includes(elem))) {
      return '1';
    }

    return false;
  };

  return (
    <div
      className={Styles.Sidebar}
      style={{
        display: isOpen ? 'block' : 'none',
      }}
    >
      <div>
        <div style={{ cursor: 'pointer', paddingBottom: '16px' }}>
          <ImageView
            src="/Images/vocable_sidebar_logo.svg"
            alt="Vocable"
            class="img-fluid logo-img"
            onClick={() => navigate('/')}
          />
        </div>
        <nav className={Styles.Accordion}>
          {MAIN_SIDEBAR_OPTIONS.map((option) => {
            if (planName === 'Basic Plan' && option.id === 'teams') {
              return (
                <NavLink
                  key={option.id}
                  to={'#'} // Prevent navigation if disabled
                  className={Styles.sidebarListItemProPlan}
                  onClick={(event) => {
                    setShowUpgradeModal(true);
                  }}
                >
                  <div className={option.new && Styles.item_icon}>
                    {option.icon}
                  </div>
                  <span className={Styles.listItemText}>{option.label}</span>
                </NavLink>
              );
            }
            return (
              <NavLink
                key={option.id}
                to={option.new ? '#' : option.path} // Prevent navigation if disabled
                className={({ isActive }) =>
                  option.new
                    ? Styles.sidebarListItemDisabled
                    : isActive
                      ? Styles.sidebarListItemActive
                      : Styles.sidebarListItemInactive
                }
                onClick={(event) => {
                  if (option.new) {
                    event.preventDefault(); // Prevent default behavior if disabled
                  }
                }}
              >
                <div className={option.new && Styles.item_icon}>
                  {option.icon}
                </div>
                <span className={Styles.listItemText}>{option.label}</span>
                {option.new && (
                  <span className={Styles.comming_soon}>Coming Soon</span>
                )}
              </NavLink>
            );
          })}
        </nav>
        <div>
          <span className={Styles.Others_text}>Other</span>
          <Accordion
            className={Styles.Accordion}
            defaultActiveKey={activeAccordion}
          >
            <Accordion.Item bsPrefix={Styles.Accordion_Item} eventKey="1">
              <Accordion.Header bsPrefix={Styles.Accordion_Header}>
                <p
                  className={
                    activeAccordion()
                      ? Styles.sidebarListItemActive
                      : Styles.sidebarListItemInactive
                  }
                >
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10.6668 14V3.33333C10.6668 2.97971 10.5264 2.64057 10.2763 2.39052C10.0263 2.14048 9.68712 2 9.3335 2H6.66683C6.31321 2 5.97407 2.14048 5.72402 2.39052C5.47397 2.64057 5.3335 2.97971 5.3335 3.33333V14M2.66683 4.66667H13.3335C14.0699 4.66667 14.6668 5.26362 14.6668 6V12.6667C14.6668 13.403 14.0699 14 13.3335 14H2.66683C1.93045 14 1.3335 13.403 1.3335 12.6667V6C1.3335 5.26362 1.93045 4.66667 2.66683 4.66667Z"
                      stroke="white"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                  <span className={Styles.listItemText}>Tools</span>
                </p>
              </Accordion.Header>
              <Accordion.Body>
                <nav className={`${Styles.Accordion} Accordion_custom_nav`}>
                  {TOOLS_SIDEBAR_OPTIONS.map((option) => {
                    if (planName === 'Basic Plan' && option.id === 'research') {
                      return (
                        <NavLink
                          key={option.id}
                          to={'#'} // Prevent navigation if disabled
                          className={Styles.sidebarListItemProPlan}
                          onClick={(event) => {
                            setShowUpgradeModal(true);
                          }}
                        >
                          <div className={option.new && Styles.item_icon}>
                            {option.icon}
                          </div>
                          <span className={Styles.listItemText}>
                            {option.label}
                          </span>
                        </NavLink>
                      );
                    }

                    return (
                      <NavLink
                        key={option.id}
                        to={option.path}
                        className={({ isActive }) =>
                          isActive
                            ? Styles.sidebarListItemActive
                            : Styles.sidebarListItemInactive
                        }
                      >
                        {option.icon}
                        <span className={Styles.listItemText}>
                          {option.label}
                        </span>
                      </NavLink>
                    );
                  })}
                </nav>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
          <nav className={Styles.Accordion}>
            {OTHER_OPTIONS.map((option) => {
              return (
                <NavLink
                  key={option.id}
                  to={option.new ? '#' : option.path} // Prevent navigation if disabled
                  className={({ isActive }) =>
                    option.new
                      ? Styles.sidebarListItemDisabled
                      : isActive
                        ? Styles.sidebarListItemActive
                        : Styles.sidebarListItemInactive
                  }
                  onClick={(event) => {
                    if (option.new) {
                      event.preventDefault(); // Prevent default behavior if disabled
                    }
                  }}
                >
                  <div className={option.new && Styles.item_icon}>
                    {option.icon}
                  </div>
                  <span className={Styles.listItemText}>{option.label}</span>
                </NavLink>
              );
            })}
          </nav>
        </div>
      </div>
      <div>
        <button
          type="button"
          className="px-2 product-tour-btn secondary_btn h-auto btn btn-dark"
          onClick={() =>
            window.open('https://www.youtube.com/@Vocable.ai20', '_blank')
          }
        >
          <img src={`${Rocketicon}`} alt="" />
          <span>Product Tutorials</span>
        </button>
        <ManageSubscriptionCard />
      </div>
      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
        />
      )}
    </div>
  );
}

export default Sidebar;
