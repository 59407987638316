import React, { useState } from 'react';

import { LuPlus } from 'react-icons/lu';

import StyleClasses from './KanbanContainer.module.scss';
import KanbanCard from 'Components/KanbanCard/KanbanCard';
import { titleize } from 'helpers';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';
import { useSelector } from 'react-redux';

function KanbanContainer({
  tasks,
  category,
  onDelete,
  onMove,
  onEdit,
  countOfTasks,
  onCreateClick,
  onDragStart,
  onDrop,
  fetchCPTasks,
  onArchive,
  handleAICreate,
}) {
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { id: userId, subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  return (
    <section
      className={'d-flex flex-column ' + StyleClasses.KanbanContainer}
      onDrop={(e) => onDrop(e, category)}
      onDragOver={(e) => e.preventDefault()}
    >
      <div
        className={
          'd-flex justify-content-between align-items-center ' +
          StyleClasses.KanbanContainer__Header
        }
      >
        <div className="d-flex gap-2">
          <div className="d-flex gap-2 align-items-center">
            <span className={`Dot Dot__${category}`}></span>
            <p>{titleize(category)}</p>
          </div>
          <span className={StyleClasses.count}>{countOfTasks}</span>
        </div>

        <span
          onClick={
            currentSubscription?.plan_name === 'Basic Plan' &&
            location.pathname.startsWith('/projects')
              ? () => setShowUpgradeModal(true)
              : () => onCreateClick(category)
          }
        >
          <LuPlus color="#00a7b7" size={20} />
        </span>
      </div>
      <div className={`d-flex flex-column ${StyleClasses.innerCradContainer}`}>
        {tasks.map((task) => (
          <KanbanCard
            id={task.id}
            key={task.id}
            title={task.title}
            deadline={task.deadline_date}
            priority={task.priority}
            assignedTo={task.assignee?.name}
            assignedToImage={task.assignee?.mini_avatar}
            document={task.document}
            status={category}
            onDelete={onDelete}
            onMove={onMove}
            onEdit={onEdit}
            onDragStart={onDragStart}
            project_id={task.project_id}
            access={task.project_access}
            fetchCPTasks={fetchCPTasks}
            state={task.state}
            onArchive={onArchive}
            description={task.description}
            content_type={task.content_type}
            other_content_type={task.other_content_type}
            handleAICreate={handleAICreate}
          />
        ))}

        {showUpgradeModal && (
          <UpgradeModal
            show={showUpgradeModal}
            onHide={() => setShowUpgradeModal(false)}
            message={
              'Updating campaign related content is a pro feature. Please update to pro plan to access this feature.'
            }
          />
        )}

        {/* <span
        onClick={() => onCreateClick(category)}
        className={StyleClasses.KanbanContainer__AddNewBtn}
      >
        <BsPlus />
        Add New
      </span> */}
      </div>
    </section>
  );
}

export default KanbanContainer;
