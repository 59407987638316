import React from 'react';
import '../PublishModal/PublishModal.scss'; // Import the SCSS file

const XThreadModal = ({ isOpen, onClose, convertItToThread }) => {
  const processThread = () => {
    onClose();
    convertItToThread();
  };
  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content-cs">
        <div className="close-button-container">
          <button
            className="close-button"
            style={{ border: '1px solid #EEEEEE' }}
            onClick={onClose}
          >
            ✕
          </button>
        </div>
        <div className="model-container">
          <div className="modal-icon">
            {/* <div className="icon-placeholder"></div> */}
            <img
              src="/Images/redError.svg"
              alt="icon gif"
              className="icon-gif"
            />
          </div>
          <h2 className="modal-title">Errors</h2>
          <p className="modal-message">
            Your tweet exceeds 280 characters. Do you want to convert it into a
            thread?
          </p>
          <div className="modal-actions d-flex align-items-center justify-content-center">
            <button className="btn btn-success" onClick={processThread}>
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default XThreadModal;
