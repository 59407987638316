import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { postXIntegration } from 'services/integrationService';

const XCallback = () => {
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const oauthToken = queryParams.get('oauth_token');
    const oauthVerifier = queryParams.get('oauth_verifier');
    if (oauthToken && oauthVerifier) {
      postXIntegration({
        oauth_verifier: oauthVerifier,
        oauth_token: oauthToken,
      })
        .then((data) => {
          window.opener.postMessage({ type: 'X_SUCCESS', payload: {} }, '*');
          window.close();
        })
        .catch((error) => {
          console.error('Error fetching X token:', error);

          // Send error back to parent window
          if (window.opener) {
            window.opener.postMessage({ type: 'X_ERROR', payload: error }, '*');
          }

          // Close the popup
          window.close();
        });
    }
  }, [location]);

  return <div>Processing X Login...</div>;
};

export default XCallback;
