import React from 'react';
import Image from 'react-bootstrap/Image';
import './ImageView.module.scss';

const ImageView = ({
  src,
  alt,
  class: className,
  onMouseEnter,
  onClick,
  style,
}) => {
  return (
    <Image
      className={className}
      src={src}
      alt={alt}
      onMouseEnter={onMouseEnter}
      onClick={onClick}
      style={style}
    />
  );
};

export default ImageView;
