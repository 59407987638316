import React from 'react';
import BSButton from 'Components/Button/BSButton';
import FormikTextField from 'Components/Form/FormikTextField';
import { EMAIL_REGEX } from 'constants';
import { ErrorMessage, Formik } from 'formik';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import { Modal } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';
import { useDispatch } from 'react-redux';
import Select from 'react-select';
import { setLoader } from 'redux/slices/loader';
import * as Yup from 'yup';
import sc from './CreateTeamModal.module.scss';
import { FiMail } from 'react-icons/fi';
import { addTeamUser, updateTeamUserAccess } from 'services/teamService';

const options = [
  {
    value: 'admin',
    label: (
      <div className={`d-flex flex-column ${sc.SelectLabel}`}>
        <p className="text-md-Regular">Admin</p>
        <span className="text-sm-18lh">
          can add, update and remove team members
        </span>
      </div>
    ),
  },
  {
    value: 'creator',
    label: (
      <div className={`d-flex flex-column ${sc.SelectLabel}`}>
        <p className="text-md-Regular">Member</p>
        <span className="text-sm-18lh">can view team members only</span>
      </div>
    ),
  },
];

const Styles = {
  option: (styles, { isSelected, isFocused }) => {
    return {
      boxShadow: isFocused ? null : null,
      background: isSelected ? `url("./Images/tickDark.svg")` : '',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '95% 50%',
      backgroundColor: isSelected ? '#F2F4F7!important' : '#fff',
      paddingRight: isSelected ? '12px' : '25px',
      fontSize: '14px',
      lineHeight: '24px',
      fontWeight: '500',
      color: '#101828',

      '&:hover': {
        backgroundColor: 'lightgrey',
        cursor: 'pointer',
      },
    };
  },

  control: (baseStyles) => {
    return {
      ...baseStyles,
      cursor: 'pointer',
    };
  },
};

function AddMemberModal(props) {
  const { access, email, name, editName } = props.teamUser || {};
  const { team } = props || {};
  const dispatch = useDispatch();

  const initialValues = {
    email,
    access,
    name,
  };

  const validationSchema = Yup.object({
    email: Yup.string().matches(EMAIL_REGEX, 'Invalid email').required(),
    name: Yup.string().trim().required('Name is required.'),
    access: Yup.string().required(),
  });

  const handleSubmit = async (values) => {
    try {
      const teamUser = {
        email: values.email,
        access: values.access,
        name: values.name,
      };

      const isTeamUser = props.teamUsers?.some(
        (data) => data?.user?.email === values.email,
      );
      const isPendingUser = props.pendingUsers?.some(
        (user) => user?.email === values.email,
      );

      if (isTeamUser) {
        renderError(`The user ${values.email} is already a member.`);
        return;
      } else if (isPendingUser) {
        renderError(`The user ${values.email} already has a pending invite.`);
        return;
      }

      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Adding',
          description: 'Please wait...',
        }),
      );

      if (access) {
        const res = await updateTeamUserAccess(team.id, teamUser);
        renderSuccess(res?.data?.message);
      } else {
        const res = await addTeamUser(team.id, teamUser);
        renderSuccess(res?.data?.message);
      }

      dispatch(setLoader({ isLoading: false }));
      props.fetchTeamDetail();
      props.onHide();
    } catch (error) {
      dispatch(setLoader({ isLoading: false }));
      renderError(error);
    }
  };

  return (
    <Modal
      {...props}
      size={600}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="border-0" closeButton>
        <Modal.Title
          className={`${sc.fontsPoppins} Modal-Title `}
          id="contained-modal-title-vcenter"
        >
          {access ? 'Update' : 'Add'} Team Member
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnMount={true}
          enableReinitialize={true}
        >
          {({ values, isValid, dirty, setFieldValue }) => {
            return (
              <>
                <div>
                  <FormikTextField
                    Label={'Name '}
                    name="name"
                    placeholder="Enter name"
                    classList="input input__Email"
                    disabled={!!(props.teamUser && editName)}
                  />
                  <ErrorMessage name="name">
                    {(msg) => <span className="error">{msg}</span>}
                  </ErrorMessage>
                </div>
                <section
                  className={sc.InviteInputSection + ' mt-2 email-w-dropdown'}
                >
                  <label className="Input_label">Email</label>
                  <InputGroup className={sc.GroupedInputs}>
                    <InputGroup.Text
                      className={`${sc.Input__Icon} icon-wrapper`}
                    >
                      <FiMail size={18} color="#667085" />
                    </InputGroup.Text>

                    <FormikTextField
                      name="email"
                      type="email"
                      placeholder="Enter email for invite"
                      inputWrapperClass="input input__Email"
                      disabled={!!access}
                    />
                    <Select
                      isSearchable={false}
                      className={'Custom-Select'}
                      classNamePrefix={'Custom-Select'}
                      styles={Styles}
                      options={options}
                      placeholder="Select Permission"
                      onChange={({ value }) => {
                        setFieldValue('access', value);
                      }}
                      defaultValue={options.find(
                        (option) => option.value === access,
                      )}
                    />
                  </InputGroup>
                </section>
                <Modal.Footer
                  className={'border-0 px-0 pb-2 pt-4 ' + sc.ProfileModalFooter}
                >
                  <BSButton
                    onClick={props.onHide}
                    ButtonText="Cancel"
                    variant="light"
                    classList={'btn button cancel_btn'}
                  />
                  <BSButton
                    onClick={() => handleSubmit(values)}
                    ButtonText={access ? 'Update' : 'Add'}
                    disabled={!isValid || !dirty}
                    variant="dark"
                    classList={'btn button secondary_btn'}
                  />
                </Modal.Footer>
              </>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
}

export default AddMemberModal;
