import React, { useEffect } from 'react';
import { Formik, Form as FormikForm } from 'formik';
import Styles from './AIPoweredBrief.module.scss';
import { foundationSchema } from 'schemas/briefSchemas';
import BSButton from 'Components/Button/BSButton';
import { FiArrowRight } from 'react-icons/fi';
import FoundationFields from './FoundationFields';
import KeywordResearch from './KeywordResearch';
import HeadlineIdeas from './HeadlineIdeas';
import BriefForm from '../BriefForm';
import Spinner from 'Components/Spinner/Spinner';
import useAiPoweredBrief from 'hooks/useAiPoweredBrief';
import { ProgressBar } from 'react-bootstrap';

const AIPoweredBrief = ({
  initialValues,
  priorityOptions,
  userOptions,
  stateOptions,
  isProjectView,
  teamOptions,
  setSelectedProject,
  documentOptions,
  onSubmit,
  onHide,
  setSearchDocs,
  action,
}) => {
  const {
    nextStep,
    previousStep,
    setupStep,
    formFields,
    handleFormFieldsChange,
    fetchTags,
    tags,
    setTags,
    selectedTags,
    setSelectedTags,
    memoizedSelectedTags,
    fetchHeadlines,
    headlines,
    getResearchData,
    researchInfo,
    generateBrief,
    aiBrief,
    setResearchInfo,
    isFetchingKeywords,
    isAnalyzingKeywords,
    setIsAnalyzingKeywords,
    isFetchingHeadlines,
    isGeneratingAiBrief,
  } = useAiPoweredBrief(initialValues);

  useEffect(() => {
    setSelectedTags(tags.filter((tag) => tag.selected));
  }, [tags]);

  useEffect(() => {
    fetchTags();
  }, [formFields.content_type, formFields.topic, formFields.goals]);

  useEffect(() => {
    if (memoizedSelectedTags.length > 0) {
      getResearchData();
    } else {
      setIsAnalyzingKeywords(false);
      setResearchInfo(null);
    }
  }, [memoizedSelectedTags]);

  return (
    <>
      {setupStep < 2 && (
        <Formik
          onSubmit={generateBrief}
          initialValues={formFields}
          validationSchema={foundationSchema}
          validateOnMount={true}
        >
          {({ values, isValid, dirty, setFieldValue }) => {
            const { content_type, goals } = values || {};
            return (
              <FormikForm className={Styles.Container}>
                {setupStep === 0 && (
                  <>
                    <FoundationFields
                      content_type={content_type}
                      goals={goals}
                      handleFormFieldsChange={handleFormFieldsChange}
                      setFieldValue={setFieldValue}
                    />
                    {formFields.content_type &&
                      formFields.topic &&
                      formFields.goals && (
                        <KeywordResearch
                          tags={tags}
                          selectedTags={selectedTags}
                          setTags={setTags}
                          researchInfo={researchInfo}
                          isFetchingKeywords={isFetchingKeywords}
                          isAnalyzingKeywords={isAnalyzingKeywords}
                        />
                      )}

                    <div className="d-flex justify-content-end drawer-footer">
                      <BSButton
                        classList={`button secondary_btn ${Styles.Container_NextButton}`}
                        variant="light"
                        ButtonText="Continue"
                        onClick={() => {
                          nextStep();
                          fetchHeadlines();
                        }}
                        disabled={!(selectedTags?.length > 0)}
                      />
                    </div>
                  </>
                )}
                {setupStep === 1 && (
                  <HeadlineIdeas
                    formFields={formFields}
                    handleFormFieldsChange={handleFormFieldsChange}
                    headlines={headlines}
                    setFieldValue={setFieldValue}
                    previousStep={previousStep}
                    isFetchingHeadlines={isFetchingHeadlines}
                  />
                )}
              </FormikForm>
            );
          }}
        </Formik>
      )}
      {action === 'create' && (
        <ProgressBar now={66} className="drawer-progress-bar" />
      )}
      {setupStep === 2 &&
        (isGeneratingAiBrief ? (
          <Spinner
            className={Styles.Container_KeywordsContainer_KeywordsSpinner}
            text="Generating Brief..."
          />
        ) : (
          <BriefForm
            initialValues={aiBrief}
            priorityOptions={priorityOptions}
            userOptions={userOptions}
            stateOptions={stateOptions}
            teamOptions={teamOptions}
            documentOptions={documentOptions}
            isProjectView={isProjectView}
            setSelectedProject={setSelectedProject}
            buttonTitle={'Add to Plan'}
            action={action}
            onSubmit={onSubmit}
            onHide={onHide}
            setSearchDocs={setSearchDocs}
          />
        ))}
    </>
  );
};

export default AIPoweredBrief;
