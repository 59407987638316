import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FiUserPlus } from 'react-icons/fi';
import * as Yup from 'yup';
import Styles from '../TableBody/TableBody.module.scss';
import { getUserImage, humanize, areEqualConsideringEmpty } from 'helpers';
import ImageView from '../Image/ImageView';
import Badge from 'Components/Badge/Badge';
import KanbanDropDown from 'Components/KanbanCard/KanbanDropDown';
import NewDocumentModal from 'Components/Modals/NewDocumentModal/NewDocumentModal';
import { ROUTES } from 'constants/routes';
import { LuPencil } from 'react-icons/lu';
import BSButton from 'Components/Button/BSButton';
import { DropdownButton } from 'react-bootstrap';
import Dropdown from 'Components/Dropdown/Dropdown';
import {
  CONTENT_TYPE_OPTIONS,
  CONTENT_TYPE_ICONS,
} from 'constants/contentTypes';
import { Form, Formik } from 'formik';
import { updateTask } from 'services/taskService';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import { getAllMembers } from 'services/teamService';
import { KANBAN_STATES } from 'constants';
import FormikDateField from 'Components/Form/FormikDateField';
import moment from 'moment';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';
import { useSelector } from 'react-redux';

const ContentPlannerTable = ({
  fetchCPTasks,
  tasks,
  onEdit,
  onDelete,
  onMove,
  onArchive,
  onUpdateBrief,
  selectedColumns,
  calendarTable,
  handleAICreate,
}) => {
  const [newDocumentModalView, setNewDocumentModalView] = useState(false);
  const [collaborators, setCollaborators] = useState(null);
  const [modalInfo, setModalInfo] = useState({
    taskId: null,
    taskName: null,
    projectId: null,
  });
  const [openDropdownId, setOpenDropdownId] = useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { user } = useSelector((state) => state.auth);
  const { id: userId, subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  const fetchAllMembers = useCallback(async () => {
    try {
      const response = await getAllMembers();
      setCollaborators(
        response.data.map((user) => ({
          value: user.id,
          label: (
            <div className="d-flex align-items-center gap-3">
              {user.mini_avatar ? (
                <ImageView
                  src={user.mini_avatar}
                  class="rounded-profile-x-sm"
                />
              ) : (
                getUserImage(
                  user.mini_avatar,
                  user.name,
                  { width: 32, height: 32 },
                  'sm',
                )
              )}
              <p>{user.name}</p>
            </div>
          ),
        })),
      );
    } catch (e) {
      renderError(e);
      setCollaborators([]);
    }
  });
  useEffect(() => {
    if (tasks.length > 0 && !collaborators) fetchAllMembers();
  }, [tasks]);

  // useEffect(() => {
  //   if (contentGenerated) {
  //     handleContentGenerated();
  //   }
  // }, [contentGenerated]);

  const handleClick = (isDocument, id, templateId, projectId) => {
    if (isDocument) {
      if (templateId) {
        return ROUTES.EditorDocumentTemplate(id, templateId);
      } else {
        return ROUTES.FreestyleDocumentTemplate(id);
      }
    } else {
      return ROUTES.Project.Folder(projectId, id);
    }
  };

  const onHideModal = (refetchTasks = false) => {
    refetchTasks && fetchCPTasks && fetchCPTasks();
    setNewDocumentModalView(false);
  };

  const COLUMNS_RENDERER = {
    deadline: (task) => {
      const { deadline_date, id } = task;
      const formattedDate = moment(deadline_date).format('MM/DD/YYYY');

      return (
        <>
          <span>{deadline_date ? formattedDate : 'Not Specified'}</span>
          <EditDeadline
            id={id}
            initialValues={{ deadline_date }}
            onUpdateBrief={onUpdateBrief}
            isOpen={openDropdownId === `deadline-${id}`}
            toggleOpen={() =>
              setOpenDropdownId(
                openDropdownId === `deadline-${id}` ? null : `deadline-${id}`,
              )
            }
            currentSubscription={currentSubscription}
            task={task}
          />
        </>
      );
    },
    assignedTo: (task) => {
      const { id, assignee } = task;
      return (
        <>
          <div className={'d-flex align-items-center gap-table-data'}>
            {!assignee?.name ? (
              <div className="rounded-profile-x-sm">
                <FiUserPlus size={16} />
              </div>
            ) : assignee?.mini_avatar ? (
              <ImageView
                class="rounded-profile-x-sm"
                src={assignee?.mini_avatar}
              />
            ) : (
              getUserImage(
                assignee?.mini_avatar,
                assignee?.name,
                {
                  width: 32,
                  height: 32,
                },
                'sm',
              )
            )}
            <p className="name name-dark">{assignee?.name ?? 'Unassigned'}</p>
          </div>
          {currentSubscription?.plan_name === 'Basic Plan' &&
          task?.project_id !== null ? (
            <></>
          ) : (
            <UpdateDropdown
              id={id}
              options={collaborators}
              name={'assignee_id'}
              label={'Assign To'}
              placeholder={'Assign to'}
              initialValues={{ assignee_id: assignee?.id }}
              isClearable={true}
              onUpdateBrief={onUpdateBrief}
              isOpen={openDropdownId === `assignee-${id}`}
              toggleOpen={() =>
                setOpenDropdownId(
                  openDropdownId === `assignee-${id}` ? null : `assignee-${id}`,
                )
              }
            />
          )}
        </>
      );
    },
    status: (task) => {
      const { id, state } = task;
      return (
        <>
          <span style={calendarTable && { textWrap: 'nowrap' }}>
            <Badge status={state} />
          </span>{' '}
          {/* {currentSubscription?.plan_name === 'Basic Plan' &&
          task?.project_id !== null ? (
            <></>
          ) : ( */}
          <UpdateDropdown
            id={id}
            options={KANBAN_STATES.map((type) => {
              return {
                value: type,
                label: type
                  .replace(/_/g, ' ')
                  .replace(/\b\w/g, (l) => l.toUpperCase()),
              };
            })}
            name={'state'}
            label={'Status'}
            placeholder={'Select status'}
            initialValues={{ state }}
            onUpdateBrief={onUpdateBrief}
            isRequired={true}
            isOpen={openDropdownId === `status-${id}`}
            toggleOpen={() =>
              setOpenDropdownId(
                openDropdownId === `status-${id}` ? null : `status-${id}`,
              )
            }
          />
          {/* )} */}
        </>
      );
    },
    contentType: (task) => {
      const { id, content_type, other_content_type } = task;
      const icon =
        content_type === 'other'
          ? CONTENT_TYPE_ICONS.other
          : CONTENT_TYPE_ICONS[content_type];

      return (
        <>
          {' '}
          <div className="d-flex align-items-center content-type-container">
            {icon}
            <span style={calendarTable && { textWrap: 'nowrap' }}>
              {content_type === 'other'
                ? humanize(other_content_type)
                : humanize(content_type || '')}
            </span>
          </div>
          {currentSubscription?.plan_name === 'Basic Plan' &&
          task?.project_id !== null ? (
            <></>
          ) : (
            <UpdateDropdown
              id={id}
              options={CONTENT_TYPE_OPTIONS}
              name={'content_type'}
              label={'Content Type'}
              placeholder={'Select Content Type'}
              initialValues={{ content_type }}
              onUpdateBrief={onUpdateBrief}
              isRequired={true}
              isOpen={openDropdownId === `content_type-${id}`}
              toggleOpen={() =>
                setOpenDropdownId(
                  openDropdownId === `content_type-${id}`
                    ? null
                    : `content_type-${id}`,
                )
              }
            />
          )}
        </>
      );
    },
  };
  return (
    <>
      <tbody>
        {(tasks ?? [])?.map((task) => {
          const { id, title, state, project_access, document, project_id } =
            task;

          return (
            <>
              <tr key={id} className={Styles.TableRow}>
                <td
                  className={'text-sm-normal ' + Styles.TableData_ListTitle}
                  // onClick={() => onEdit(id)}
                  onClick={
                    currentSubscription?.plan_name === 'Basic Plan' &&
                    project_id !== null
                      ? () => setShowUpgradeModal(true)
                      : () => onEdit(id)
                  }
                >
                  <span
                    className={`d-flex justify-content-between align-items-center gap-1 ${Styles.TableData_Hidden_icon}`}
                  >
                    <span
                      className={`${Styles.TableData_KanbanTitle} ${Styles.TableData_first_column}`}
                    >
                      {title}
                    </span>{' '}
                    <span className={Styles.TableData_Title__edit_btn}>
                      View
                    </span>
                  </span>
                </td>

                {selectedColumns
                  .filter((c) => c[1])
                  .map((c) => c[0])
                  .map(
                    (column) =>
                      COLUMNS_RENDERER[column] && (
                        <td
                          key={`${id}-${column}`}
                          className={'text-sm-normal ' + Styles.TableData}
                        >
                          <span
                            className={`d-flex justify-content-between align-items-center ${Styles.TableData_Hidden_icon} gap-1`}
                          >
                            {/* <img src={youtube} alt="youtube" /> */}
                            {COLUMNS_RENDERER[column](task)}
                            {/* {COLUMNS_RENDERER[column](task, openDropdownId, setOpenDropdownId)} */}
                          </span>
                        </td>
                      ),
                  )}

                <td
                  className={
                    'text-sm-normal overflow-visible ' + Styles.TableData
                  }
                >
                  <div className="d-flex align-items-center justify-content-end gap-4">
                    <Link
                      className={`text-sm-normal  ${Styles.TableData_Link} `}
                      onClick={
                        () =>
                          currentSubscription?.plan_name === 'Basic Plan' &&
                          project_id !== null
                            ? setShowUpgradeModal(true)
                            : !document?.id &&
                              handleAICreate(id, project_id, title)

                        // (currentSubscription?.plan_name === "Basic Plan" && location.pathname.startsWith('/projects')) ?  setShowUpgradeModal(true) : !document?.id && handleAICreate(id, project_id, title)
                      }
                      to={
                        document?.id &&
                        handleClick(
                          document?.id,
                          document?.id,
                          document?.template_id,
                          project_id,
                        )
                      }
                    >
                      {document?.id ? 'Open Draft' : 'Create Draft'}
                    </Link>
                    <KanbanDropDown
                      id={id}
                      onDelete={onDelete}
                      onEdit={
                        currentSubscription?.plan_name === 'Basic Plan' &&
                        project_id !== null
                          ? () => setShowUpgradeModal(true)
                          : onEdit
                      }
                      onMove={onMove}
                      access={project_access}
                      onArchive={onArchive}
                      state={state}
                    />
                  </div>
                </td>
              </tr>
            </>
          );
        })}
      </tbody>
      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
          message={
            'Updating campaign related content is a pro feature. Please update to pro plan to access this feature.'
          }
        />
      )}

      {newDocumentModalView && (
        <NewDocumentModal
          show={newDocumentModalView}
          onHide={onHideModal}
          aiPowered
          {...modalInfo}
        />
      )}
    </>
  );
};

const UpdateDropdown = ({
  id,
  initialValues,
  onUpdateBrief,
  options,
  name,
  label,
  placeholder,
  isClearable = false,
  isRequired = false,
  isOpen,
  toggleOpen,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // const toggleDropdown = () => {
  //   setDropdownOpen(!dropdownOpen);
  // };

  const handleSubmit = async (values) => {
    try {
      const resp = await updateTask(id, { task: values });
      renderSuccess('Brief Updated!');
      onUpdateBrief(id, resp.data.task);
    } catch (e) {
      renderError('Cannot update brief!');
    }
  };
  return (
    <DropdownButton
      title={
        <span className={Styles.TableData_Title__edit_icon}>
          <LuPencil onClick={toggleOpen} />
        </span>
      }
      className={Styles.drop_btn_new}
      // show={dropdownOpen}
      show={isOpen}
    >
      <div className={Styles.outer_container}>
        <Formik
          initialValues={initialValues}
          validateOnMount
          enableReinitialize
        >
          {({ values, isValid, dirty, setFieldValue }) => (
            <Form className="w-100">
              <div>
                <Dropdown
                  name={name}
                  label={label}
                  placeholder={placeholder}
                  options={options}
                  isRequired={isRequired}
                  defaultValue={options.find(
                    (option) => option.value === initialValues[name],
                  )}
                  onChange={(option) =>
                    setFieldValue([name], option?.value ?? '')
                  }
                  isClearable={isClearable}
                  iconWithOption={true}
                />
              </div>
              <div className="d-flex justify-content-end align-items-center gap-2 mt-4 w-100">
                <BSButton
                  variant="light"
                  ButtonText={'Cancel'}
                  onClick={toggleOpen}
                  classList={'btn button white_btn'}
                />
                <BSButton
                  variant=""
                  ButtonText={'Update'}
                  classList={'btn button secondary_btn'}
                  disabled={
                    !(
                      isValid &&
                      dirty &&
                      !areEqualConsideringEmpty(
                        values[name],
                        initialValues[name],
                      )
                    )
                  }
                  onClick={() => {
                    handleSubmit(values);
                    // toggleDropdown();
                    toggleOpen();
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </DropdownButton>
  );
};

const EditDeadline = ({
  id,
  initialValues,
  onUpdateBrief,
  isOpen,
  toggleOpen,
  currentSubscription,
  task,
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const mappedValues = {
    deadline_date: initialValues?.deadline_date
      ? moment.utc(initialValues.deadline_date, 'MMMM D, YYYY').toDate()
      : '',
  };

  // const toggleDropdown = () => {
  //   setDropdownOpen(!dropdownOpen);
  // };

  const validationSchema = Yup.object().shape({
    deadline_date: Yup.date()
      .nullable(true)
      .test('is-valid-date', 'Invalid date format', (value) =>
        moment(value, 'YYYY-MM-DD', true).isValid(),
      ),
  });
  const handleSubmit = async (values) => {
    try {
      const resp = await updateTask(id, { task: values });
      renderSuccess('Brief Updated!');
      onUpdateBrief(id, resp.data.task);
    } catch (e) {
      renderError('Cannot update brief!');
    }
  };

  return (
    // currentSubscription?.plan_name === 'Basic Plan' && task?.project_id !== null ? (
    //   <></>
    // ) : (
    <DropdownButton
      title={
        <span className={Styles.TableData_Title__edit_icon}>
          <LuPencil onClick={toggleOpen} />
        </span>
      }
      className={Styles.drop_btn_new}
      show={isOpen}
    >
      <div className={Styles.outer_container}>
        <Formik
          initialValues={mappedValues}
          validationSchema={validationSchema}
          validateOnMount
          validateOnChange
          enableReinitialize
        >
          {({ values, isValid, dirty }) => (
            <Form className="w-100">
              <div>
                <FormikDateField name="deadline_date" label="Draft Due" />
              </div>
              <div className="d-flex justify-content-end align-items-center gap-2 mt-4 w-100">
                <BSButton
                  variant="light"
                  ButtonText={'Cancel'}
                  onClick={toggleOpen}
                  classList={'btn button white_btn'}
                />
                <BSButton
                  variant=""
                  ButtonText={'Update'}
                  classList={'btn button secondary_btn'}
                  disabled={!(isValid && dirty)}
                  onClick={() => {
                    handleSubmit(values);
                    toggleOpen();
                  }}
                />
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </DropdownButton>
  );
  // );
};

export default ContentPlannerTable;
