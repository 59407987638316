import React from 'react';
import Styles from './UrlForm.module.scss';
import { ErrorMessage, Formik } from 'formik';
import { Form } from 'react-bootstrap';
import FormikTextField from 'Components/Form/FormikTextField';
import BSButton from 'Components/Button/BSButton';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';

const TextForm = ({
  noButtons,
  setDescription,
  setBrandSummary,
  setActiveTab,
  setCancelClicked,
}) => {
  const { user } = useSelector((state) => state.auth);
  const brandCount = user?.user_brands?.brands_count;

  const getValidationSchema = () => {
    return Yup.object().shape({
      description: Yup.string().trim().required('Description is required'),
    });
  };

  const handleSubmit = (values) => {
    setDescription(values.description);
    setBrandSummary(true);
  };

  const handleShowBrands = () => {
    setCancelClicked(true);
    setBrandSummary(true);
  };

  return (
    <>
      <Formik
        initialValues={{ description: '' }}
        validationSchema={getValidationSchema()}
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, isValid, dirty, submitForm }) => {
          const handleContinueDescription = () => {
            submitForm();
          };

          return (
            <div className={Styles.form_container}>
              <Form
                className={Styles.Container}
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <div>
                  <FormikTextField
                    id="description"
                    name="description"
                    type="string"
                    placeholder="Enter your text here..."
                    Label="Tell us a little bit about your brand, your audience, and topics of interest."
                    classList="input form-control text-area-height"
                    isRequired
                    isTextArea={true}
                  />
                  <ErrorMessage
                    name="description"
                    component="div"
                    className="error mt-2"
                  />
                </div>
              </Form>
              {!noButtons && (
                <div className={`d-flex justify-content-end gap-2 mt-4`}>
                  <BSButton
                    classList={`cancel_btn`}
                    variant=""
                    ButtonText="Cancel"
                    onClick={() => setActiveTab(null)}
                  />
                  {/* {brandCount > 0 && (
                    <BSButton
                      classList={`cancel_btn`}
                      variant=""
                      ButtonText="My Brands"
                      onClick={handleShowBrands}
                    />
                  )} */}
                  <BSButton
                    classList={`secondary_btn`}
                    variant=""
                    ButtonText="Continue"
                    onClick={handleContinueDescription}
                  />
                </div>
              )}
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default TextForm;
