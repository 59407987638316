import React, { useMemo, useState } from 'react';
import CompaignCard from 'Components/CompaignCard/CompaignCard';
import { deleteProject } from 'services/projectService';
import ProjectTile from 'Components/Projects/ProjectTile/ProjectTile';
import SectionHeader from './SectionHeader';
import { ROUTES } from 'constants/routes';
import { FiLayers } from 'react-icons/fi';
import Styles from './Dashboard.module.scss';
import CompaignCardSkeleton from 'Components/CompaignCard/CompaignCardSkeleton';
import EmptyDashboardState from './EmptyDashboardState';
import useProjects from 'hooks/projects/useProjects';
import ProjectDeleteModal from 'Components/Projects//ProjectDeleteModal';
import ProjectDrawer from 'Components/Projects/ProjectDrawer';
import { success as successToast, error as errorToast } from 'helpers/toaster';
import { useSelector } from 'react-redux';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';

const options = [
  { label: 'All Campaigns', value: 'all' },
  { label: 'My Campaigns', value: 'individual' },
  { label: 'Shared with Me', value: 'shared' },
];

const ActiveCampaigns = ({ openProjectDrawer }) => {
  const [campaignsType, setCampaignsType] = useState('all');
  const [action, setAction] = useState('create');
  const [project, setProject] = useState(null);
  const [projectIdToRemove, setProjectIdToRemove] = useState('');
  const [showProjectDrawer, setShowProjectDrawer] = useState(false);
  const [showProjectDeleteModal, setShowProjectDeleteModal] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { id: userId, subscriptions } = user || {};

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  const {
    data: projectPages,
    isLoading: isProjectsLoading,
    refetch,
  } = useProjects({
    searchTerm: '',
    type: campaignsType,
  });
  const { projects, projectsCount } = useMemo(() => {
    const projects = projectPages?.pages.flatMap(
      (page) => page?.data?.projects,
    );
    const projectsCount = projectPages?.pages[0]?.data?.meta_data?.count || 0;
    return { projects, projectsCount };
  }, [projectPages?.pages]);

  const handleProjectEdit = (project) => {
    setAction('update');
    setProject(project);
    setShowProjectDrawer(true);
  };

  const handleProjectDeletion = async () => {
    try {
      await deleteProject(projectIdToRemove);
      setProjectIdToRemove('');
      setShowProjectDeleteModal(false);

      // This can be removed after useDeleteProject hook
      refetch();
      successToast('Campaign Deleted Successfully');
    } catch (error) {
      errorToast(error);
    }
  };

  const handleProjectDelete = (projectId) => {
    setProjectIdToRemove(projectId);
    setShowProjectDeleteModal(true);
  };

  return (
    <>
      <div>
        <SectionHeader
          icon={<FiLayers size={20} color="#00A7B7" />}
          name="Active Campaigns"
          route={ROUTES.Projects}
          count={projectsCount}
          options={options}
          selectedValue={campaignsType}
          setSelectedValue={setCampaignsType}
          hideLink={projectsCount < 1}
        />
      </div>
      {isProjectsLoading ? (
        <div className={Styles.Dashboard__compaigns}>
          <CompaignCardSkeleton count={4} />
        </div>
      ) : projects && projects.length > 0 ? (
        <div className={Styles.Dashboard__compaigns}>
          {projects.slice(0, 4).map((project, index) => {
            // return <CompaignCard key={index} project={project} />;
            return (
              <ProjectTile
                key={project.id}
                project={project}
                userId={userId}
                handleEdit={
                  currentSubscription?.plan_name === 'Basic Plan'
                    ? () => setShowUpgradeModal(true)
                    : () => handleProjectEdit(project)
                }
                handleDelete={() => handleProjectDelete(project.id)}
              />
            );
          })}
        </div>
      ) : (
        <EmptyDashboardState
          // title="No Campaign Yet?"
          description="Generate a batch of content ideas and drafts related to one specific goal or milestone (i.e product launch , or course promotion..)"
          buttonText="Start a New Campaign"
          backgroundImageUrl="/Images/DashboardEmptyProjectsState.svg"
          onClick={() => {
            openProjectDrawer();
          }}
        />
      )}
      {showProjectDrawer && (
        <ProjectDrawer
          show={showProjectDrawer}
          onHide={async () => {
            // This can be removed after useCreateProject hook
            refetch();
            setProject(null);
            setShowProjectDrawer(false);
          }}
          action={action}
          project={project}
        />
      )}

      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
        />
      )}

      {showProjectDeleteModal && (
        <ProjectDeleteModal
          show={showProjectDeleteModal}
          onHide={() => {
            setProjectIdToRemove('');
            setShowProjectDeleteModal(false);
          }}
          onDelete={handleProjectDeletion}
        />
      )}
    </>
  );
};

export default ActiveCampaigns;
