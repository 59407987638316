import { createSlice } from '@reduxjs/toolkit';
import BusinessSummary from 'Components/Pages/Onboarding/Forms/BusinessSummary';

const initialState = {
  currentStep: 0,
  step1: {},
  businessSummary: {},
  step2: {},
  // step3: {
  //     topics: ["Using AI to Craft the Perfect Content Strategy", "The Vocable Way: a Super AI automating Content Ops", "How We Are Redefining Software Interaction with LLMs", "AI and Customer Service: Enhancing the Customer Experience", "Mastering Personalization: How AI Transforms Customer Experience"]
  // },
  step3: {
    topics: [],
  },
  step4: {},
};

const newOnboardingSlice = createSlice({
  name: 'newOnboarding',
  initialState,
  reducers: {
    setCurrentStep: (state, action) => {
      state.currentStep = action.payload;
    },
    nextStep: (state) => {
      if (state.currentStep < 4) {
        state.currentStep += 1;
      }
    },
    prevStep: (state) => {
      if (state.currentStep > 0) {
        state.currentStep -= 1;
      }
    },
    updateStep1: (state, action) => {
      return {
        ...state,
        step1: action.payload,
      };
    },
    updateBusinessSummary: (state, action) => {
      return {
        ...state,
        businessSummary: action.payload,
      };
    },
    updateStep2: (state, action) => {
      return {
        ...state,
        step2: action.payload,
      };
    },
    updateStep3: (state, action) => {
      return {
        ...state,
        step3: action.payload,
      };
    },
    updateStep4: (state, action) => {
      return {
        ...state,
        step4: action.payload,
      };
    },
    resetOnboarding: () => initialState,
    clearStateKey: (state, action) => {
      const key = action.payload;
      if (state.hasOwnProperty(key)) {
        state[key] = initialState[key];
      }
    },
  },
});

// const { reducer, actions } = onboardingSlice;
// export { actions };
// export default reducer;

export const {
  setCurrentStep,
  updateStep1,
  updateStep2,
  updateStep3,
  updateStep4,
  nextStep,
  prevStep,
  updateBusinessSummary,
  clearStateKey,
  resetOnboarding,
} = newOnboardingSlice.actions;
export default newOnboardingSlice.reducer;
