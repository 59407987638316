import { BiChevronsDown, BiChevronsUp } from 'react-icons/bi';
import { FaEquals } from 'react-icons/fa6';
import React from 'react';
import { ReactComponent as High } from '../../src/assets/svg/high.svg';
import { ReactComponent as Low } from '../../src/assets/svg/low.svg';
import { ReactComponent as Medium } from '../../src/assets/svg/medium.svg';

const mapPriorityIcon = {
  high: <High />,
  medium: <Medium />,
  low: <Low />,
};

export { mapPriorityIcon };
