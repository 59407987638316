import React, { useState } from 'react';
import Styles from './RecentDocumentCard.module.scss';
import { AvatarGroup } from 'Components/AvatarGroup/AvatarGroup';
import { CiCalendar } from 'react-icons/ci';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'constants/routes';
import OptionsDropDown from 'Components/OptionsDropDown/OptionsDropDown';
import Star from 'Components/Star/Star';
import useDeleteStarredDocument from 'hooks/documents/useDeleteStarredDocument';
import useStarDocument from 'hooks/documents/useStarDocument';
import ImageView from 'Components/Image/ImageView';
import { CONTENT_TYPE_ICONS } from 'constants/contentTypes';
import { getNameInitials } from 'helpers';

const RecentDocumentCard = (document) => {
  const { document: documentDetails, refetch, isDashboardView } = document;
  const {
    id,
    template_id: templateId,
    user,
    status,
    title,
    created_at: createdAt,
    starred,
    task,
  } = documentDetails;
  const date = new Date(createdAt);
  const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
  const collabs = [
    { name: user.name, avatar: user.mini_avatar },
    ...documentDetails.collaborators?.map((c) => ({
      avatar: c.avatar,
      name: c.name,
    })),
  ];

  const navigate = useNavigate();
  const [star, setStar] = useState(starred);
  // const [starDisplay, setStarDisplay] = useState(false);
  const [starDisplay, setStarDisplay] = useState(starred ? true : false);
  const [showOptions, setShowOptions] = useState(false);

  const { mutateAsync: addStar } = useStarDocument();
  const { mutateAsync: removeStar } = useDeleteStarredDocument();

  const handleDocument = (documentId, templateId) => {
    if (documentId) {
      const targetRoute = templateId
        ? ROUTES.EditorDocumentTemplate(documentId, templateId)
        : ROUTES.FreestyleDocumentTemplate(documentId);

      navigate(targetRoute);
    }
  };

  const handleStar = async () => {
    if (Object.keys(star || {}).length) {
      await removeStar(star.id, {
        onSuccess: () => {
          setStar(null);
          setStarDisplay(false);
        },
      });
    } else {
      await addStar(id, {
        onSuccess: (data) => {
          if (!data?.data?.starred_document) return;
          setStarDisplay(true);
          setStar(data?.data?.starred_document);
        },
      });
    }
    refetch(false);
  };

  return (
    <div
      className={Styles.card}
      onClick={() => {
        handleDocument(id, templateId);
      }}
      // onMouseEnter={() => setStarDisplay(true)}
      // onMouseLeave={() => {
      //   if (!star && setStarDisplay(false));
      // }}
      onMouseEnter={() => {
        setStarDisplay(true);
        setShowOptions(true);
      }}
      onMouseLeave={() => {
        if (!star) setStarDisplay(false);
        setShowOptions(false);
      }}
    >
      <div className={Styles.card__header}>
        <div>
          {task &&
            task.content_type &&
            (task.content_type === 'other'
              ? CONTENT_TYPE_ICONS.other
              : CONTENT_TYPE_ICONS[task.content_type])}
        </div>
        <div className="d-flex align-items-center gap-3">
          <div
            className={`${Styles.card__header__badge} ${status === 'in_progress' ? Styles.inprogress : status === 'not_started' ? Styles.not_started : status === 'in_review' ? Styles.in_review : status === 'approved' ? Styles.completed : status === 'published' ? Styles.published : ''}`}
          >
            {status.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase())}
          </div>
          <Star
            isStarred={star}
            shouldDisplay={starDisplay}
            position={starDisplay ? 'flex' : 'absolute'}
            shouldAlwaysDisplay={!!star}
            onClick={(e) => {
              e.stopPropagation();
              handleStar();
            }}
          />
        </div>
      </div>
      <p className={Styles.card_heading}>{title}</p>
      <div className={Styles.card__date}>
        <CiCalendar color="#667085" height={12} width={12} />
        <span>{formattedDate} </span>
      </div>
      <div className={Styles.card__footer}>
        {/* <div
          className={`${collabs?.length === 1 ? '' : Styles.card__footer__avatars}`}
        >
          <AvatarGroup avatars={collabs} size={'sm'} overflowAfter={5} />
        </div> */}
        <div
          className={Styles.TileInfo_AvatarGroup}
          style={collabs.length === 1 ? { background: 'transparent' } : {}}
        >
          <div className="d-flex gap-3 stacked-Images-rounded">
            <div>
              {collabs?.slice(0, 3)?.map(({ id, avatar, name: userName }) => {
                return avatar ? (
                  <ImageView
                    key={id}
                    src={avatar}
                    class="rounded-avatar-bordered"
                  />
                ) : (
                  <div key={id} className={Styles.rounded_profile}>
                    {getNameInitials(userName)}
                  </div>
                );
              })}

              {collabs.length > 3 && (
                <div className={Styles.rounded_profile}>
                  {`+${collabs.length - 3}`}
                </div>
              )}
            </div>

            {collabs.length === 1 && (
              <span className={`${Styles.collaborator_name}`}>
                {collabs[0].name}
              </span>
            )}
          </div>
        </div>
        {showOptions && (
          <span className={''} onClick={(e) => e.stopPropagation()}>
            <OptionsDropDown
              OptionsFor={'File'}
              drop="end"
              Icon={<BsThreeDotsVertical color="#101828" />}
              document={documentDetails}
              fetchFolders={refetch}
              isTrash={false}
              isDocument={true}
              chevronRight={true}
              myProjects={[]}
              isDashboardView={isDashboardView}
            />
          </span>
        )}
      </div>
    </div>
  );
};

export default RecentDocumentCard;
