import React from 'react';
import {
  FiCalendar,
  FiEdit3,
  FiPlus,
  FiTag,
  FiUserPlus,
  FiUser,
} from 'react-icons/fi';

import Styles from './ProjectPlanCard.module.scss';
import {
  formatDate,
  formatReadableDate,
  formatmdy,
} from '../../../helpers/dates';
import { titleize } from '../../../helpers';
import { mapPriorityIcon } from '../../../constants/statusPriority';

import { ReactComponent as Instagram } from '../../../../src/assets/svg/instagram.svg';
import { ReactComponent as Other } from '../../../../src/assets/svg/Group.svg';
import { ReactComponent as X } from '../../../../src/assets/svg/x.svg';
import { ReactComponent as Linkedin } from '../../../../src/assets/svg/linkedin.svg';
import { ReactComponent as Youtube } from '../../../../src/assets/svg/youtube.svg';
import { ReactComponent as Blog } from '../../../../src/assets/svg/blog.svg';
import { ReactComponent as CaseStudy } from '../../../../src/assets/svg/case-study.svg';
import { ReactComponent as Guide } from '../../../../src/assets/svg/Guide.svg';
import { ReactComponent as Quiz } from '../../../../src/assets/svg/quiz.svg';

const ContentIconRenderer = ({ brief }) => {
  const contentTypeIconMapper = {
    'youtube script': Youtube,
    'twitter thread': X,
    'instagram post': Instagram,
    'linkedin post': Linkedin,
    'case study': CaseStudy,
    quizz: Quiz,
    quiz: Quiz,
    'blog post': Blog,
    guide: Guide,
  };
  const Component =
    contentTypeIconMapper[brief.content_type.toLowerCase()] || Other;
  return <Component />;
};

const ProjectPlanCard = ({ brief, onClick, onboardingFooter }) => {
  return (
    <div
      className={onboardingFooter ? Styles.card_onboarding : Styles.card}
      onClick={onClick}
    >
      <div className={Styles.upperCard}>
        <div className={Styles.groups}>
          <p className="d-flex align-items-center gap-2">
            <ContentIconRenderer brief={brief} />
            <span className={Styles.content_type}>{brief.content_type}</span>
          </p>
          {onboardingFooter ? (
            ''
          ) : (
            <p
              className={`${Styles.priorityTag} ${brief.priority === 'medium' ? Styles.mediumPriority : brief.priority === 'high' ? Styles.highPriority : Styles.lowPriority}`}
            >
              {mapPriorityIcon[brief.priority]} {titleize(brief.priority)}
            </p>
          )}
        </div>
        <div>
          <div className="d-inline-flex">
            <b className={Styles.title}>{brief.title}</b>
            <div className={Styles.card_HoverIcon}>
              <FiEdit3 size={24} />
            </div>
          </div>
        </div>
        <div className={Styles.iconGroup}>
          <p>
            <FiCalendar /> {formatmdy(brief.deadline_date)}
          </p>
        </div>
      </div>
      <div className={Styles.card_divider}></div>
      {onboardingFooter ? (
        <div className={Styles.actionsGroup}>
          <p
            className={`d-flex align-items-center justify-content-center w-100 gap-1 ${Styles.creater_link}`}
          >
            <FiPlus height={'16px'} width={'16px'} /> <span>View Brief</span>
          </p>
        </div>
      ) : (
        <div className={Styles.actionsGroup}>
          <p className="d-flex align-items-center gap-2">
            <span className={Styles.User_icon}>
              <FiUser color="#00A7B7" size={16} />
            </span>{' '}
            <span className={Styles.assign_btn}>Assign to</span>
          </p>
          <p
            className={`d-flex align-items-center gap-1 ${Styles.creater_link}`}
          >
            <FiPlus height={'16px'} width={'16px'} />{' '}
            <span>Create a draft</span>
          </p>
        </div>
      )}
    </div>
  );
};

export default ProjectPlanCard;
