import React from 'react';
import sc from './Subscription.module.scss';
import { useNavigate } from 'react-router-dom';
import { SUBSCRIPTION } from 'constants';

const PlanTypeCard = ({ className, currentSubscription }) => {
  const {
    plan_name: planName,
    amount,
    stripe_price: stripePrice,
    services,
  } = currentSubscription || {};

  const { interval } = stripePrice || {};

  const navigate = useNavigate();

  return (
    <div className={sc.outer_container_plantype_card}>
      <div className={`${sc.planTypeCard} ${className}`}>
        <div className={sc.cardDetailsBox}>
          <div className={sc.billingCardHeadingWrapper}>
            <div className={sc.billingCardHeadingWrapper__heading}>
              <span className={sc.billingCardHeadingWrapper__heading__dropicon}>
                {/* <FaArrowDown /> */}
                <img src="/Images/downarrow.svg" alt="check" />
              </span>
              <div className="d-flex align-items-start flex-column">
                <p
                  className={`${sc.billingCardHeadingWrapper__heading__name} ${sc.nameFonts}`}
                >
                  {planName}
                </p>
                <p
                  className={
                    sc.billingCardHeadingWrapper__heading__subscription
                  }
                >
                  <span
                    className={
                      sc.billingCardHeadingWrapper__heading__subscription__bold
                    }
                  >
                    ${Number(stripePrice?.amount)}
                  </span>{' '}
                  <span>/{interval === 'month' ? 'Month' : 'Annually'}</span>
                </p>
              </div>
            </div>
            <div className={sc.manage_button_container}>
              <button
                className={`cancel_btn text-capitalize`}
                onClick={() => navigate(`/${SUBSCRIPTION}`)}
              >
                {interval === 'year' ? 'Change plan' : 'Manage plan'}
                {/* <img src="/Images/arrowLeft.svg" alt="left arrow" /> */}
              </button>
            </div>
          </div>
          <div className={sc.cardDetailsBox__bottom_container}>
            <p className={sc.cardDetailsBox__bottom_container__heading}>
              Services Included
            </p>
            <div
              className={sc.cardDetailsBox__bottom_container__list_container}
            >
              {services &&
                services.map((option, index) => (
                  <div
                    className={
                      sc.cardDetailsBox__bottom_container__list_container__list
                    }
                    key={index}
                  >
                    <img
                      className="mt-1"
                      src="/Images/tickicon.svg"
                      alt="checkIcon"
                    />
                    <span>{option}</span>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanTypeCard;
