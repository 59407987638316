import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import { FiLink } from 'react-icons/fi';

import { KANBAN_STATES } from 'constants';
import { getUserImage } from 'helpers';
import { mapTaskAttributes } from 'helpers/task';
import Drawer from 'Components/Drawer/Drawer';
import ImageView from 'Components/Image/ImageView';
import CreateBriefHeader from './CreateBriefHeader/CreateBriefHeader';
import AIPoweredBrief from './AIPoweredBrief/AIPoweredBrief';
import BriefForm from './BriefForm';
import useProjects from 'hooks/projects/useProjects';
import useProjectUsers from 'hooks/projects/useProjectUsers';
import useInfiniteDocuments from 'hooks/documents/useInfiniteDocuments';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';

const BriefDrawer = ({
  onSubmit,
  onDeleteHandler,
  action,
  task,
  isProjectView = false,
  isAIPowered = true,
  show,
  onHide,
  disableCreateDraft = false,
  regenerate = false,
  regenerateFn,
}) => {
  const [searchDocs, setSearchDocs] = useState('');
  const [selectedProject, setSelectedProject] = useState('');
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};
  const initialTabKey =
    action === 'edit'
      ? 'manual'
      : currentSubscription?.plan_name === 'Pro Plan'
        ? 'ai-powered'
        : 'manual';
  const [tabKey, setTabKey] = useState(initialTabKey);

  const { projectId } = useParams();

  const { data: projectPages } = useProjects({ searchTerm: '' });
  const { data: projectUsers } = useProjectUsers({
    projectId: projectId || selectedProject,
  });

  const projects = useMemo(() => {
    return projectPages?.pages.flatMap((page) => page?.data?.projects);
  }, [projectPages?.pages]);

  const { document_id: documentId } = task || {};

  const {
    data: documentsPage,
    isLoading: isLoadingDocuments,
    hasNextPage,
    fetchNextPage,
  } = useInfiniteDocuments({
    projectId: selectedProject || projectId || '',
    type: 'all',
    search: searchDocs,
  });

  const libraryDocuments = useMemo(() => {
    return documentsPage?.pages.flatMap((page) => page?.data?.documents);
  }, [documentsPage?.pages]);

  const documents = useMemo(
    () =>
      libraryDocuments?.filter(
        (document) => !document.task_associated || document.id === documentId,
      ),
    [libraryDocuments],
  );

  const users = useMemo(() => {
    if (projectId || selectedProject) {
      return (projectUsers?.data?.project_users || []).map(({ user }) => user);
    } else {
      return [user];
    }
  }, [projectId, selectedProject]);

  const userOptions = useMemo(() => {
    if (!users?.length) {
      return [];
    }

    return users.map((user) => {
      return {
        value: user.id,
        label: (
          <div className="d-flex align-items-center gap-3">
            {user.mini_avatar ? (
              <ImageView src={user.mini_avatar} class="rounded-profile-x-sm" />
            ) : (
              getUserImage(
                user.mini_avatar,
                user.name,
                { width: 32, height: 32 },
                'sm',
              )
            )}
            <p>{user.name}</p>
          </div>
        ),
      };
    });
  }, [users]);

  const documentOptions = useMemo(() => {
    if (isLoadingDocuments) {
      return [];
    }
    if (!documents?.length && hasNextPage) {
      fetchNextPage();
      return [];
    }

    return documents.map((document) => {
      return {
        value: document.id,
        label: (
          <div className="d-flex align-items-center gap-3">
            <FiLink size={20} color="#667085" />
            <p>{document.title}</p>
          </div>
        ),
      };
    });
  }, [documents, hasNextPage, isLoadingDocuments]);

  const teamOptions = useMemo(() => {
    if (!projects?.length) {
      return [];
    }

    return projects.map((project) => {
      return {
        value: project.id,
        label: (
          <div className="d-flex align-items-center gap-3">
            {project.mini_avatar ? (
              <ImageView
                src={project.mini_avatar}
                class="rounded-profile-x-sm"
              />
            ) : (
              getUserImage(
                project.mini_avatar,
                project.name,
                { width: 32, height: 32 },
                'sm',
              )
            )}
            <p>{project.name}</p>
          </div>
        ),
      };
    });
  }, [projects]);

  const priorityOptions = [
    { value: 'low', label: 'Low' },
    { value: 'medium', label: 'Medium' },
    { value: 'high', label: 'High' },
  ];

  const stateOptions = KANBAN_STATES.map((type) => {
    return {
      value: type,
      label: type.replace(/_/g, ' ').replace(/\b\w/g, (l) => l.toUpperCase()),
    };
  });

  const handleUpgrade = () => {
    // Implement upgrade functionality here
    // For example, navigate to the upgrade page
    navigate('/subscription');
  };

  useEffect(() => {
    setSelectedProject(task?.project_id || '');
  }, [task?.project_id]);

  const initialValues = useMemo(() => {
    return mapTaskAttributes(task, projectId);
  }, [task]);

  const modalTitle = action === 'create' ? 'Create New Brief' : 'Edit Brief';
  const buttonTitle = action === 'create' ? 'Add to Plan' : 'Update Brief';

  const handleTabSelect = (k) => {
    if (k === 'ai-powered' && currentSubscription?.plan_name === 'Basic Plan') {
      setShowUpgradeModal(true);
      setTabKey('manual'); // Prevent switching to 'ai-powered' tab
    } else {
      setTabKey(k);
    }
  };

  return (
    <Drawer
      show={show}
      onHide={onHide}
      title={modalTitle}
      placement="end"
      width={720}
    >
      <Tab.Container activeKey={tabKey} onSelect={handleTabSelect}>
        {action === 'create' && isAIPowered && <CreateBriefHeader />}
        <Tab.Content>
          <Tab.Pane eventKey="manual">
            <BriefForm
              initialValues={initialValues}
              priorityOptions={priorityOptions}
              userOptions={userOptions}
              stateOptions={stateOptions}
              teamOptions={teamOptions}
              documentOptions={documentOptions}
              isProjectView={isProjectView}
              setSelectedProject={setSelectedProject}
              buttonTitle={buttonTitle}
              action={action}
              onSubmit={onSubmit}
              onDeleteHandler={onDeleteHandler}
              taskId={task?.id}
              onHide={onHide}
              setSearchDocs={setSearchDocs}
              disableCreateDraft={disableCreateDraft}
              regenerate={regenerate}
              regenerateFn={regenerateFn}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="ai-powered">
            {currentSubscription?.plan_name === 'Pro Plan' && (
              <AIPoweredBrief
                initialValues={initialValues}
                priorityOptions={priorityOptions}
                userOptions={userOptions}
                stateOptions={stateOptions}
                isProjectView={isProjectView}
                teamOptions={teamOptions}
                setSelectedProject={setSelectedProject}
                documentOptions={documentOptions}
                buttonTitle={buttonTitle}
                onSubmit={onSubmit}
                onHide={onHide}
                setSearchDocs={setSearchDocs}
                action={action}
              />
            )}
            {currentSubscription?.plan_name === 'Basic Plan' && (
              <UpgradeModal
                show={showUpgradeModal}
                onHide={() => setShowUpgradeModal(false)}
              />
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </Drawer>
  );
};

export default BriefDrawer;
