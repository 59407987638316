import React from 'react';
import Styles from './Heading.module.scss';
const Heading = ({ title, description, classes }) => {
  return (
    <div className={Styles.heading} style={classes}>
      <p className={Styles.heading__title}>{title}</p>
      <p className={Styles.heading__desc}>{description}</p>
    </div>
  );
};
export default Heading;
