import React, { useEffect } from 'react';
import Publish from '../Publish';
import Settings from '../Settings';

const MergePublishSetting = ({
  publishSelectedIntegration,
  setPublishSelectIntegration,
  displaySettings,
  setDisplaySettings,
  checkIntegrations,
  handlePublishDoc,
  formSubmitRef,
  showUpgradeModal,
  setShowUpgradeModal,
}) => {
  // Determine if the Publish or Settings component should be rendered

  useEffect(() => {
    return () => {
      setPublishSelectIntegration({});
      // setDisplaySettings(false)
    };
  }, []);

  const shouldShowPublish = !checkIntegrations(publishSelectedIntegration);

  return shouldShowPublish ? (
    <Publish
      setPublishSelectIntegration={setPublishSelectIntegration}
      publishSelectedIntegration={publishSelectedIntegration}
      displaySettings={displaySettings}
      setDisplaySettings={setDisplaySettings}
      checkIntegrations={checkIntegrations}
      handlePublishDoc={handlePublishDoc}
    />
  ) : (
    <Settings
      setDisplaySettings={setDisplaySettings}
      handlePublishDoc={handlePublishDoc}
      setPublishSelectIntegration={setPublishSelectIntegration}
      publishSelectedIntegration={publishSelectedIntegration}
      formSubmitRef={formSubmitRef}
      showUpgradeModal={showUpgradeModal}
      setShowUpgradeModal={setShowUpgradeModal}
      // Pass additional props if required for Settings
    />
  );
};

export default MergePublishSetting;
