import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Tab from 'react-bootstrap/Tab';
import {
  FiChevronDown,
  FiFile,
  FiFolder,
  FiGrid,
  FiList,
  FiTag,
  FiTrash,
  FiUser,
} from 'react-icons/fi';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown, Nav, OverlayTrigger } from 'react-bootstrap';

import ImageView from 'Components/Image/ImageView';
import ProjectSelect from 'Containers/Content Planner/ProjectSelect';
import ConfirmEmptyBin from 'Containers/Modal/ConfirmEmptyBin/ConfirmEmptyBin';
import NewFolderModal from 'Containers/Modal/NewFolderModal/NewFolderModal';
import { capitalize, humanize } from 'helpers';
import { shareFolderIfNotAlready } from 'services/folderService';
import Styles from './Documents.module.scss';
import DocumentsGridView from './DocumentsGridView';
import DocumentsListView from './DocumentsListView';
import FloatingButton from 'ui/FloatingButton/FloatingButton';
import Popover from 'Components/Popover/Popover';
import BSButton from 'Components/Button/BSButton';
import NewDocumentModal from 'Components/Modals/NewDocumentModal/NewDocumentModal';
import EmptyState from 'Components/EmptyState/EmptyState';
import SearchInput from 'ui/SearchInput/SearchInput';
import PageHeader from 'Components/PageHeader/PageHeader';
import { ROUTES } from 'constants/routes';
import useFolderDocuments from 'hooks/folders/useFolderDocuments';
import Loader from 'Components/Loader/Loader';
import { StatusOptions } from 'Components/Status/StatusOption';
import useInfiniteDocuments from 'hooks/documents/useInfiniteDocuments';
import useFolders from 'hooks/folders/useFolders';
import { CiGrid2H } from 'react-icons/ci';
import { BsFilter, BsPlus } from 'react-icons/bs';
import { TbCalendarMinus } from 'react-icons/tb';
import { BiDisc } from 'react-icons/bi';
import Select from 'react-select';
import ContentFilter from 'Containers/Content Planner/ContentFilter';
import { RxCross2 } from 'react-icons/rx';
import LibraryDropdown from 'Components/MultiDropdown/LibraryDropdown';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';

const Documents = ({ projectView = true, projectType, noMargin }) => {
  const optionsClassName =
    'd-flex align-items-center select-option gap-2 ' + Styles.SelectLabel;
  const filterOptions = {
    filterBy: '',
    isOptions: '',
  };
  const isFilterOptions = [
    {
      value: '_in',
      label: <div className={optionsClassName}>is</div>,
    },
    {
      value: '_not_in',
      label: <div className={optionsClassName}>is not</div>,
    },
  ];
  const SelectStyles = {
    option: (styles, { isSelected, isFocused }) => {
      return {
        boxShadow: isFocused ? null : null,
        background: isSelected ? `url("./Images/blueTick.svg")` : '',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: '90% 50%',
        backgroundColor: isSelected ? '#F2FBFB!important' : '#fff',
        paddingRight: isSelected ? '0px' : '25px',
        fontSize: '14px',
        lineHeight: '24px',
        fontWeight: '500',
        color: '#344054',
        padding: '4px 10px',

        '&:hover': {
          backgroundColor: 'lightgrey',
          cursor: 'pointer',
        },
      };
    },

    control: (baseStyles) => {
      return {
        ...baseStyles,
        cursor: 'pointer',
      };
    },
  };
  const options = [
    {
      value: 'state',
      label: (
        <div className={optionsClassName}>
          <BiDisc size={14} color="#667085" /> <p>Status</p>
        </div>
      ),
    },
    {
      value: 'deadline_date',
      label: (
        <div className={optionsClassName}>
          <TbCalendarMinus size={14} color="#667085" /> <p>Deadline</p>
        </div>
      ),
    },
    {
      value: 'assignee_id',
      label: (
        <div className={optionsClassName}>
          <FiUser size={14} color="#667085" /> <p>Assigned to</p>
        </div>
      ),
    },
    {
      value: 'content_type',
      label: (
        <div
          className={
            'd-flex align-items-center select-option gap-2 ' +
            Styles.SelectLabel
          }
        >
          <FiTag size={14} color="#667085" /> <p>Content Type</p>
        </div>
      ),
    },
  ];
  const [NewDocumentModalView, setNewDocumentModalView] = useState(false);
  const [NewFolderModalView, setNewFolderModalView] = useState(false);
  const [showEmptyBinModal, setShowEmptyBinModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [status, setStatus] = useState('');
  const [project, setProject] = useState('');
  const [showPopover, setShowPopover] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [filters, setFilters] = useState([filterOptions]);
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const params = useParams();

  const { subscriptions, user_brands } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const { folderSlug, projectId } = params;
  const documentType = params.documentType || folderSlug || projectType;
  const isFolder = Boolean(folderSlug);
  const isTrash = documentType === 'trash';

  const {
    documents: folderDocuments,
    title: folderTitle,
    isLoading: isLoadingFolderDocuments,
    refetch: refetchFolderDocuments,
  } = useFolderDocuments({ folderSlug, enabled: isFolder });

  const requestProps = {
    type: documentType || 'all',
    search: searchTerm,
    projectId: projectId || project,
    enabled: !isFolder,
  };

  const {
    folders: libraryFolders,
    isLoading: isLoadingFolders,
    refetch: refetchFolders,
  } = useFolders(requestProps);

  const {
    data: documentsPage,
    isLoading: isLoadingDocuments,
    fetchNextPage,
    hasNextPage,
    refetch: refetchDocuments,
  } = useInfiniteDocuments({
    ...requestProps,
    status: status?.value,
  });

  const libraryDocuments = useMemo(() => {
    return documentsPage?.pages.flatMap((page) => page?.data?.documents);
  }, [documentsPage?.pages]);

  const shareFolderCheck = useCallback(async () => {
    if (folderSlug) {
      const navigationPath = await shareFolderIfNotAlready(user);
      navigationPath && navigate(navigationPath);
    }
  }, [folderSlug]);

  useEffect(() => {
    shareFolderCheck();
  }, [folderSlug]);

  const isLoading =
    isLoadingFolderDocuments || isLoadingDocuments || isLoadingFolders;
  const documents = isFolder ? folderDocuments : libraryDocuments;
  const folders = isFolder ? [] : libraryFolders;
  const refetch = isFolder
    ? refetchFolderDocuments
    : () => {
        refetchFolders();
        refetchDocuments();
      };

  const redirectToCat = (value) => {
    if (!projectView) {
      return value === 'All'
        ? navigate(ROUTES.ProjectSlug(projectId))
        : navigate(`${ROUTES.ProjectSlug(projectId)}/${value.toLowerCase()}`);
    }

    return value === 'All'
      ? navigate(ROUTES.Library)
      : navigate(`${ROUTES.Library}/${value.toLowerCase()}`);
  };

  const handleTogglePopover = () => {
    setShowPopover((show) => !show);
  };

  const DocumentPopover = (
    <Popover>
      <BSButton
        classList="button secondary_btn"
        ButtonText="Document"
        variant="outline-secondary"
        onClick={() => {
          setNewDocumentModalView(true);
          handleTogglePopover();
        }}
        icon={<FiFile />}
      />
      {!folderSlug && (
        <BSButton
          classList="button secondary_btn"
          ButtonText="Folder"
          variant="outline-secondary"
          onClick={() => {
            setNewFolderModalView(true);
            handleTogglePopover();
          }}
          icon={<FiFolder />}
        />
      )}
    </Popover>
  );

  const tabs = {
    All: null,
    Drafts: null,
    Starred: null,
    Trash: null,
    ...(projectId ? {} : { Shared: null }),
  };

  const documentsViewProps = {
    folders,
    fetchFolders: refetch,
    documents,
    isTrash,
    projectView,
  };
  const appliedFilters = filters?.map((filter) => filter?.filterBy) || [];
  const handleFilters = (index, val, type) => {
    const transformedFilters = filters.map((filter, filIndex) => {
      if (filIndex === index) {
        if (type === 'isOptions') {
          return {
            ...filter,
            [type]: val,
          };
        }

        if (type !== 'filterValue') {
          return {
            ...filter,
            [type]: val,
            filterValue: '',
          };
        }

        return {
          ...filter,
          [type]: val,
        };
      }

      return filter;
    });

    if (type === 'filterValue') {
      setFinalFilters(transformedFilters);
    }
    setFilters(transformedFilters);
  };

  return (
    <section className={Styles.Documents}>
      <section
        className={Styles.Documents_Wrapper}
        style={{ marginTop: noMargin === true ? 0 : !projectView ? '20px' : 0 }}
      >
        <Tab.Container
          className="nav-tabs"
          id="document-tabs"
          defaultActiveKey="grid"
        >
          <Nav className={Styles.ContentPlanner__Wrapper_Navigation}>
            <div className={Styles.ContentPlanner__Wrapper_Navigation__left}>
              <Nav.Item
                className={Styles.ContentPlanner__Wrapper_Navigation_Icon}
              >
                <Nav.Link
                  eventKey="grid"
                  className={
                    Styles.ContentPlanner__Wrapper_Navigation_Icon__Item
                  }
                >
                  <FiGrid size={20} />
                  <span>Grid view</span>
                </Nav.Link>
              </Nav.Item>
              <Nav.Item
                className={Styles.ContentPlanner__Wrapper_Navigation_Icon}
              >
                <Nav.Link
                  eventKey="list"
                  className={
                    Styles.ContentPlanner__Wrapper_Navigation_Icon__Item
                  }
                >
                  <CiGrid2H size={20} />
                  <span>List view</span>
                </Nav.Link>
              </Nav.Item>
            </div>
            <div className={Styles.ContentPlanner__Wrapper_Navigation__right}>
              {/* <Dropdown>
              <Dropdown.Toggle
                className={Styles.Documents_Wrapper_Navigation_Filter}
              >
                {documentType ? humanize(documentType) : 'All docs'}
                <FiChevronDown size={20} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {Object.keys(tabs).map((tabKey) => (
                  <Dropdown.Item
                    key={tabKey}
                    onClick={() => redirectToCat(tabKey)}
                  >
                    {tabKey}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <Dropdown>
              <Dropdown.Toggle
                className={Styles.Documents_Wrapper_Navigation_Filter}
              >
                {status
                  ? StatusOptions.find((s) => s.value === status.value)?.label
                  : 'All Status'}
                <FiChevronDown size={20} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => setStatus(undefined)}>
                  All Status
                </Dropdown.Item>
                {StatusOptions.map((status) => (
                  <Dropdown.Item
                    key={status.value}
                    onClick={() => setStatus(status)}
                  >
                    {status.label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            {isTrash && (documents?.length > 0 || folders?.length > 0) && (
              <div
                className={Styles.Documents_Wrapper_Navigation_Trash}
                onClick={() => setShowEmptyBinModal(true)}
              >
                <FiTrash size={14} color="#667085" />
                <span>Empty Bin</span>
              </div>
            )} */}
              <div className="px-2">
                <SearchInput
                  setInputValue={setSearchTerm}
                  inputValue={searchTerm}
                  text="Search"
                />
              </div>
              <Dropdown>
                {/* <Dropdown.Toggle
                  className={Styles.ContentPlanner__Wrapper_Navigation_Filter}
                >
                  <BsFilter size={20} />
                  Filter
                </Dropdown.Toggle> */}

                <Dropdown.Menu
                  className={
                    Styles.ContentPlanner__Wrapper_InputSection_DropDown
                  }
                >
                  {filters.map((filter, index) => {
                    const { filterBy, isOptions, filterValue } = filter || {};

                    const filterByValue = options.find(
                      (status) => status.value === filterBy,
                    );

                    return (
                      <div
                        className={
                          'justify-content-between gap-2 ' +
                          Styles.ContentPlanner__Wrapper_InputSection_Filters
                        }
                        style={{ marginBottom: 5 }}
                        key={index}
                      >
                        <section className="d-flex justify-content-start gap-2">
                          <Select
                            isSearchable={false}
                            className={'filter-Select'}
                            classNamePrefix={'filter-Select'}
                            styles={SelectStyles}
                            placeholder="Filter By"
                            options={options.filter(
                              (option) =>
                                !appliedFilters.includes(option.value),
                            )}
                            value={filterByValue || null}
                            onChange={(val) =>
                              handleFilters(index, val.value, 'filterBy')
                            }
                          />
                          <Select
                            isSearchable={false}
                            className="filter-Select"
                            classNamePrefix="filter-Select"
                            styles={SelectStyles}
                            placeholder="is"
                            options={isFilterOptions}
                            value={
                              isFilterOptions.find(
                                (status) => status.value === isOptions,
                              ) || null
                            }
                            onChange={(val) =>
                              handleFilters(index, val.value, 'isOptions')
                            }
                          />
                          <ContentFilter
                            filterBy={filterBy}
                            filterValue={filterValue}
                            handleFilters={handleFilters}
                            index={index}
                            filters={filters}
                            isOptions={isOptions}
                            project={projectId || selectedProject}
                          />
                        </section>

                        <RxCross2
                          size={14}
                          color="#667085"
                          // onClick={() => handleRemoveFilter(index)}
                        />
                      </div>
                    );
                  })}

                  {filters.length < 4 && (
                    <BSButton
                      icon={<BsPlus />}
                      classList="mt-3 button button-light"
                      ButtonText="Add filter"
                      variant="light"
                      onClick={() =>
                        setFilters((prevValue) => [
                          ...prevValue,
                          { filterBy: '', isOptions: '' },
                        ])
                      }
                    />
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <div
                className={`dropdown_container ${Styles.utility_left_margin}`}
              >
                <ProjectSelect setSelectedProject={setSelectedProject} />
              </div>
            </div>
          </Nav>
          <Tab.Content>
            {isLoading ? (
              <div className="mt-4">
                <Loader inline loaderHeading="Loading Library..." />
              </div>
            ) : folders?.length <= 0 && documents?.length <= 0 ? (
              <EmptyState
                emptyText="You don't have any documents yet."
                ctaText="Start creating now."
                buttonText="New Document"
                onClick={
                  currentSubscription?.plan_name === 'Basic Plan' &&
                  !projectView
                    ? () => setShowUpgradeModal(true)
                    : () => setNewDocumentModalView(true)
                }
              >
                <ImageView src="/Images/content.svg" />
              </EmptyState>
            ) : (
              <>
                <Tab.Pane eventKey="grid">
                  <DocumentsGridView
                    {...documentsViewProps}
                    activeDisplay={documentType}
                    next={fetchNextPage}
                    hasMore={hasNextPage}
                    // onClick={() => setNewDocumentModalView(true)}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="list">
                  <DocumentsListView {...documentsViewProps} />
                </Tab.Pane>
              </>
            )}
          </Tab.Content>
        </Tab.Container>
      </section>

      <OverlayTrigger
        placement="top"
        rootClose
        show={showPopover}
        onToggle={handleTogglePopover}
        overlay={DocumentPopover}
      >
        <LibraryDropdown
          onClick={handleTogglePopover}
          file={
            currentSubscription?.plan_name === 'Basic Plan' && !projectView
              ? () => setShowUpgradeModal(true)
              : () => {
                  setNewDocumentModalView(true);
                  handleTogglePopover();
                }
          }
          folder={() => {
            setNewFolderModalView(true);
            handleTogglePopover();
          }}
        />
      </OverlayTrigger>

      {NewDocumentModalView && (
        <NewDocumentModal
          show={NewDocumentModalView}
          onHide={() => setNewDocumentModalView(false)}
          folderId={folderSlug}
          projectId={projectId}
        />
      )}

      {NewFolderModalView && (
        <NewFolderModal
          show={NewFolderModalView}
          onHide={() => {
            setNewFolderModalView(false);
            refetch();
          }}
        />
      )}

      {showEmptyBinModal && (
        <ConfirmEmptyBin
          show={showEmptyBinModal}
          onHide={() => setShowEmptyBinModal(false)}
          fetchFolders={refetch}
        />
      )}
      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
        />
      )}
    </section>
  );
};

export default Documents;
