import React, { useEffect, useState } from 'react';
import Styles from './Settings.module.scss';
import Heading from './Components/Breadcrumb/Heading';
import { error as errorToast } from 'helpers/toaster';

import StoryDetailFormStyles from './Components/Breadcrumb/SettingsForm.module.scss';

import FocusKeyword from './Components/Breadcrumb/FocusKeyword';
import ImageView from 'Components/Image/ImageView';
import Input from 'Components/Input/Input';
import Dropdown from 'Components/Dropdown/Dropdown';
import * as Yup from 'yup';
import { ErrorMessage, Formik, Form } from 'formik';

import { TbExclamationCircle } from 'react-icons/tb';
import TextArea from 'Components/Input/TextArea/TextArea';
import { getAllMembers } from 'services/teamService';

import TagInput from 'Components/Input/TagsInput/TagInput';
import Datepicker from 'react-datepicker';
import {
  getDocumentSettings,
  saveDocumentSettings,
  getChannelsDropdownsData,
} from 'services/documentService';
import { useParams } from 'react-router-dom';

import { toast } from 'react-toastify';
import { settingsValidationSchema } from 'helpers/form';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import FormikTextField from 'Components/Form/FormikTextField';
import { setLoader } from 'redux/slices/loader';
import { useDispatch } from 'react-redux';

//
const Settings = ({
  setDisplaySettings,
  handlePublishDoc,
  setPublishSelectIntegration,
  publishSelectedIntegration,
  formSubmitRef,
  showUpgradeModal,
  setShowUpgradeModal,
}) => {
  const [activeButton, setActiveButton] = useState('Story Details');
  const { documentId } = useParams() || {};
  const [docSettings, setFetchedDocSettings] = useState({});
  const { user } = useSelector((state) => state.auth);
  const { subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};
  let integration =
    publishSelectedIntegration[Object.keys(publishSelectedIntegration)[0]];

  useEffect(() => {
    handleFetchDocSettings();
  }, [documentId]);

  useEffect(() => {
    setDisplaySettings(true); // Set display settings to true on component mount
    return () => setDisplaySettings(false); // Reset to false on unmount
  }, []);

  const handleFetchDocSettings = async () => {
    try {
      let integrationId =
        publishSelectedIntegration[Object.keys(publishSelectedIntegration)[0]]
          ?.id;
      const { data } = await getDocumentSettings(documentId, integrationId);

      setFetchedDocSettings(data);
    } catch (error) {
      console.log('error', error);
    }
  };
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };
  const handleSubmitSettings = async (formData) => {
    if (currentSubscription?.plan_name === 'Basic Plan') {
      setShowUpgradeModal(true);
      return;
    }
    const objectKey = Object.keys(publishSelectedIntegration)[0];
    if (!publishSelectedIntegration[objectKey] || !objectKey) {
      errorToast('Please select one channel to publish');
      return;
    }

    if (
      publishSelectedIntegration[objectKey].category !== 'social' &&
      formData?.story_detail?.cover_image === undefined
    ) {
      errorToast('Please select a cover image in story details.');
      return;
    }

    try {
      const data = await saveDocumentSettings(documentId, formData);

      if (data?.data?.message === 'Settings saved successfully.') {
        toast.success(data?.data?.message);
        if (handlePublishDoc) {
          handlePublishDoc();
        }
      }
    } catch (error) {
      console.log('error', error);
      toast.error(error?.response?.data?.error);
    }
  };

  return (
    <div className={Styles.settings_container}>
      <div>
        <SettingsForm
          handleSubmitSettings={handleSubmitSettings}
          settingsFormValues={docSettings}
          setDisplaySettings={setDisplaySettings}
          setPublishSelectIntegration={setPublishSelectIntegration}
          publishSelectedIntegration={publishSelectedIntegration}
          formSubmitRef={formSubmitRef}
          showUpgradeModal={showUpgradeModal}
          setShowUpgradeModal={setShowUpgradeModal}
          user={user}
        />

        {/* <div id="Focus Keywords" className={Styles.settings_container__form}>
          <div className={Styles.settings_container__form__heading_container}>
            <div>
              <Heading
                heading="Focus Keywords"
                desc="Add focus key Phrases to tailor the suggestions of your blog."
              />
            </div>
            <button className="btn secondary_btn px-3">Add New</button>
          </div>
          <FocusKeyword />
        </div> */}
      </div>
    </div>
  );
};

export default Settings;

const SettingsForm = ({
  handleSubmitSettings,
  settingsFormValues,
  setDisplaySettings,
  handlePublishDoc,
  setPublishSelectIntegration,
  publishSelectedIntegration,
  formSubmitRef,
  showUpgradeModal,
  setShowUpgradeModal,
  user,
}) => {
  const [authorsList, setAuthorsList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [tagsList, setTagsList] = useState([]);
  const dispatch = useDispatch();

  const [selectedFile, setSelectedFile] = useState();

  const [previewUrl, setPreviewUrl] = useState(null);

  let statusList = [
    {
      value: 'publish',
      label: 'Publish',
    },
  ];

  const channel =
    publishSelectedIntegration[Object.keys(publishSelectedIntegration)[0]].type;
  const category =
    publishSelectedIntegration[Object.keys(publishSelectedIntegration)[0]]
      .category;

  if (category !== 'social') {
    statusList = statusList.concat([
      {
        value: 'future',
        label: 'Future',
      },
      {
        value: 'draft',
        label: 'Draft',
      },
      {
        value: 'pending',
        label: 'Pending',
      },
    ]);
  }

  const integrationId =
    publishSelectedIntegration[Object.keys(publishSelectedIntegration)[0]]?.id;

  useEffect(() => {
    fetchDataByChannel(integrationId);
  }, [integrationId]);

  useEffect(() => {
    if (settingsFormValues?.story_detail?.cover_image) {
      setPreviewUrl(settingsFormValues?.story_detail?.cover_image);
      setSelectedFile(settingsFormValues?.story_detail?.cover_image);
    }
  }, [settingsFormValues]);

  const fetchDataByChannel = async (integrationId) => {
    try {
      dispatch(
        setLoader({
          isLoading: true,
          heading: 'Fetching!',
          description: 'Please wait...',
        }),
      );
      const response = await getChannelsDropdownsData(integrationId);
      if (response.status !== 200 && response.status !== 204) {
        dispatch(setLoader({ isLoading: false }));
        throw new Error('Failed to fetch data');
      } else {
        if (channel === 'wordpress') {
          setAuthorsList(
            response?.data?.authors?.map((author) => ({
              value: author.id,
              label: author.name,
            })),
          );
          setCategoryList(
            response?.data?.categories?.map((category) => ({
              value: category.id,
              label: category.name,
            })),
          );
          setTagsList(
            response?.data?.tags?.map((tags) => ({
              value: tags.id.toString(),
              label: tags.name,
            })),
          );
        } else if (channel === 'wix') {
          setAuthorsList([
            {
              value: user.id,
              label: user.name,
            },
          ]);
          setCategoryList(
            response?.data?.categories?.map((category) => ({
              value: category.id,
              label: category.name,
            })),
          );
          setTagsList(
            response?.data?.tags?.map((tags) => ({
              value: tags.id,
              label: tags.name,
            })),
          );
        } else if (channel === 'webflow') {
          setAuthorsList([
            {
              value: user.id,
              label: user.name,
            },
          ]);
        }
        dispatch(setLoader({ isLoading: false }));
      }

      // return data;
      //
    } catch (error) {
      console.error('Error fetching data:', error);
      dispatch(setLoader({ isLoading: false }));
      return null; // Return null in case of an error
    }
  };

  const changeHandler = async (event) => {
    const file = event.target.files[0];
    if (file) {
      let returnImg = '';
      const previewUrl = URL.createObjectURL(file);
      setPreviewUrl(previewUrl);

      if (file) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const imageDataUrl = reader?.result;
          returnImg = imageDataUrl;
          setSelectedFile(imageDataUrl);
        };
        reader.readAsDataURL(file);
        return returnImg;
      }
    }
  };

  const handleFormSubmit = (values) => {
    const { publish_settings, focus_keywords, ...data } = values;

    const objSend = {
      ...data,
      integration_id:
        publishSelectedIntegration[Object.keys(publishSelectedIntegration)[0]]
          ?.id,
      story_detail: {
        ...values.story_detail,
        cover_image: selectedFile,
        tags: values.story_detail.tags.join(', '),
      },
      status: values.publish_settings?.status,
      scheduled_at: values?.publish_settings?.scheduled_at,
    };
    handleSubmitSettings(objSend);
  };

  return (
    <>
      <Formik
        initialValues={{
          story_detail: {
            author:
              channel === 'wix' ||
              channel === 'webflow' ||
              category === 'social'
                ? user?.id || '' // fallback to an empty string if user.name is undefined
                : settingsFormValues?.story_detail?.author || '',
            category: settingsFormValues
              ? settingsFormValues?.story_detail?.category
              : '',
            // tags: settingsFormValues
            //   ? settingsFormValues?.story_detail?.tags
            //   : '',
            tags:
              settingsFormValues?.story_detail?.tags
                ?.split(',')
                .map((item) => item.trim()) || [],
            cover_image:
              previewUrl || settingsFormValues
                ? settingsFormValues?.story_detail?.cover_image
                : '',
          },
          seo_setting: {
            slug: settingsFormValues
              ? settingsFormValues?.seo_setting?.slug
              : '',
            meta_description: settingsFormValues
              ? settingsFormValues?.seo_setting?.meta_description
              : '',
          },
          publish_settings: {
            status: settingsFormValues
              ? settingsFormValues?.publish_settings?.status
              : '',
            scheduled_at: settingsFormValues
              ? settingsFormValues?.publish_settings?.scheduled_at
              : null,
          },
          focus_keywords: settingsFormValues?.focus_keywords
            ? [...settingsFormValues?.focus_keywords]
            : [{ name: '' }],
        }}
        validationSchema={
          category === 'social' ? undefined : settingsValidationSchema
        }
        validateOnMount={true}
        enableReinitialize={true}
        onSubmit={(values) => handleFormSubmit(values)}
      >
        {({
          values,
          setFieldValue,
          isValid,
          errors,
          handleSubmit,
          setFieldTouched,
        }) => {
          useEffect(() => {
            if (formSubmitRef) {
              formSubmitRef.current = () => {
                handleFormSubmit(values); // Call submit with current form values
              };
            }
          }, [formSubmitRef, values]);

          useEffect(() => {
            if (settingsFormValues) {
              setFieldTouched('publish_settings.scheduled_at', true);
            }
          }, [settingsFormValues]);

          useEffect(() => {
            setFieldValue('story_detail.cover_image', selectedFile);
          }, [selectedFile]);

          return (
            <Form
              className={StoryDetailFormStyles.Container}
              // onSubmit={()=>handleSubmit(values)}
            >
              <div
                id="Post Settings"
                className={Styles.settings_container__form}
              >
                <Heading
                  heading="Post Settings"
                  desc="A summary of the story used on overview pages, social and email channels."
                />

                <div className={StoryDetailFormStyles.form_container}>
                  <div>
                    <LabelComponent
                      label="Cover Image:"
                      className="my-2"
                      required={category !== 'social'}
                    />
                    <div className="w-100">
                      {previewUrl && (
                        <img
                          src={previewUrl}
                          alt="Preview"
                          className="img-fluid"
                          height={300}
                          width={400}
                        />
                      )}
                    </div>
                    {}
                    <section className={StoryDetailFormStyles.DragDropArea}>
                      <div className="d-flex flex-column justify-content-center align-items-center text-center ">
                        <p
                          className={
                            StoryDetailFormStyles.DragDropArea__fileMsg
                          }
                        >
                          <span
                            className={
                              StoryDetailFormStyles.DragDropArea__fileMsg_highlight
                            }
                          >
                            Click to upload
                          </span>{' '}
                          or drag and drop
                        </p>
                        <p
                          className={
                            StoryDetailFormStyles.DragDropArea__fileMsg_format
                          }
                        >
                          {' '}
                          jpeg, jpg, png, gif (max.1000 Mb)
                        </p>
                      </div>
                      <Input
                        type="file"
                        onChange={(event) => {
                          const imageText = changeHandler(event);
                          setFieldValue(
                            'story_detail.cover_image',
                            selectedFile,
                          );
                        }}
                        accept=".jpg,.jpeg,.png"
                      />
                    </section>
                    <ErrorMessage
                      name="seo_setting.cover_image"
                      component="div"
                      className="error mt-2"
                    />
                  </div>
                  {category === 'social' ? (
                    <></>
                  ) : (
                    <>
                      <div className={`${StoryDetailFormStyles.fullWidth}`}>
                        <LabelComponent label="Author" className="my-2" />
                        <Dropdown
                          name="story_detail.author"
                          label={''}
                          placeholder="Select author"
                          iconWithLabel={true}
                          options={authorsList}
                          // value={values.story_detail.author}
                          value={authorsList?.find(
                            (option) =>
                              option.value === values.story_detail.author,
                          )}
                          onChange={({ value }) =>
                            setFieldValue('story_detail.author', value)
                          }
                          isDisabled={
                            channel === 'wix' || channel === 'webflow'
                          }
                        />
                      </div>
                      {channel === 'webflow' ? (
                        <div
                          className={StoryDetailFormStyles.fullWidth + ' my-2'}
                        >
                          <FormikTextField
                            id="story_detail.category"
                            name="story_detail.category"
                            type="text"
                            placeholder="Enter Category"
                            Label="Category"
                            classList="input form-control"
                            isRequired
                          />
                          <ErrorMessage
                            name="story_detail.category"
                            component="div"
                            className="error mt-2"
                          />
                        </div>
                      ) : (
                        <div className={`${StoryDetailFormStyles.fullWidth}`}>
                          <LabelComponent label="Category" className="my-2" />
                          <Dropdown
                            name="story_detail.category"
                            label={''}
                            placeholder="Select Category"
                            iconWithLabel={true}
                            options={categoryList}
                            // value={values.story_detail.author}
                            value={categoryList?.find(
                              (option) =>
                                option.value === values.story_detail.category,
                            )}
                            onChange={({ value }) =>
                              setFieldValue('story_detail.category', value)
                            }
                          />
                        </div>
                      )}
                      {channel === 'webflow' ? (
                        <div className={StoryDetailFormStyles.fullWidth}>
                          <div className="my-2">
                            <LabelComponent label="Tags" />
                          </div>

                          <TagInput
                            name="story_detail.tags"
                            // tags={[]}
                            label=""
                            tags={values?.story_detail?.tags}
                            onChange={(value) => {
                              setFieldValue('story_detail.tags', value);
                            }}
                            placeholder="Enter Tags..."
                            showError={true}
                          />
                          <ErrorMessage
                            name="story_detail.tags"
                            component="div"
                            className="error mt-2"
                          />
                        </div>
                      ) : (
                        <div className={`${StoryDetailFormStyles.fullWidth}`}>
                          <LabelComponent label="Tags" className="my-2" />
                          <Dropdown
                            name="story_detail.tags"
                            label={''}
                            placeholder="Select Tags"
                            iconWithLabel={true}
                            options={tagsList}
                            value={tagsList?.filter((option) =>
                              values.story_detail.tags.includes(option.value),
                            )}
                            onChange={(selectedOptions) => {
                              setFieldValue(
                                'story_detail.tags',
                                selectedOptions.map((option) => option.value),
                              );
                            }}
                            isMulti={true}
                          />
                        </div>
                      )}
                      <div
                        className={StoryDetailFormStyles.fullWidth + ' my-2'}
                      >
                        <FormikTextField
                          id="seo_setting.slug"
                          name="seo_setting.slug"
                          type="text"
                          placeholder="Enter Slug"
                          Label="Slug"
                          classList="input form-control"
                          isRequired
                        />
                        <ErrorMessage
                          name="seo_setting.slug"
                          component="div"
                          className="error mt-2"
                        />
                      </div>
                      <div className={StoryDetailFormStyles.fullWidth + 'my-2'}>
                        <FormikTextField
                          id="seo_setting.meta_description"
                          name="seo_setting.meta_description"
                          type="text"
                          placeholder="Add excerpt or use AI to generate..."
                          Label="Meta Description:"
                          classList="input form-control text-area-height"
                          isRequired
                          isTextArea
                        />
                        <ErrorMessage
                          name="seo_setting.meta_description"
                          component="div"
                          className="error mt-2"
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div
                id="Publishing Settings"
                className={Styles.settings_container__form}
              >
                <Heading
                  heading="Publishing Settings"
                  // desc="A summary of the story used on overview pages, social and email channels."
                />
                <div className={StoryDetailFormStyles.form_container}>
                  <div className={StoryDetailFormStyles.fullWidth}>
                    <LabelComponent
                      label="Status"
                      className="my-2"
                      required={true}
                    />

                    <Dropdown
                      name="publish_settings.status"
                      label={''}
                      placeholder="Select status"
                      iconWithLabel={true}
                      options={statusList}
                      // value={values.story_detail.author}
                      value={statusList?.find(
                        (option) =>
                          option.value === values.publish_settings.status,
                      )}
                      onChange={({ value }) =>
                        setFieldValue('publish_settings.status', value)
                      }
                    />
                  </div>
                </div>
              </div>

              <div
                className={StoryDetailFormStyles.width_full}
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  marginBottom: '5rem',
                  gap: '1rem',
                }}
              >
                <button
                  className="btn secondary_btn px-3 mb-4"
                  type="submit"
                  disabled={!isValid}
                >
                  Publish
                </button>
                <button
                  className="btn cancel_btn px-3 mb-4"
                  onClick={() => setPublishSelectIntegration({})}
                  type="button"
                  // disabled={!isValid}
                >
                  Cancel
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

const LabelComponent = ({
  label = '',
  className = '',
  required = true,
  info,
}) => {
  return (
    <div className={className}>
      <span className="Input_label">
        {label}{' '}
        {required && <span style={{ color: 'rgb(180, 35, 24)' }}> *</span>}
      </span>
      {info && (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="tooltip-top">{info}</Tooltip>}
        >
          <span>
            <TbExclamationCircle
              color="#98A2B3"
              style={{
                height: '20px',
                width: '20px',
                marginLeft: '4px',
                transform: 'rotate(180deg)',
                cursor: 'pointer',
              }}
            />
          </span>
        </OverlayTrigger>
      )}
    </div>
  );
};
