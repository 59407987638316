import Styles from '../Components/Pages/Onboarding/Forms/Styles.module.scss';
const tagClasses = [
  Styles.tag_green,
  Styles.tag_orange,
  Styles.tag_purpel,
  Styles.tag_red,
];

export const getTagClass = (index) => {
  return tagClasses[index % tagClasses.length];
};

export const convertToOptions = (list) => {
  return list
    ? list.map((ele) => ({
        label: ele,
        value: ele,
      }))
    : {};
};

export function getDomainName(url) {
  // Add 'http://' if the URL doesn't have a scheme
  if (!/^https?:\/\//i.test(url)) {
    url = 'http://' + url;
  }

  // Create an anchor element to utilize the browser's URL parsing capabilities
  const anchor = document.createElement('a');
  anchor.href = url;

  // Extract the hostname from the URL
  let hostname = anchor.hostname;

  // Remove 'www.' if it exists
  if (hostname.startsWith('www.')) {
    hostname = hostname.slice(4);
  }

  return hostname;
}
