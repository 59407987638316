import React from 'react';
import { FacebookLoginButton } from 'react-social-login-buttons';
import { LoginSocialFacebook } from 'reactjs-social-login';
import Styles from '../Socials.module.scss';
import { postFacebookIntegration } from 'services/integrationService';

const FacebookLoginComponent = ({ onLoginSuccess }) => {
  const handleFacebookLogin = async ({ data }) => {
    try {
      const accessToken = data.accessToken;
      await postFacebookIntegration({
        access_token: accessToken,
      });
      onLoginSuccess();
    } catch (error) {
      console.error('Facebook Login Error:', error);
    }
  };

  return (
    <div>
      <LoginSocialFacebook
        appId="516068428115571"
        scope="pages_manage_posts,pages_show_list,pages_read_engagement,business_management"
        onResolve={handleFacebookLogin}
        onReject={(error) => console.log(error)}
      >
        <img
          src={`/Images/logo-facebook.png`}
          alt={'facebook'}
          width="50"
          height="50"
        />
      </LoginSocialFacebook>
    </div>
  );
};

export default FacebookLoginComponent;
