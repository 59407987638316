import React from 'react';

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import store from 'redux/store';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { ToastContainer } from 'react-toastify';
import 'react-tooltip/dist/react-tooltip.css';
import * as Sentry from '@sentry/react';

import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));

const reactPlugin = new ReactPlugin();

const environment = process.env.REACT_APP_ENV || process.env.NODE_ENV;

const isProduction = environment === 'production';
const isStaging = environment === 'staging';
const isDevelopment = environment === 'development';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  environment: environment,
  tracesSampleRate: isProduction ? 0.1 : 1.0, // Sample rate for performance monitoring
  tracePropagationTargets: isProduction
    ? [/^https:\/\/vocable\.ai\/api/] // Production API
    : isStaging
      ? [/^https:\/\/staging\.vocable\.ai\/api/] // Staging API
      : ['localhost', /^http:\/\/localhost:\d+/],
  replaysSessionSampleRate: isProduction ? 0.1 : 1.0, // Lower session sample rate in production
  replaysOnErrorSampleRate: 1.0, // Always capture replays for errors in both environments
});

root.render(
  <Provider store={store}>
    <BrowserRouter>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_API_KEY}>
        <ToastContainer />
        <App />
      </GoogleOAuthProvider>
    </BrowserRouter>
  </Provider>,
);

const appInsights = new ApplicationInsights({
  config: {
    connectionString: process.env.REACT_APP_AZURE_MONITOR_CONNECTION_STRING,
    enableAutoRouteTracking: true,
    extensions: [reactPlugin],
  },
});

appInsights.loadAppInsights();
