import React, { useState, useCallback, useEffect } from 'react';
import Styles from './OnBoardingFlow.module.scss';
import Heading from 'Components/Pages/Onboarding/Heading/Heading';
import Stepper from 'Components/Pages/Onboarding/Stepper/Stepper';
import Step1 from 'Components/Pages/Onboarding/Forms/Step1';
import Step4 from 'Components/Pages/Onboarding/Forms/Step4';
import Step3 from 'Components/Pages/Onboarding/Forms/Step3';
import Step2 from 'Components/Pages/Onboarding/Forms/Step2';
import { useDispatch, useSelector } from 'react-redux';
import AIProjectPlan from 'Components/Projects/ProjectPlan/AIProjectPlan';
import BusinessSummary from 'Components/Pages/Onboarding/Forms/BusinessSummary';
import SubscriptionScreen from 'Containers/Subscription/Subscription';
import useFetchPlans from 'hooks/subscriptions/fetchPlans';
import BottomDrawer from 'Components/Drawer/BottomDrawer';
import OnboardingHeader from 'Components/Pages/Onboarding/Header/Header';
import PaymentScreen from 'Containers/Subscription/PaymentScreen';
import ConfirmSubscription from 'Containers/Subscription/ConfirmSubscription';
import { useNavigate } from 'react-router-dom';
import { resetOnboarding } from 'redux/slices/newOnboarding';

const OnboardingFlow = () => {
  const [paymentMethods, setPaymentMethods] = useState({});
  const [currentSubscription, setCurrentSubscription] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState({});
  const [lastSubscription, setLastSubscription] = useState();
  const steps = [0, 1, 2, 3, 4];
  const [haveweb, setHaveweb] = useState(false);
  const [autofill, setAutofill] = useState(false);
  const [headlines, setHeadlines] = useState([]);
  const [showBoard, setShowBoard] = useState(true); // State to manage the display of Board component
  // const [skip, setSkip] = useState(false); // State to manage the display of Board component
  const [productData, setProductData] = useState({});
  const [isTrial, setTrial] = useState(false);
  const [summaryData, setOrderSummary] = useState({});
  // const [jobId, setJobId] = useState();

  const dispatch = useDispatch();
  const currentStep = useSelector((state) => state.newOnboarding.currentStep);
  const fetchPlansData = useFetchPlans();
  const navigate = useNavigate();

  const openSubscriptionModal = () => {
    if (currentSubscription?.active) {
      navigate('/');
    } else {
      setIsModalOpen(true);
    }
  };
  const closeSubscriptionModal = () => setIsModalOpen(false);

  const subscriptionPlansDrawerCallback = () => {
    return currentSubscription?.active
      ? navigate('/')
      : openSubscriptionModal();
  };
  const fetchPlans = useCallback(async () => {
    fetchPlansData({
      setPaymentMethods,
      setSubscriptionPlans,
      setCurrentSubscription,
      setLastSubscription,
      showLoader: false,
    });
    closeSubscriptionModal();
  }, []);

  useEffect(() => {
    fetchPlans();
  }, [fetchPlans]);

  useEffect(() => {
    // Cleanup function to reset onboarding when the component unmounts
    return () => {
      dispatch(resetOnboarding());
    };
  }, [dispatch]);

  // this will reset redux if user during expand plan move to another route
  useEffect(() => {
    if (location.pathname === '/new-plan') {
      dispatch(resetOnboarding());
    }
  }, [location.pathname, dispatch]);

  const getHeadingContent = (step) => {
    const firstName = localStorage.getItem('user')
      ? JSON.parse(localStorage.getItem('user')).first_name
      : '';

    switch (step) {
      case 0:
        return {
          title: `Welcome ${firstName}!`,
          description:
            "Let's generate your content plan tailored to your brand.",
        };
      case 1:
        return {
          title: 'Brand Summary',
          description: haveweb
            ? "To tailor the content plan specifically to your brand's voice and goals, please fill out the details below:"
            : 'The first step to creating relevant and impactful content is establishing a clear verbal brand identity. Based on your website, here’s a general brand overview we’ve generated for you.',
        };
      case 2:
        return {
          title: 'Content Topics',
          description:
            'We generate your content based on these topics. Change them to see different content.',
        };
      case 3:
        return {
          title: 'Content Type & Timeline',
          description:
            'Let us know which type of content type you want to create.',
        };
      case 4:
        return {
          title:
            'Ta-da! There it is…Your content plan is your new roadmap to success.',
          description:
            "A well-structured content plan is key to executing a successful content strategy. We've packed your plan with detailed and customizable briefs for each piece of content. Super cool, right? Now, all that's left is the fun part—bringing these briefs to life!",
        };
      default:
        return {
          title: '',
          description: '',
        };
    }
  };
  const { title, description } = getHeadingContent(currentStep);
  const path = location.pathname === '/onboarding' ? 'onboarding' : 'newPlan';

  return (
    <>
      {/* {location.pathname === "/onboarding" && <OnboardingHeader /> }  */}
      {path === 'onboarding' && <OnboardingHeader />}
      <div
        className={
          currentStep >= 4 ? Styles['onboarding-step5'] : Styles.onboarding
        }
      >
        <Heading
          title={title}
          description={description}
          classes={
            currentStep == 1 && showBoard && !haveweb
              ? { display: 'none' }
              : { display: 'flex' }
          }
        />
        {currentStep < 4 && (
          <Stepper
            steps={steps}
            activeStep={currentStep}
            classes={
              currentStep == 1 && showBoard && !haveweb
                ? { display: 'none' }
                : { display: 'flex' }
            }
          />
        )}

        {currentStep === 0 && (
          <Step1
            haveweb={haveweb}
            setHaveweb={setHaveweb}
            autofill={autofill}
            setAutofill={setAutofill}
            openSubscriptionModal={openSubscriptionModal}
            flow={path}
            // jobId={jobId}
            // setJobId={setJobId}
          />
        )}
        {currentStep === 1 &&
          (haveweb ? (
            <Step2 />
          ) : (
            <BusinessSummary
              setShowBoard={setShowBoard}
              showBoard={showBoard}
              haveweb={haveweb}
              // jobId={jobId}
            />
          ))}
        {currentStep === 2 && <Step3 haveweb={haveweb} />}
        {currentStep === 3 && <Step4 setHeadlines={setHeadlines} />}
        {currentStep === 4 && (
          <AIProjectPlan
            headlines={headlines}
            flow={path}
            subscriptionPlansDrawer={subscriptionPlansDrawerCallback}
            onboardingFooter={true}
          />
        )}
        {currentStep === 5 && (
          <PaymentScreen
            productData={productData}
            setOrderSummary={setOrderSummary}
          />
        )}

        {currentStep === 6 && <ConfirmSubscription summaryData={summaryData} />}

        <BottomDrawer
          title="Subscription Plan"
          show={isModalOpen}
          placement="bottom"
        >
          <SubscriptionScreen
            closeDrawer={() => setIsModalOpen(false)}
            subscriptionPlans={subscriptionPlans}
            paymentMethods={paymentMethods}
            currentSubscription={currentSubscription}
            lastSubscription={lastSubscription}
            refetch={fetchPlans}
            setProductData={setProductData}
            setTrial={setTrial}
          />
        </BottomDrawer>
      </div>
    </>
  );
};

export default OnboardingFlow;
