import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from 'helpers/axios';

// ##### ##### ##### ##### #####   GET   ##### ##### ##### ##### ##### //
export const getAvailableIntegrations = () => {
  return getRequest('integrations/available');
};

// ##### ##### ##### ##### #####   POST   ##### ##### ##### ##### ##### //
export const postNewIntegration = (type, body) => {
  return postRequest(`integrations/${type}`, body);
};

export const postLinkedInIntegration = (body) => {
  return postRequest(`integrations/linkedin`, body);
};
export const addLinkedinOrgs = (body) => {
  return postRequest(`integrations/linkedin/add_orgs`, body);
};

export const postFacebookIntegration = (body) => {
  return postRequest(`integrations/facebook`, body);
};

export const getFacebookPageList = (token) => {
  return getRequest(`integrations/facebook/fetch_pages?access_token=${token}`);
};

export const postXIntegration = (body) => {
  return postRequest(`integrations/x`, body);
};

export const getXToken = () => {
  return postRequest(`integrations/x/request_token`);
};

// ##### ##### ##### ##### #####   PUT   ##### ##### ##### ##### ##### //
export const updateIntegration = (type, id, body) => {
  return putRequest(`integrations/${type}/${id}`, body);
};

// ##### ##### ##### ##### #####   PATCH   ##### ##### ##### ##### ##### //
export const patchIntegration = (type, id, param, body) => {
  return patchRequest(`integrations/${type}/${id}/${param}`, body);
};

// ##### ##### ##### ##### #####   DELETE   ##### ##### ##### ##### ##### //
export const deleteIntegration = (type, id) => {
  return deleteRequest(`integrations/${type}/${id}`);
};

// ##### ##### ##### ##### #####   ? WEIRD ?   ##### ##### ##### ##### ##### //
export const publishDocuments = (documentId, payload) => {
  return postRequest(`documents/${documentId}/publish`, payload);
};

// ##### ##### ##### ##### #####   ? WEIRD ?   ##### ##### ##### ##### ##### //
export const getUserIntegrations = () => {
  return getRequest(`user_integrations`);
};
