import { useState } from 'react';
import { Tab, Nav, Col } from 'react-bootstrap';
import IntegrationForm from './IntegrationForm';
import Modal from 'react-bootstrap/Modal';
import { capitalize } from 'helpers';
import Styles from './IntegrationModal.module.scss';
import { FaArrowLeft } from 'react-icons/fa';

import LinkedinLoginForm from '../../OAuth/Linkedin/LinkedinLoginForm';
import FacebookLoginForm from '../../OAuth/Facebook/FacebookLoginForm';
import XLoginForm from '../../OAuth/X/XLoginForm';

const IntegrationNavItem = ({ type, onClick }) => (
  <div key={type}>
    <Nav.Item>
      <Nav.Link className={Styles.IntegrationBtn} onClick={() => onClick(type)}>
        <img
          src={`/Images/logo-${type.toLowerCase()}.png`}
          alt={type}
          width="50"
          height="50"
        />
      </Nav.Link>
    </Nav.Item>
  </div>
);

const IntegrationModal = ({
  projectId,
  availableIntegrations,
  handleAddSubmit,
  handleUpdateSubmit,
  onSocialSuccess,
  updatedIntegration,
  show,
  onHide,
}) => {
  const [step, setStep] = useState(1);
  const [tabKey, setTabKey] = useState(
    (updatedIntegration && capitalize(updatedIntegration.type)) ||
      availableIntegrations[0]?.type,
  );

  const handleTabClick = (type) => {
    setTabKey(type);
    setStep(2);
  };

  const handleFormSubmit = async (type, values) => {
    const payload = { integration: { ...values, project_id: projectId } };
    if (updatedIntegration) {
      handleUpdateSubmit(type, updatedIntegration.id, payload);
    } else {
      handleAddSubmit(type, payload);
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      size={600}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header className="border-0 pb-0" closeButton>
        <Modal.Title>
          {updatedIntegration ? 'Updating' : 'New'} Integration
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        {step === 1 ? (
          <>
            <Tab.Container id="integration-tabs" activeKey={tabKey}>
              <Nav
                variant="pills"
                className="justify-content-center  text-center"
              >
                {availableIntegrations?.map((integration) => (
                  <IntegrationNavItem
                    key={integration.type}
                    type={integration.type}
                    onClick={handleTabClick}
                  />
                ))}
                <div key={'linkedin'}>
                  <Nav.Item>
                    <Nav.Link className={Styles.IntegrationBtn}>
                      <LinkedinLoginForm onLoginSuccess={onSocialSuccess} />
                    </Nav.Link>
                  </Nav.Item>
                </div>
                <div key={'linkedin'}>
                  <Nav.Item>
                    <Nav.Link className={Styles.IntegrationBtn}>
                      <FacebookLoginForm onLoginSuccess={onSocialSuccess} />
                    </Nav.Link>
                  </Nav.Item>
                </div>

                <div key={'linkedin'}>
                  <Nav.Item>
                    <Nav.Link className={Styles.IntegrationBtn}>
                      <XLoginForm onLoginSuccess={onSocialSuccess} />
                    </Nav.Link>
                  </Nav.Item>
                </div>
              </Nav>
            </Tab.Container>
          </>
        ) : (
          <>
            <button className="btn mb-3 p-0" onClick={() => setStep(1)}>
              <FaArrowLeft size={20} /> {/* Back arrow icon */}
            </button>
            <Tab.Container activeKey={tabKey}>
              <Tab.Content>
                {availableIntegrations?.map((integration) => (
                  <Tab.Pane eventKey={integration.type} key={integration.type}>
                    <div class="d-flex justify-content-center">
                      <img
                        src={`/Images/logo-${integration.type.toLowerCase()}.png`}
                        alt={integration.type}
                        width="100"
                        height="100"
                      />
                    </div>
                    <IntegrationForm
                      type={integration.type.toLowerCase()}
                      fields={integration.fields}
                      defaultValues={updatedIntegration}
                      onSubmit={handleFormSubmit}
                    />
                  </Tab.Pane>
                ))}
              </Tab.Content>
            </Tab.Container>
          </>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default IntegrationModal;
