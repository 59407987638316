import React, { useState } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import {
  FiChevronRight,
  FiCopy,
  FiDownload,
  FiRefreshCcw,
  FiTrash2,
  FiUserPlus,
} from 'react-icons/fi';
import { BiRightArrowAlt } from 'react-icons/bi';
import { FaRegArrowAltCircleRight } from 'react-icons/fa';

import ExportOptions from './ExportOptions';
import StyleClasses from './OptionsDropDown.module.scss';
import { useQueryClient } from '@tanstack/react-query';
import { BsFillCircleFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import UpgradeModal from 'Containers/Modal/UpgradeModal/UpgradeModal';

const FileOptions = ({
  isDocumentOwner,
  isFullAccess,
  isTrash,
  drop,
  Icon,
  setShareModalView,
  setMoveToFolderModalView,
  setMovetoStatusModalView,
  OptionsFor,
  toggleShowFormats,
  showExportFormats,
  chevronRight,
  exportFile,
  openDuplicateModal,
  setDeleteDocumentModalView,
  restoreDocument,
  documentAccessLevel,
  setDeleteTrashConfirmModalView,
  isDashboardView = false,
  projectId,
}) => {
  const queryClient = useQueryClient();

  const hasFullAccess = isDocumentOwner || isFullAccess;

  const { user } = useSelector((state) => state.auth);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { id: userId, subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  return (
    <>
      {!isTrash ? (
        <DropdownButton
          className={StyleClasses.DropdownWrapper}
          drop={drop}
          title={Icon}
        >
          <Dropdown.Item
            onClick={() => setMovetoStatusModalView(true)}
            className={StyleClasses.dropDownItemWrapper}
          >
            <div className={StyleClasses.listItem_sm}>
              {/* <BsFillCircleFill size={10} color="#2e90fa" /> */}
              <FaRegArrowAltCircleRight color="#667085" />
              <p>Move to status</p>
            </div>
          </Dropdown.Item>

          {hasFullAccess && (
            <Dropdown.Item
              onClick={
                currentSubscription?.plan_name === 'Basic Plan' &&
                projectId !== null
                  ? () => setShowUpgradeModal(true)
                  : () => setShareModalView(true)
              }
              // onClick={ () => setShareModalView(true)}

              className={StyleClasses.dropDownItemWrapper}
            >
              <div className={StyleClasses.listItem_sm}>
                <FiUserPlus color="#667085" size={16} />
                <p>Add Collaborator</p>
              </div>
            </Dropdown.Item>
          )}
          {!isDashboardView && (
            <>
              {hasFullAccess && (
                <Dropdown.Item
                  // onClick={() => setMoveToFolderModalView(true)}
                  onClick={(e) => {
                    e.stopPropagation();
                    setTimeout(() => {
                      setMoveToFolderModalView(true);
                    }, 150); // Adjust the delay as needed
                  }}
                  className={`${StyleClasses.dropDownItemWrapper} ${
                    !hasFullAccess && StyleClasses.dropDownItemWrapper__disabled
                  }`}
                >
                  <div className={StyleClasses.listItem_sm}>
                    {/* <BiRightArrowAlt color="#667085" size={16} /> */}
                    <FaRegArrowAltCircleRight color="#667085" />
                    <p>Move to a folder</p>
                  </div>
                </Dropdown.Item>
              )}
              {OptionsFor === 'Folder' ? (
                'Export as Zip'
              ) : (
                <Dropdown.Item
                  className={StyleClasses.dropDownItemWrapper}
                  onMouseEnter={toggleShowFormats}
                  onMouseLeave={toggleShowFormats}
                >
                  <div
                    className={'position-relative ' + StyleClasses.listItem_sm}
                    style={{ maxWidth: '100%' }}
                  >
                    <FiDownload size={16} color="#667085" />
                    <p>Export as</p>
                    <FiChevronRight className="ms-auto" />
                  </div>
                  <ExportOptions
                    show={showExportFormats}
                    onClose={toggleShowFormats}
                    chevronRight={chevronRight}
                    exportFile={exportFile}
                  />
                </Dropdown.Item>
              )}
              <Dropdown.Item
                className={StyleClasses.dropDownItemWrapper}
                onClick={openDuplicateModal}
              >
                <div className={StyleClasses.listItem_sm}>
                  <FiCopy size={16} color="#667085" />
                  <p>Duplicate</p>
                </div>
              </Dropdown.Item>
              {hasFullAccess && (
                <Dropdown.Item
                  className={StyleClasses.dropDownItemWrapper}
                  onClick={() => setDeleteDocumentModalView(true)}
                >
                  <div className={StyleClasses.listItem_sm}>
                    <FiTrash2 size={16} color="#B42318" />
                    <p className="error">Delete</p>
                  </div>
                </Dropdown.Item>
              )}
            </>
          )}
        </DropdownButton>
      ) : (
        <DropdownButton
          className={StyleClasses.DropdownWrapper}
          drop={drop}
          title={Icon}
        >
          <Dropdown.Item
            className={StyleClasses.dropDownItemWrapper}
            onClick={() => {
              restoreDocument();
              queryClient.invalidateQueries({ queryKey: ['LIBRARY'] });
            }}
          >
            <div className={StyleClasses.listItem_sm}>
              <FiRefreshCcw />
              <p>Restore</p>
            </div>
          </Dropdown.Item>
          {documentAccessLevel === 'full_access' && (
            <Dropdown.Item
              className={StyleClasses.dropDownItemWrapper}
              onClick={() => setDeleteTrashConfirmModalView(true)}
            >
              <div className={StyleClasses.listItem_sm}>
                <FiTrash2 size={16} color="#B42318" />
                <p className="error">Delete</p>
              </div>
            </Dropdown.Item>
          )}
        </DropdownButton>
      )}
      {showUpgradeModal && (
        <UpgradeModal
          show={showUpgradeModal}
          onHide={() => setShowUpgradeModal(false)}
          message={
            'Updating campaign related content is a pro feature. Please update to pro plan to access this feature.'
          }
        />
      )}
    </>
  );
};

export default FileOptions;
