import React from 'react';
import Nav from 'react-bootstrap/Nav';
import { FiList, FiCpu } from 'react-icons/fi';
import Styles from './CreateBriefHeader.module.scss';
import { useSelector } from 'react-redux';

const CreateBriefHeader = () => {
  const { user } = useSelector((state) => state.auth);
  const { subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};

  return (
    <Nav justify variant="pills" className={Styles.CreateBriefHeader}>
      <Nav.Item>
        <Nav.Link
          eventKey="manual"
          className={`CreateBriefHeader_Tab ${Styles.CreateBriefHeader_Tab}`}
        >
          <FiList />
          <span>Self-Powered Brief</span>
        </Nav.Link>
      </Nav.Item>
      <Nav.Item>
        <Nav.Link
          eventKey="ai-powered"
          className={Styles.CreateBriefHeader_Tab}
        >
          <FiCpu />
          <span>AI-Powered Brief</span>
        </Nav.Link>
      </Nav.Item>
    </Nav>
  );
};

export default CreateBriefHeader;
