import { ReactComponent as Youtube } from '../../src/assets/svg/youtube.svg';
import { ReactComponent as BlogPost } from '../../src/assets/svg/blog.svg';
import { ReactComponent as Medium } from '../../src/assets/svg/medium.svg';
import { ReactComponent as Podcast } from '../../src/assets/svg/medium.svg';
import { ReactComponent as CaseStudy } from '../../src/assets/svg/case-study.svg';
import { ReactComponent as Quiz } from '../../src/assets/svg/quiz.svg';
import { ReactComponent as Guide } from '../../src/assets/svg/Guide.svg';
import { ReactComponent as PressRelease } from '../../src/assets/svg/medium.svg';
import { ReactComponent as EmailNewsletter } from '../../src/assets/svg/medium.svg';
import { ReactComponent as Instagram } from '../../src/assets/svg/instagram.svg';
import { ReactComponent as LinkedIn } from '../../src/assets/svg/linkedin.svg';
import { ReactComponent as Twitter } from '../../src/assets/svg/x.svg';
import { ReactComponent as Other } from '../../src/assets/svg/Group.svg';

import { ReactComponent as EmailNewsletterRounded } from '../../src/assets/svg/medium-rounded.svg';
import { ReactComponent as InstagramRounded } from '../../src/assets/svg/instagram-rounded.svg';
import { ReactComponent as LinkedInRounded } from '../../src/assets/svg/linkedin-rounded.svg';
import { ReactComponent as TwitterRounded } from '../../src/assets/svg/twitter-rounded.svg';
import { ReactComponent as YoutubeRounded } from '../../src/assets/svg/youtube-rounded.svg';
import { ReactComponent as CaseStudyRounded } from '../../src/assets/svg/case-study-rounded.svg';
import { ReactComponent as BlogPostRounded } from '../../src/assets/svg/blogpost-rounded.svg';
import { ReactComponent as QuizRounded } from '../../src/assets/svg/quiz-rounded.svg';
import { ReactComponent as GuideRounded } from '../../src/assets/svg/guide-rounded.svg';

import React from 'react';

export const CONTENT_TYPE_OPTIONS = [
  {
    label: 'Blog Post',
    value: 'Blog Post',
    icon: <BlogPost />, // Added icon
  },
  {
    label: 'Youtube Script',
    value: 'Youtube Script',
    icon: <Youtube />, // Added icon
  },
  {
    label: 'Email Newsletter',
    value: 'Email Newsletter',
    icon: <Other />, // Added icon
  },
  {
    label: 'Instagram Post',
    value: 'Instagram Post',
    icon: <img src="/instagram.svg" alt="" />, // Added icon
  },
  {
    label: 'Linkedin Post',
    value: 'Linkedin Post',
    icon: <LinkedIn />, // Added icon
  },
  {
    label: 'Twitter Thread',
    value: 'Twitter Thread',
    icon: <Twitter />, // Added icon
  },
  {
    label: 'Quiz',
    value: 'Quiz',
    icon: <Quiz />, // Added icon
  },
  {
    label: 'Guide',
    value: 'Guide',
    icon: <Guide />, // Added icon
  },
  {
    label: 'Press Release',
    value: 'Press Release',
    icon: <Other />, // Added icon
  },
  {
    label: 'Podcast Episode',
    value: 'Podcast Episode',
    icon: <Podcast />, // Added icon
  },
  {
    label: 'Case Study',
    value: 'Case Study',
    icon: <CaseStudy />, // Added icon
  },
  {
    label: 'Medium Article',
    value: 'Medium Article',
    icon: <Other />, // Added icon
  },
];

export const INDUSTRY = [
  {
    label: 'Automotive',
    value: 'Automotive',
  },
  {
    label: 'E-commerce',
    value: 'E-commerce',
  },
];

export const BRANDS = [
  {
    value: 1,
    label: 'Junaid Jamshed',
  },
  {
    value: 2,
    label: 'Oladoc',
  },
];

export const TONES = [
  {
    label: 'Friendly',
    value: 'Friendly',
  },
  {
    label: 'Formal',
    value: 'Formal',
  },
  {
    label: 'Assertive',
    value: 'Assertive',
  },
  {
    label: 'Optimistic',
    value: 'Optimistic',
  },
  {
    label: 'Informative',
    value: 'Informative',
  },
  {
    label: 'Curious',
    value: 'Curious',
  },
];

export const CONTENT_TYPE_ICONS = {
  'Blog Post': <BlogPost />,
  'Youtube Script': <Youtube />,
  'Medium Article': <Other />,
  'Medium Articles': <Other />,
  'Podcast Episode': <Podcast />,
  'Case Study': <CaseStudy />,
  Quizz: <Quiz />,
  Guide: <Guide />,
  'Press Release': <Other />,
  'Email Newsletter': <Other />,
  'Instagram Post': <img src="/instagram.svg" alt="" />,
  'Linkedin Post': <LinkedIn />,
  'Twitter Thread': <Twitter />,
  other: <Other />,
  Quiz: <Quiz />,
  'Instagram post': <img src="/instagram.svg" alt="" />,
  'LinkedIn Post': <LinkedIn />,
};

export const CONTENT_TYPE_ICONS_ROUNDED = {
  'Blog Post': <BlogPostRounded />,
  'Youtube Script': <YoutubeRounded />,
  'Medium Article': <EmailNewsletterRounded />,
  'Medium Articles': <EmailNewsletterRounded />,
  'Podcast Episode': <EmailNewsletterRounded />,
  'Case Study': <CaseStudyRounded />,
  Quizz: <QuizRounded />,
  Guide: <GuideRounded />,
  'Press Release': <Other />,
  'Email Newsletter': <Other />,
  'Instagram Post': <InstagramRounded />,
  'Linkedin Post': <LinkedInRounded />,
  'Twitter Thread': <TwitterRounded />,
  other: <EmailNewsletterRounded />,
  Quiz: <QuizRounded />,
  'Instagram post': <InstagramRounded />,
  'LinkedIn Post': <LinkedInRounded />,
};

export const CAMPAIGN_GOALS = [
  {
    label: 'Lead Generation',
    value: 'Lead Generation',
  },
  {
    label: 'Brand Awareness',
    value: 'Brand Awareness',
  },
  {
    label: 'Engagement',
    value: 'Engagement',
  },
  {
    label: 'Sales Conversion',
    value: 'Sales Conversion',
  },
];
