import React from 'react';
import Styles from './BadgeHeader.module.scss';
import { FaAngleRight } from 'react-icons/fa6';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

export function HeaderBadge({ icon, text, redirectUrl }) {
  const navigate = useNavigate();
  const { user } = useSelector((state) => state.auth);
  const { subscriptions } = user || {};
  const { current_subscription: currentSubscription } = subscriptions ?? {};
  const handleNavigation = (url) => {
    if (url) {
      navigate(url);
    }
  };
  return (
    <div className={Styles.badge}>
      <button
        className={Styles.badge__btn}
        onClick={() => handleNavigation(redirectUrl)}
      >
        <span>{icon[0]}</span>
        <p>{text[0]}</p>
      </button>
      {text.slice(1)?.map((item, index) => (
        <div key={index} className="d-flex">
          <span>
            <FaAngleRight />
          </span>
          <button className={Styles.badge__btn_child}>
            {icon[index + 1] && <span>{icon[index + 1]}</span>}
            <p>{item}</p>
          </button>
        </div>
      ))}
    </div>
  );
}
