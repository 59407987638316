import React, { useState } from 'react';
import BSButton from 'Components/Button/BSButton';
import { toast } from 'react-toastify';
import { applyCouponDiscount } from 'services/subscriptionService';
import { useDispatch } from 'react-redux';
import { setLoader } from 'redux/slices/loader';
import Styles from './CouponElement.module.scss';
export const CouponElement = ({
  couponName,
  setCouponName,
  setDiscountedPrice,
  setShowCouponForm,
  priceId,
  setIsDisabledSwitch,
  setDiscountPercentage,
}) => {
  const [showCouponbutton, setShowCouponButton] = useState(true);

  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const applyCoupon = async (couponName, priceId) => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Applying coupon',
        description: 'Please wait...',
      }),
    );
    try {
      const response = await applyCouponDiscount(couponName, priceId);
      const responseData = response.data;
      if (responseData.valid) {
        setDiscountedPrice(responseData.discounted_price);
        setDiscountPercentage(responseData.discount_percentage);
        toast.success('Coupon applied successfully');
      } else {
        setCouponName('');
        toast.error(responseData.message);
      }
      setShowCouponButton(false);
      setShowCouponForm(false);
      setIsDisabledSwitch(true);
    } catch (error) {
      setCouponName('');
      toast.error('Oops! An error occurred while applying the coupon', error);
    }
    dispatch(setLoader({ isLoading: false }));
  };

  return (
    <>
      {showCouponbutton && (
        <form onSubmit={handleSubmit}>
          <div className="form-group row">
            <div className="col">
              <input
                type="text"
                id="couponName"
                placeholder="Add coupon code"
                value={couponName}
                onChange={(e) => setCouponName(e.target.value)}
                className="input form-control"
              />
            </div>
            <div className="col-auto px-0">
              <BSButton
                ButtonText="Apply"
                variant="dark"
                disabled={couponName.trim().length === 0}
                onClick={() => applyCoupon(couponName, priceId)}
                classList={`button secondary_btn`}
              />
            </div>
          </div>
        </form>
      )}
    </>
  );
};
