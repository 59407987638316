import React, { useEffect, useState } from 'react';
import fileDownload from 'js-file-download';
import { useDispatch, useSelector } from 'react-redux';
import { setLoader } from 'redux/slices/loader';

import ConfirmDeleteModal from 'Containers/Modal/ConfirmDeleteModal/ConfirmDeleteModal';
import ConfirmTrashDeleteModal from 'Containers/Modal/ConfirmTrashDeleteModal/ConfirmTrashDeleteModal';
import DuplicateDocumentModal from 'Containers/Modal/DuplicateDocumentModal/DuplicateDocumentModal';
import DuplicateFolderModal from 'Containers/Modal/DuplicateFolderModal/DuplicateFolderModal';
import SaveAsModal from 'Containers/Modal/SaveAsModal/SaveAsModal';
import ShareModal from 'Containers/Modal/ShareModal/ShareModal';
import { getAccessLevel } from 'helpers';
import {
  error as renderError,
  success as renderSuccess,
} from 'helpers/toaster.js';
import EditorOptions from './EditorOptions';
import FileOptions from './FileOptions';
import FolderOptions from './FolderOptions';
import {
  getDocumentExport,
  restoreDocument,
  updateDocument,
} from 'services/documentService';
import { exportFolder, restoreFolder } from 'services/folderService';
import MoveToStateModal from '../../Containers/Modal/MoveToStateModal/MoveToStateModal';

const OptionsDropDown = ({
  document,
  folder,
  refetchContent,
  drop,
  Icon,
  collaborator,
  chevronRight,
  canDelete,
  OptionsFor,
  fetchFolders,
  isTrash,
  isDocument,
  saveAsModalView,
  setSaveAsModalView,
  isDashboardView = false,
}) => {
  const { user } = useSelector((state) => state.auth);

  const [moveToFolderModalView, setMoveToFolderModalView] = useState(false);
  const [moveToStatusModalView, setMoveToStatusModalView] = useState(false);
  const [duplicateDocumentModalView, setDuplicateDocumentModalView] =
    useState(false);
  const [deleteDocumentModalView, setDeleteDocumentModalView] = useState(false);
  const [deleteFolderModalView, setDeleteFolderModalView] = useState(false);
  const [shareModalView, setShareModalView] = useState(false);
  const [showExportFormats, setShowExportFormats] = useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [deleteTrashConfirmModalView, setDeleteTrashConfirmModalView] =
    useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    saveAsModalView && openSaveAsModal();
  }, [saveAsModalView]);

  const toggleShowFormats = () => setShowExportFormats(!showExportFormats);

  const exportFile = async (type, responseType) => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Exporting!',
        description: 'Please wait...',
      }),
    );
    try {
      const response = await getDocumentExport({
        documentId,
        type,
        responseType,
      });

      dispatch(setLoader({ isLoading: false }));
      fileDownload(response?.data, `${title}.${type}`);
    } catch (error) {
      renderError(error);
      dispatch(setLoader({ isLoading: false }));
    }
  };

  const openSaveAsModal = async () => {
    refetchContent && (await refetchContent(false));
    !saveAsModalView &&
      !moveToFolderModalView &&
      setSaveAsModalView &&
      setSaveAsModalView(true);
  };

  const exportZipFolder = async () => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Exporting!',
        description: 'Please wait...',
      }),
    );
    try {
      const response = await exportFolder(folderId);

      dispatch(setLoader({ isLoading: false }));
      fileDownload(response?.data, `${folderTitle}.zip`);
    } catch (error) {
      renderError(error);
      dispatch(setLoader({ isLoading: false }));
    }
  };

  const hideModal = () => {
    setSaveAsModalView(false);
    refetchContent && refetchContent(false);
  };

  const openDuplicateModal = async () => {
    dispatch(setLoader({ isLoading: true }));

    refetchContent && (await refetchContent(false));
    setDuplicateDocumentModalView(true);
    dispatch(setLoader({ isLoading: false }));
  };

  const handleRestoreFolder = async () => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Restoring!',
        description: 'Please wait...',
      }),
    );

    try {
      const response = await restoreFolder(folderId);

      dispatch(setLoader({ isLoading: false }));
      renderSuccess(response?.data?.message);
      fetchFolders();
    } catch (error) {
      renderError(error);
      dispatch(setLoader({ isLoading: false }));
    }
  };

  const handleRestoreDocument = async () => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Restoring!',
        description: 'Please wait...',
      }),
    );

    try {
      const response = await restoreDocument(documentId);
      dispatch(setLoader({ isLoading: false }));
      renderSuccess(response?.data?.message);
      fetchFolders();
    } catch (error) {
      renderError(error);
      dispatch(setLoader({ isLoading: false }));
    }
  };

  const handleChangeStatusDocument = async ({ state }) => {
    dispatch(
      setLoader({
        isLoading: true,
        heading: 'Restoring!',
        description: 'Please wait...',
      }),
    );

    try {
      const response = await updateDocument(documentId, { status: state });
      dispatch(setLoader({ isLoading: false }));
      renderSuccess(response?.data?.message);
      fetchFolders();
    } catch (error) {
      renderError(error);
      dispatch(setLoader({ isLoading: false }));
    }
  };

  const {
    id: documentId,
    title,
    user: documentOwner,
    user_id: documentUserId,
    shared_documents: sharedDocuments,
    project_id: projectIdFile,
  } = document || {};

  const documentAccessLevel = getAccessLevel(
    sharedDocuments,
    user,
    documentOwner,
  );

  const {
    id: folderId,
    title: folderTitle,
    user: folderOwner,
    shared_folders: sharedFolders,
    user_id: folderUserId,
    project_id: projectIdFolder,
  } = folder || {};

  const folderAccessLevel = getAccessLevel(sharedFolders, user, folderOwner);

  const isFullAccess = documentAccessLevel === 'full_access';
  const isFullAccessFolder = folderAccessLevel === 'full_access';

  const documentOwnerId = documentUserId || documentOwner?.id;
  const folderOwnerId = folderUserId || documentOwner?.id;

  const isDocumentOwner = documentOwnerId === user?.id;
  const isFolderOwner = folderOwnerId === user?.id;

  const commonProps = {
    drop,
    Icon,
    toggleShowFormats,
  };

  return (
    <div>
      {OptionsFor === 'EditorDocument' ? (
        <EditorOptions
          {...commonProps}
          collaborator={collaborator}
          openSaveAsModal={openSaveAsModal}
          showExportFormats={showExportFormats}
          chevronRight={chevronRight}
          exportFile={exportFile}
          openDuplicateModal={openDuplicateModal}
          canDelete={canDelete}
          setDeleteDocumentModalView={setDeleteDocumentModalView}
        />
      ) : OptionsFor === 'Folder' ? (
        <FolderOptions
          {...commonProps}
          isFolderOwner={isFolderOwner}
          isFullAccessFolder={isFullAccessFolder}
          isTrash={isTrash}
          setShareModalOpen={setShareModalOpen}
          exportZipFolder={exportZipFolder}
          setShowDuplicateModal={setShowDuplicateModal}
          setDeleteFolderModalView={setDeleteFolderModalView}
          restoreFolder={handleRestoreFolder}
          documentAccessLevel={documentAccessLevel}
          setDeleteTrashConfirmModalView={setDeleteTrashConfirmModalView}
          projectId={projectIdFolder}
        />
      ) : OptionsFor === 'File' ? (
        <FileOptions
          {...commonProps}
          isDocumentOwner={isDocumentOwner}
          isFullAccess={isFullAccess}
          isTrash={isTrash}
          setShareModalView={setShareModalView}
          setMoveToFolderModalView={setMoveToFolderModalView}
          OptionsFor={OptionsFor}
          showExportFormats={showExportFormats}
          chevronRight={chevronRight}
          exportFile={exportFile}
          openDuplicateModal={openDuplicateModal}
          setDeleteDocumentModalView={setDeleteDocumentModalView}
          restoreDocument={handleRestoreDocument}
          documentAccessLevel={documentAccessLevel}
          setDeleteTrashConfirmModalView={setDeleteTrashConfirmModalView}
          setMovetoStatusModalView={setMoveToStatusModalView}
          isDashboardView={isDashboardView}
          projectId={projectIdFile}
        />
      ) : null}

      {saveAsModalView && (
        <SaveAsModal
          show={saveAsModalView}
          onHide={hideModal}
          openSaveAsModal={openSaveAsModal}
          document={document}
        />
      )}

      {shareModalView && (
        <ShareModal
          show={shareModalView}
          onHide={() => setShareModalView(false)}
          document={document}
        />
      )}

      {moveToFolderModalView && (
        <SaveAsModal
          show={moveToFolderModalView}
          onHide={() => {
            fetchFolders();
            setMoveToFolderModalView(false);
          }}
          openSaveAsModal={openSaveAsModal}
          document={document}
          title="Move To"
        />
      )}

      {duplicateDocumentModalView && (
        <DuplicateDocumentModal
          show={duplicateDocumentModalView}
          onHide={() => {
            fetchFolders && fetchFolders();
            setDuplicateDocumentModalView(false);
          }}
          document={document}
        />
      )}

      {deleteDocumentModalView && (
        <ConfirmDeleteModal
          show={deleteDocumentModalView}
          onHide={() => {
            fetchFolders && fetchFolders();
            setDeleteDocumentModalView(false);
          }}
          resource={document}
          resourceName="documents"
        />
      )}

      {deleteFolderModalView && (
        <ConfirmDeleteModal
          show={deleteFolderModalView}
          onHide={() => {
            fetchFolders && fetchFolders();
            setDeleteFolderModalView(false);
          }}
          resource={folder}
          resourceName="folders"
        />
      )}

      {showDuplicateModal && (
        <DuplicateFolderModal
          show={showDuplicateModal}
          onHide={() => setShowDuplicateModal(false)}
          fetchFolders={fetchFolders}
          folder={folder}
        />
      )}

      {deleteTrashConfirmModalView && (
        <ConfirmTrashDeleteModal
          show={deleteTrashConfirmModalView}
          onHide={() => setDeleteTrashConfirmModalView(false)}
          fetchFolders={fetchFolders}
          url={
            documentId && isDocument
              ? `documents/${documentId}/fully_destroy`
              : `folders/${folderId}/fully_destroy`
          }
        />
      )}

      {shareModalOpen && (
        <ShareModal
          show={shareModalOpen}
          onHide={() => setShareModalOpen(false)}
          folder={folder}
          isDocument={false}
          fetchFolders={fetchFolders}
        />
      )}

      {moveToStatusModalView && (
        <MoveToStateModal
          show={moveToStatusModalView}
          onHide={() => setMoveToStatusModalView(false)}
          onMove={handleChangeStatusDocument}
          isDocument
        />
      )}
    </div>
  );
};

export default OptionsDropDown;
