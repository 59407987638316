import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Styles from './DashboardLayout.module.scss';
import DashboardHeader from 'Components/DashboardHeader/DashboardHeader';
import Sidebar from 'Components/Sidebar/Sidebar';
import {
  ROUTES_REQUIRE_DIFF_STYLE,
  ROUTES_REQUIRE_LAYOUT,
} from '../../constants/routes';
import { TbExclamationCircle } from 'react-icons/tb';
import Announcement from 'Components/Pages/Brand/Announcement';

function DashboardLayout({ data, children }) {
  const location = useLocation();
  const pathName = location.pathname.split('/')[1];

  const isMobile = window.matchMedia('(max-width: 768px)').matches;
  const [isSidebarOpen, setIsSidebarOpen] = useState(!isMobile);
  const [showMobileModal, setShowMobileModal] = useState(false);

  useEffect(() => {
    const hasSeenModal = sessionStorage.getItem('hasSeenMobileModal');
    if (isMobile && !hasSeenModal) {
      setShowMobileModal(true);
    }
  }, [isMobile]);

  const handleDismissModal = () => {
    setShowMobileModal(false);
    sessionStorage.setItem('hasSeenMobileModal', 'true');
  };

  useEffect(() => {
    setIsSidebarOpen(!isMobile);
  }, [location.pathname, isMobile]);

  useEffect(() => {
    const resizeHandler = () => {
      setIsSidebarOpen(
        !window.matchMedia('(max-width: 768px)').matches || isSidebarOpen,
      );
    };
    window.addEventListener('resize', resizeHandler);
    return () => window.removeEventListener('resize', resizeHandler);
  }, []);

  const routePath = `/${pathName}`;
  let status = data?.user?.subscriptions?.current_subscription?.status;
  const planName = data?.user?.subscriptions?.current_subscription?.plan_name;
  const hidescroll = location.pathname.startsWith(
    '/freestyle-editor/document/',
  );
  const hidescroll2 = location.pathname.startsWith('/editor/document/');
  if (
    data?.isLoggedIn &&
    (!pathName || ROUTES_REQUIRE_LAYOUT.includes(routePath))
  ) {
    return (
      <>
        <div className={Styles.contentFlexWrapper}>
          {showMobileModal && (
            <div className={Styles.mobileModal}>
              <div className={Styles.mobileModalContent}>
                <h3>Desktop Experience Recommended</h3>
                <p>
                  This app works best on desktop for the optimal experience.
                  While you can still use it on mobile, some features might be
                  limited.
                </p>
                <button
                  className={Styles.gotItButton}
                  onClick={handleDismissModal}
                >
                  Got it
                </button>
              </div>
            </div>
          )}
          <Sidebar planName={planName} isOpen={isSidebarOpen} />
          <div className={Styles.contanet_container}>
            <DashboardHeader
              onHamburgerClick={() => setIsSidebarOpen(!isSidebarOpen)}
            />
            <div
              className={
                hidescroll || hidescroll2
                  ? Styles.content_hidescroll
                  : Styles.content
              }
              data-diff-style={ROUTES_REQUIRE_DIFF_STYLE.includes(routePath)}
            >
              {status === 'past_due' && (
                <div className={Styles.contanet_container__header}>
                  <span>
                    <TbExclamationCircle />
                  </span>
                  <p>
                    <span>
                      Your payment has failed. Please update your payment
                      method, or your subscription will end.
                    </span>
                  </p>
                </div>
              )}

              {location.pathname !== '/brand' && (
                <div style={{ borderBottom: '1px solid white' }}>
                  <Announcement view={true} />
                </div>
              )}

              {children}
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return children;
  }
}

export default DashboardLayout;
