import {
  postRequest,
  putRequest,
  getRequest,
  deleteRequest,
} from 'helpers/axios';
const BASE_URL = '';

const createBusiness = (body) => {
  return postRequest(`businesses`, body);
};

const getScrappedBusiness = (url) => {
  return getRequest(`businesses/scraped_summary?url=${url}`);
};

const getBusinessById = (id) => {
  return getRequest(`businesses/${id}`);
};

const updateBusinessById = (id, body) => {
  return putRequest(`businesses/${id}`, body);
};

const getAnalyzeBusiness = (url) => {
  return getRequest(`scripts/analyze?url=${url}`);
};

const getContentTopics = (body) => {
  return postRequest(
    `businesses/generate_content_topics_for_user_entered_summary`,
    body,
  );
};

const postFailedScrapper = (url) => {
  return postRequest(`scripts/handle_failed_scraper`, { url });
};

const getUserBrandInfo = () => {
  return getRequest(`user_brands_info`);
};

const deleteBrand = (id) => {
  return deleteRequest(`businesses/${id}`);
};

const onBoardingService = {
  createBusiness,
  getScrappedBusiness,
  getAnalyzeBusiness,
  postFailedScrapper,
  getContentTopics,
  getBusinessById,
  updateBusinessById,
  getUserBrandInfo,
  deleteBrand,
};

export default onBoardingService;
