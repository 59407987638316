import { useQuery } from '@tanstack/react-query';
import { getProject } from 'services/projectService';
import keys from 'constants/queryKeys';

const useProject = ({ projectId }) => {
  const query = useQuery({
    queryKey: keys.Project(projectId),
    queryFn: () => getProject(projectId),
  });

  return {
    ...query,
    ...query?.data?.data,
  };
};

export default useProject;
