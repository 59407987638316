import React, { useContext } from 'react';
import { ModalContext } from 'layouts/LandingLayout/LandingLayout';

import Box from 'ui/Box/Box';
import Flex from 'ui/Flex/Flex';
import Text from 'ui/Text/Text';
import Button from 'ui/Button/Button';
import { Image } from 'react-bootstrap';
import AIGiff from '../../../assets/landingpage/Gif.png';
import Video from '../../../assets/landingpage/Specialised AI.mov';

import Styles from './SpecializedAISection.module.scss';
import { useNavigate } from 'react-router-dom';

const SpecializedAISection = () => {
  const { modalViewHandler, setModalType } = useContext(ModalContext);
  const navigate = useNavigate();

  return (
    <div className={Styles.box_container}>
      <Flex direction="column" className={Styles.box_container__left_container}>
        <Text
          size={'4xl'}
          weight={'f_600'}
          className={Styles.box_container__left_container__heading}
        >
          One Editor, <br />
          Multiple AI Models
        </Text>
        <Text className={Styles.box_container__left_container__description}>
          Vocable’s AI-powered editor gives you access to the top AI models to
          help you draft, refine, and perfect your content with ease and
          efficiency. No more interruptions to switch models; experience a
          unified workflow.
        </Text>
        <button
          className={Styles.box_container__left_container__btn}
          onClick={() => navigate('sign-up')}
        >
          Start your FREE Trial
        </button>
      </Flex>
      <div className={Styles.box_container__video_section}>
        <video
          playsInline
          autoPlay
          muted
          loop
          className={Styles.box_container__video_section__img}
        >
          <source src={Video} />
        </video>
      </div>
    </div>
  );
};

export default SpecializedAISection;
