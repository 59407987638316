import React from 'react';
import BSButton from 'Components/Button/BSButton';
import { FiX } from 'react-icons/fi';
import PageStyles from '../AIProject.module.scss';
import FormikTextField from 'Components/Form/FormikTextField';

const MoreDetailsStep = ({ moreDetails, toggleMoreDetails }) => {
  return (
    <section className={PageStyles.AIProjectContainer_Body_Step}>
      <div className={PageStyles.AIProjectContainer_Body_Step_Wrapper}>
        <div>
          <b>Want to fine-tune your project with more details?</b>
          <p>
            The more your share, the more personalized your content plan will
            be.
          </p>
        </div>
        {!moreDetails ? (
          <BSButton
            ButtonText="Add More Detail"
            classList="button"
            variant="outline-secondary"
            onClick={() => toggleMoreDetails(true)}
          />
        ) : (
          <div className={PageStyles.Optional}>
            <div className="d-flex justify-content-between">
              <p>Optional details</p>
              <FiX size={18} onClick={() => toggleMoreDetails(false)} />
            </div>
            <FormikTextField
              Label="Who are you targeting?"
              name="targetAudience"
              placeholder="Describe target audience"
              classList="w-100 input"
              onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            />
            <FormikTextField
              Label="What messages do you want to convey?"
              name="keyMessage"
              placeholder="Write key message"
              classList="w-100 input"
              onKeyDown={(e) => {
                e.key === 'Enter' && e.preventDefault();
              }}
            />
          </div>
        )}
      </div>
    </section>
  );
};

export default MoreDetailsStep;
